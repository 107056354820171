import React, {PureComponent} from 'react';
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import socketIOClient from "socket.io-client";
import {getProp} from "../../util/util";
import {pushNotification} from "../../data/actions/ui";
import Env from "../../util/env";
import ChatPanel from "./components/chat";
import {hideChatPanel} from "./actions/messaging";

const User = () => {
    return LocalStorage.get('user');
}

const UserToken = () => {
    return User()?.access_token;
}

class SocketListener extends PureComponent {

    constructor(props) {
        super(props);

        this.socket = null;
        this.state = {
            logged: !!UserToken()
        }
    }

    componentDidMount() {
        console.log("SocketListener:componentDidMount");
        if (this.state.logged && UserToken()) {
            this.initSocket();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("SocketListener:componentDidUpdate");
        if (!getProp(this.props, "user.data.access_token", false) && !!this.state.logged) {
            this.setState({
                logged: false
            }, this.destroySocket);
        }
        if (!!getProp(this.props, "user.data.access_token", false) && !this.state.logged) {
            this.setState({
                logged: true
            }, this.initSocket);
        }
    }

    componentWillUnmount() {
        console.log("SocketListener:componentWillUnmount");
        this.destroySocket();
    }

    initSocket = () => {
        console.log("SocketListener:initSocket");
        // Connect to socket, and authenticate
        this.socket = socketIOClient(Env.getWebsocketUrl(), {
            auth: {
                token: UserToken()
            }
        });

        // On connection
        this.socket.on("connect", () => {
            console.log("Socket:connected");
        });

        /* Events
        ================================================ */
        this.socket.on("OnMessage", (data) => {
            const notificationData = {
                data: {
                    notificationMessage: <div>
                        <div>from <span className={"font-bold"}>{data.FromName}</span></div>
                        <span>"{data.Message}"</span></div>,
                    messageDuration: 5,
                    squared: data.Squared,
                    color: data.Color,
                    type: data.Type,
                    data: data.Data,
                    notificationTitle: "New Message",
                    isSystemNotification: true
                }
            }

            if (!this.props.messages.panel) {
                this.props.dispatch(pushNotification(notificationData))
            }
        });
    }

    destroySocket = () => {
        console.log("SocketListener:destroySocket");
        if (this.socket) {
            this.socket.disconnect();
            this.socket.close();
            this.socket = null;
        }
    }

    render() {
        return (
            <ChatPanel
                open={!!this.props.messages.panel}
                onClose={() => this.props.dispatch(hideChatPanel())}
                socket={this.socket}
                messages={this.props.messages}
                translate={this.props.translate}
            />
        );
    }
}

export default connect((state) => ({
    user: state.user,
    messages: state.messages
}))(SocketListener);