import React, {Component} from 'react'
import HorizontalTabs from "../../../components/horizontal-tabs";
import {fillFieldsFromData, getProp} from "../../../util/util";
import {Field, FieldsManager} from "../../../data/services/fields";
import DialogDefault from "../../../components/dialog-default";
import ClientsDialogInfoTab from "./clients-dialog-info-tab";
import ClientsDialogCareTeamTab from "./clients-dialog-care-team-tab";
import ClientsDialogAuthorizedPickupTab from "./clients-dialog-authorized-pickup-tab";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import Env from "../../../util/env";
import ClientsDialogChildTab from "./clients-dialog-child-tab";

export default class OrgChartClientsDialog extends Component {

    constructor(props) {
        super(props);

        const tabs = [
            {name: props.translate('tab.info'), resource: Resources.Client, current: true},
            {
                name: props.translate('tab.care_team'),
                resource: !this.props.createModal ? Resources.Members : "hide",
                current: false
            },
            {
                name: props.translate('tab.authorized_pickup'),
                resource: !this.props.createModal ? Resources.ClientPickups : "hide",
                current: false
            },
            {
                name: props.translate('tab.children'),
                resource: !this.props.createModal ? Resources.ClientChild : "hide",
                current: false
            }
        ];

        this.state = {
            tabs: tabs,
            selectedTab: tabs[0].resource,
            fields: this.props.getClientFields(),
            getCareTeamFields: this.getCareTeamFields(),
            getChildFields: this.getChildFields(),

            careTeamData: [],
            authorizedPickupData: [],
            childData: [],
            updateImage: false,
            authorizedPickupImages: {},
            childImages: {},
            newPersonIndex: -1
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.visible && this.props.selectedItem !== prevProps.selectedItem) {

            this.setState({
                fields: this.props.getClientFields(this.props.selectedItem),
                careTeamData: this.props?.selectedItem?.members ?? [],
                authorizedPickupData: this.props?.selectedItem?.pickups ?? [],
                childImages: this.props?.selectedItem.child ?? []
            });
        }
    }

    /** Data Events
     ================================================================= */

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleInputChange = (name, value, selectedItem = null) => {
        let fields = this.state.fields;

        if (!!this.state.fields[name].errorMessage) {

        }

        if ("ImagePath" === name) {
            this.setState({
                updateImage: 1
            })
        }

        this.setState({fields: FieldsManager.updateField(fields, name, value)});
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return getProp(this.props.selectedItem, "ClientID", 0)
    };

    getCareTeamFields = (item = null) => {
        const fieldTemplates = {
            MemberID: new Field("MemberID", '', ['empty'], false, 'select-search'),
            MemberEmail: new Field("MemberEmail", '', [], false, 'text', {
                hideDialog: true
            }),
            CareTeamRoleID: new Field("CareTeamRoleID", '', [], false, 'select'),
            JoinedOnDate: new Field("JoinedOnDate", '', ['empty'], false, 'date')
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getAuthorizedPickupFields = (item = null) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'hidden', {hideTable: true}),
            ClientPickupID: new Field("ClientPickupID", '', [''], false, 'hidden', {hideTable: true}),
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Relation: new Field("Relation", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath;

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientPickupImage, {
                        v: it?.CreateUpdateDate,
                        id: it.ClientPickupID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                removePhoto: this.removePickupPhoto,
                id: item?.ClientPickupID,
            })
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getChildFields = (item = null) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'hidden', {hideTable: true}),
            ClientChildID: new Field("ClientChildID", '', [''], false, 'hidden', {hideTable: true}),
            ChildName: new Field("ChildName", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath;

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientChildImage, {
                        v: it?.CreateUpdateDate,
                        id: it.ClientChildID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                removePhoto: this.removeChildPhoto,
                id: item?.ClientChildID,
            })
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose, dialogResource, createModal} = this.props;
        const {tabs} = this.state;

        return (
            <DialogDefault
                title={(!!this.props.selectedItem ? "Edit" : "Add") + " Client"}
                heightClass={"min-h-[calc(60vh)]"}
                blurBackdrop={false}
                disableOverflow={true}
                visible={visible}
                onClose={onClose}
                translate={translate}
            >
                <HorizontalTabs
                    tabs={tabs}
                    handleTabChange={this.handleTabChange}
                >
                </HorizontalTabs>

                {Resources.Client === this.state.selectedTab && (
                    <ClientsDialogInfoTab
                        createModal={this.props.createModal}
                        fields={this.state.fields}
                        dialogResource={dialogResource}
                        dispatch={this.props.dispatch}
                        translate={translate}
                        id={this.getId()}
                        onClose={onClose}
                    />
                )}

                {Resources.Members === this.state.selectedTab && !createModal && (
                    <ClientsDialogCareTeamTab
                        id={this.getId()}
                        dispatch={this.props.dispatch}
                        translate={translate}
                        dialogResource={this.props.dialogResource}

                        resourceDialogWidthClass="max-w-md"
                        resourceDialogDisableOverflow={true}

                        dialogTitle={"Member"}
                        createLabel={"Add Member"}

                        sortBy={"CareTeam"}
                        primaryKey={"MemberID"}
                        queryIdKey={"ClientID"}
                        resourceName={Resources.ClientCareTeam}
                        fields={{
                            MemberID: new Field("MemberID", '', ['empty'], false, 'select-search'),
                            ClientMemberID: new Field("ClientMemberID", '', [''], false, 'select-search', {
                                hideDialog: true
                            }),
                            CareTeam: new Field("CareTeam", '', [], false, 'text', {
                                hideDialog: true
                            }),
                            ClientChildID: new Field("ClientChildID", '', ['empty'], false, 'select-search'),
                            Email: new Field("Email", '', [], false, 'text', {
                                hideDialog: true
                            }),
                            CareTeamRoleID: new Field("CareTeamRoleID", '', [], false, 'select'),
                            JoinedOnDate: new Field("JoinedOnDate", '', ['empty'], false, 'date')
                        }}
                        metadata={{
                            MemberID: {
                                api: 'api/' + Resources.Members,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.MemberID,
                                    label: item.FirstName + " " + item.LastName
                                })
                            },
                            ClientChildID: {
                                api: 'api/' + Resources.ClientChild,
                                query: {
                                    ClientID: this.getId()
                                },
                                searchMap: (item) => ({
                                    value: item.ClientChildID,
                                    label: item.ChildName
                                })
                            },
                        }}
                    />
                )}

                {Resources.ClientPickups === this.state.selectedTab && !createModal && (
                    <ClientsDialogAuthorizedPickupTab
                        id={this.getId()}
                        dispatch={this.props.dispatch}
                        translate={translate}
                        dialogResource={this.props.dialogResource}

                        dialogTitle={"Person"}
                        createLabel={"Add Person"}

                        sortBy={"FirstName"}
                        primaryKey={"ClientPickupID"}
                        resourceName={Resources.ClientPickups}
                        metadata={{
                            MemberID: {
                                api: 'api/' + Resources.Members,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.MemberID,
                                    label: item.FirstName + " " + item.LastName
                                })
                            }
                        }}
                    />
                )}

                {Resources.ClientChild === this.state.selectedTab && !createModal && (
                    <ClientsDialogChildTab
                        id={this.getId()}
                        dispatch={this.props.dispatch}
                        translate={translate}
                        dialogResource={this.props.dialogResource}

                        dialogTitle={"Children"}
                        createLabel={"Add Child"}

                        sortBy={"ChildName"}
                        primaryKey={"ClientChildID"}
                        resourceName={Resources.ClientChild}

                    />
                )}

            </DialogDefault>
        )
    }
}