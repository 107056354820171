import React, {Component} from 'react'
import {
    createResource,
    createResourceWithImage,
    deleteResource,
    getResource,
    updateResource
} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import SimpleTable from "../../components/simple-table";
import {
    checkPerm,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    fieldsSearchToHtml,
    fillFieldsFromData,
    getProp,
    UPDATE_PERM
} from "../../util/util";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {PlusIcon, RefreshIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import {Field, FieldsManager} from "../../data/services/fields";
import MemberCardDialog from "../../components/member-card";
import Resources from "../../data/services/resources";
import ArchivedSwitch from "../../components/archived-switch";
import Button from "../../components/button";
import Env from "../../util/env";

export default class MembersTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ?? "",

            searchFields: this.props.searchFields ?? {},

            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            removeImage: null,
            updateImage: null
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getPiggyResourceName()
        }))
    };

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: Resources.Member,
            piggyResource: Resources.Members
        }));
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    };

    handleToggleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            removeImage: !!item ? this.state.removeImage : "",
            updateImage: !!item ? this.state.updateImage : ""
        })
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            removeImage: null,
            updateImage: null
        })
    };

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    };

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    };

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    };

    handleToggleInviteModal = (it) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                MemberID: it.MemberID
            },
            resource: Resources.MemberInvite,
            piggyResource: this.getPiggyResourceName(),
            notificationMessage: "Staff member invited to the system"
        }));
    };

    handleInputChange = (fields, name, value) => {
        let removeImage = this.state.removeImage;
        let updateImage = this.state.updateImage;

        if ("ImagePath" === name && value) {
            removeImage = "";
            updateImage = 1;
        }

        if ("ImagePath" === name && !value) {
            removeImage = 1;
            updateImage = "";
        }

        this.setState({
            removeImage: removeImage,
            updateImage: updateImage
        })

        return FieldsManager.updateField(fields, name, value)
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id ?? 0;
    };

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    };

    getResourceName = () => {
        return this.props.resourceName ?? "";
    };

    getPiggyResourceName = () => {
        return this.props.piggyResourceName ?? this.getResourceName();
    };

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    };

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            searchFields: JSON.stringify(this.getValidSearchFields())
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            DepartmentID: new Field("DepartmentID", '', ['empty'], false, 'select-search'),
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', ['email'], false, !!item ? 'readonly' : 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            Credentials: new Field("Credentials", '', [], false, 'text'),
            ReportsToID: new Field("ReportsToID", '', [], false, 'select-search', {}, {
                isClearable: true
            }),
            SystemRoleID: new Field("SystemRoleID", '', ['empty'], false, 'select', {
                omitSort: true
            }),
            Bio: new Field("Bio", '', [], false, 'textarea', {
                hideTable: true
            }),
            PersonalStatement: new Field("PersonalStatement", '', [], false, 'textarea', {
                hideTable: true
            }),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath
                    return !!imagePath ? Env.getApiUrl('api/' + Resources.MemberImage, {
                        v: item?.CreateUpdateDate || it?.CreateUpdateDate,
                        id: it.MemberID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                id: item?.MemberID,
            })
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, disableEdit, disableDelete} = this.props;

        const data = getProp(this.props, "resource.data.list", []);

        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const selectSearchFields = fieldsSearchToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchSelectQueryChange, this.props.metadataSearch);

        return (
            <div className="">
                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mr-6 mb-3"}
                    />

                    {!!selectSearchFields && selectSearchFields}

                    <div className="flex ml-auto mb-3 inline-block">
                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={(val) => this.setState({archived: val}, this.fetchData)}
                        />

                        {!this.props.disableCreate && checkPerm(Resources.Member, CREATE_PERM) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onView={this.handleToggleViewModal}
                    onEdit={(!disableEdit && checkPerm(Resources.Member, UPDATE_PERM)) ? this.handleToggleEditModel : null}
                    onDelete={(!disableDelete && checkPerm(Resources.Member, DELETE_PERM)) ? this.handleToggleConfirmModal : null}
                    onDeleteLabel={translate("btn.archive")}
                    onRestore={checkPerm(Resources.Member, UPDATE_PERM) ? this.handleRestore : null}

                    onCustomAction={(it) => {
                        return (
                            <>
                                {checkPerm(Resources.MemberInvite, CREATE_PERM) && !it.ArchivedDate && (
                                    <Button
                                        type="button"
                                        onClick={() => this.handleToggleInviteModal(it)}
                                        className={"mx-1 inline-flex items-center px-4 py-2 border border-primary-300 rounded-md shadow-sm text-sm font-medium text-gray-700 " +
                                        " focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 hover:bg-primary-50"}
                                    >
                                        <RefreshIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        <span className="hidden sm:inline">{it.ContactID ? "Resend" : "Invite"}</span>
                                    </Button>
                                )}
                            </>
                        )
                    }}
                />

                <ModalSaveResource
                    title={"Edit " + this.props.dialogTitle}
                    className="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            this.props.dispatch(createResourceWithImage({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign(params, {
                                    removeImage: this.state.removeImage,
                                    updateImage: this.state.updateImage,
                                }),
                                image: params.ImagePath,
                                resource: Resources.MemberUpdate,
                                piggyResource: this.getPiggyResourceName(),
                                notificationMessage: translate("message.staff_member_updated")
                            }));
                            this.handleToggleEditModel(null);
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalSaveResource
                    title={"Create " + this.props.dialogTitle}
                    className="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={FieldsManager.cleanFields(this.getFields())}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            params.id = this.getId();
                            this.props.dispatch(createResourceWithImage({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign(params, {
                                    removeImage: this.state.removeImage,
                                    updateImage: this.state.updateImage,
                                }),
                                image: params.ImagePath,
                                resource: Resources.Member,
                                piggyResource: this.getPiggyResourceName()
                            }));

                            this.handleToggleCreateModal(false);
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata}
                />

                <ModalConfirm
                    title={translate("modal_title.confirm_archive")}
                    text={translate("text.confirm_archive")}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.Member,
                            piggyResource: this.getPiggyResourceName()
                        }));
                        this.handleToggleConfirmModal(false);
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <MemberCardDialog
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                    translate={this.props.translate}
                    member={this.state.selectedItem}
                />
            </div>
        )
    }
}
