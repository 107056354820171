import {Switch} from "@headlessui/react";
import React, {Component} from "react";

export default class FieldSwitch extends Component {
    render() {
        const {value, onChange, title, onClass, offClass, handleClass, id} = this.props
        const addOnClass = onClass ?? 'bg-kp-blue';
        const addOffClass = offClass ?? 'bg-gray-200';
        const addHandleClass = handleClass ?? 'bg-white';
        return (
            <Switch
                checked={value}
                onChange={onChange}
                className={`${
                    value ? addOnClass : addOffClass
                } relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-inset focus:ring-kp-blue`}
            >
                <span className="sr-only">{title}</span>
                <span
                    className={`${
                        value ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform transition ease-in-out duration-200 rounded-full ${addHandleClass}`}
                />
            </Switch>
        )
    }
}