import React, {useEffect, useRef} from 'react'
import "./index.css";

export default function SignatureCanvas({width, height, strokeStyle, lineWidth, className = "", name}) {

    const w = width || 300;
    const h = height || 200;

    const canvas = useRef(null);

    useEffect(() => {
        window.requestAnimFrame = (function (callback) {
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimaitonFrame ||
                function (callback) {
                    window.setTimeout(callback, 1000 / 60);
                };
        })();

        const ctx = canvas.current.getContext("2d");
        ctx.strokeStyle = strokeStyle || "#333333";
        ctx.lineWidth = lineWidth || 3;

        let drawing = false;
        let mousePos = {
            x: 0,
            y: 0
        };
        let lastPos = mousePos;

        canvas.current.addEventListener("mousedown", function (e) {
            drawing = true;
            lastPos = getMousePos(canvas.current, e);
        }, false);

        canvas.current.addEventListener("mouseup", function (e) {
            drawing = false;
        }, false);

        canvas.current.addEventListener("mousemove", function (e) {
            mousePos = getMousePos(canvas.current, e);
        }, false);

        // Add touch event support for mobile
        canvas.current.addEventListener("touchstart", function (e) {

        }, false);

        canvas.current.addEventListener("touchmove", function (e) {
            const touch = e.touches[0];
            const me = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.current.dispatchEvent(me);
        }, false);

        canvas.current.addEventListener("touchstart", function (e) {
            mousePos = getTouchPos(canvas.current, e);
            const touch = e.touches[0];
            const me = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.current.dispatchEvent(me);
        }, false);

        canvas.current.addEventListener("touchend", function (e) {
            const me = new MouseEvent("mouseup", {});
            canvas.current.dispatchEvent(me);
        }, false);

        function getMousePos(canvasDom, mouseEvent) {
            const rect = canvasDom.getBoundingClientRect();
            return {
                x: mouseEvent.clientX - rect.left,
                y: mouseEvent.clientY - rect.top
            }
        }

        function getTouchPos(canvasDom, touchEvent) {
            const rect = canvasDom.getBoundingClientRect();
            return {
                x: touchEvent.touches[0].clientX - rect.left,
                y: touchEvent.touches[0].clientY - rect.top
            }
        }

        function renderCanvas() {
            if (drawing) {
                ctx.moveTo(lastPos.x, lastPos.y);
                ctx.lineTo(mousePos.x, mousePos.y);
                ctx.stroke();
                lastPos = mousePos;
            }
        }

        (function drawLoop() {
            window.requestAnimFrame(drawLoop);
            renderCanvas();
        })();
    })

    return (
        <div className={className + " sig-canvas"}>
            <canvas ref={canvas} width={w} height={h} id={name}>
                Browser not supported
            </canvas>
        </div>
    )
}
