const messagesReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_CONVERSATIONS':
            return Object.assign({}, state, {
                data: null,
                isLoading: !action.data.silent,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_CONVERSATIONS':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_CONVERSATIONS':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_MESSAGES':
            return Object.assign({}, state, {
                messages: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_MESSAGES':
            return Object.assign({}, state, {
                messages: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_MESSAGES':
            return Object.assign({}, state, {
                messages: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'GET_CONTACTS':
            return Object.assign({}, state, {
                contacts: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_CONTACTS':
            return Object.assign({}, state, {
                contacts: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_CONTACTS':
            return Object.assign({}, state, {
                contacts: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'SHOW_CHAT_PANEL':
            return Object.assign({}, state, {
                panel: true
            });
        case 'HIDE_CHAT_PANEL':
            return Object.assign({}, state, {
                panel: false
            });

        case 'GET_SMS_MESSAGES':
            return Object.assign({}, state, {
                sms: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_SMS_MESSAGES':
            return Object.assign({}, state, {
                sms: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_SMS_MESSAGES':
            return Object.assign({}, state, {
                sms: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
};

export default messagesReducer;