import React, {Component, createRef} from "react";
import LayoutDashboard from "../../components/layout-dashboard";
import moment from "moment";
import PageHeading from "../../components/page-heading";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {Field, FieldsManager} from "../../data/services/fields";
import {
    checkPerm,
    CREATE_PERM,
    DELETE_PERM,
    fillFieldsFromData,
    getLookupWithFilter,
    getProp,
    LOGGED_IN_USER_TYPE_ADMIN,
    LOGGED_IN_USER_TYPE_CLIENT,
    LOGGED_IN_USER_TYPE_MEMBER,
    READ_PERM,
    toBackDateTime,
    UPDATE_PERM
} from "../../util/util";
import Button from "../../components/button";
import WorkingHoursDialog from "./woking-hours-dialog";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import Resources from "../../data/services/resources";
import ModalConfirm from "../../components/modal/modal-confirm";
import Loader from "../../components/loader";
import axios from "axios";
import {processResponse} from "../../data/services/api-util";
import Env from "../../util/env";
import Calendar from "../../components/calendar";
import XIcon from "@heroicons/react/solid/XIcon";
import PlusIcon from "@heroicons/react/solid/PlusIcon";
import ArchivedSwitch from "../../components/archived-switch";
import {ClockIcon} from "@heroicons/react/outline";

class CalendarView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 10,
            sort: "ASC",
            sortBy: "FirstName",
            paginationPage: 1,
            query: "",

            currMonth: moment().month(),
            currYear: moment().year(),

            isWeekView: false,
            weekView: 0,

            selectedItem: null,
            templateItem: null,

            showOnlyMy: null,

            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false,
            isScheduleDialogVisible: false,

            appointmentTimeModalOpen: false,
            cancelAptModalOpen: false,
            getAllMembersLoader: false,
        };

        this.MemberIDRef = createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResource()
        }));
    };

    fetchCalendarData = (year, month) => {
        this.setState({
            currMonth: month,
            currYear: year,
        }, this.fetchData)
    }

    /** UI Events
     ================================================================= */
    handleToggleCreateModal = (date = null) => {
        const root = document.getElementById("root");
        // Temp solution. This should be centralised (e.g. in dialog component)
        if (!this.state.createModalOpen) {
            root.classList.add("filter", "blur-sm")
        } else {
            root.classList.remove("filter", "blur-sm")
        }
        this.setState({
            createModalOpen: !this.state.createModalOpen,
            templateItem: {
                DateTime: date
            }
        }, () => {
            if (!this.state.createModalOpen) {
                this.setState({
                    templateItem: null
                });
            }
        });
    };

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
        });
    };

    toggleScheduleDialog = () => {
        this.setState({
            isScheduleDialogVisible: !this.state.isScheduleDialogVisible
        });
    };

    handleToggleConfirmModal = () => {
        this.setState({
            confirmModalOpen: !this.state.confirmModalOpen,
        });
    };

    /** Helpers
     ================================================================= */
    getResource = () => {
        return Resources.ScheduleItem
    };

    getQuery = () => {
        return {
            offset: 0,
            limit: 500,
            month: this.state.currMonth + 1,
            year: this.state.currYear,
            showOnlyMy: this.state.showOnlyMy ? 1 : 0
        };
    };

    getFields = (item = null, disableFields = 0, excludeFields = []) => {
        let it = Object.assign({}, item);
        const LogedInUserType = getProp(this.props, "resource.data.LogedInUserType");

        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [((checkPerm(Resources.Clients, READ_PERM) || checkPerm(Resources.MemberClientsExternal, READ_PERM)) ? 'empty_select_search' : '')], !!item?.ScheduleItemID ?? !!disableFields, ((checkPerm(Resources.Clients, READ_PERM) || checkPerm(Resources.MemberClientsExternal, READ_PERM)) ? 'select-search' : "hidden")),
            ClientChildID: new Field("ClientChildID", "", ['empty'], !!item?.ScheduleItemID ?? !!disableFields, 'select-search'),
            MemberID: new Field("MemberID", '', [((checkPerm(Resources.Clients, READ_PERM) || checkPerm(Resources.ExtClientMembers, READ_PERM)) ? 'empty_select_search' : '')], !!item?.ScheduleItemID ?? !!disableFields, ((checkPerm(Resources.Clients, READ_PERM) || checkPerm(Resources.ExtClientMembers, READ_PERM)) ? 'select-search' : "hidden"), {}),
            Duration: new Field("Duration", '', ['integer', 'empty'], !!disableFields, 'integer'),
            DateTime: new Field("DateTime", "", ['empty'], !!disableFields, 'datetime'),
            CurrentScheduleType: new Field("CurrentScheduleType", item?.ScheduleType, [], !!disableFields, !!item?.ScheduleItemID ? 'readonly' : 'hidden'),
            ScheduleTypeID: new Field("ScheduleTypeID", '', [], !!disableFields, (LogedInUserType !== LOGGED_IN_USER_TYPE_ADMIN && !!item?.ScheduleItemID) ? 'hidden': 'select'),
            ScheduleLocationID: new Field("ScheduleLocationID", '', [], !!disableFields, (checkPerm(Resources.ScheduleLocations, READ_PERM) ? 'select-search' : "select-search")),
            Notes: new Field("Notes", '', [], !!disableFields, 'textarea'),
            ScheduleItemStatus: new Field("ScheduleItemStatus", "Tentative", [], true, "readonly")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (it?.ScheduleItemStatus) {
            it.ScheduleItemStatus = it.ScheduleItemStatus ? "Accepted" : "Tentative"
        }

        if (it?.DateTime) {
            it.DateTime = it.DateTime ? toBackDateTime(it.DateTime) : "";
        }

        // ako sam superadmin ne smem da menjam ClientID, ClientChildID & MemberID
        // LogedInUserType

        if (LogedInUserType !== LOGGED_IN_USER_TYPE_ADMIN && !!item?.ScheduleItemID) { // is update
            Object.values(fieldTemplates).reduce((memo, it) => {

                if (it.type == "select-search") {
                    it.type = 'readonly-select-search'
                } else if (it.type == "select") {
                    it.type = 'readonly-select'
                } else if (it.type == "datetime") {
                    it.type = 'readonly-datetime'
                } else if (it.type === "hidden") {

                } else {
                    it.type = 'readonly'
                }
                memo[it.name] = it
                return memo
            }, {})
        }

        return fillFieldsFromData(fieldTemplates, it);
    };

    handleToggleCancelAptModal = () => {
        this.setState({
            cancelAptModalOpen: !this.state.cancelAptModalOpen
        });
    };

    handleSelectAllMembers = () => {
        this.setState({
                getAllMembersLoader: true
            }, () => axios.get(
            Env.getApiUrl('api/' + (checkPerm(Resources.Members, READ_PERM) ? Resources.Members : Resources.ExtClientMembers),
                {
                    ClientID: -1,
                    query: ""
                }
            ),
            {
                headers: {
                    'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                }
            }
            )
                .then((response) => {
                    const result = processResponse(response);
                    if (result && result.status === 0) {

                        const list = result.data.list.map((it) => {
                            let option = Object.assign({}, {value: it.MemberID, label: it.FirstName + " " + it.LastName});
                            return option;
                        });

                        this.setState({
                            MembersList: list,
                            getAllMembersLoader: false
                        })
                    }
                })
                .catch((error) => {
                })
        )
    };

    handleCancelAptClick = () => {
        this.setState({
            confirmModalSubmitResource: Resources.ScheduleItem,
            confirmModalTitle: this.props.translate("modal_title.confirm_cancel_apt"),
            confirmModalText: this.props.translate("text.cancel_apt")
        })
        this.handleToggleConfirmModal();
    };

    handleAcceptAptClick = () => {
        this.setState({
            confirmModalSubmitResource: Resources.ScheduleItemAcceptEvent,
            confirmModalTitle: this.props.translate("modal_title.confirm_accept_apt"),
            confirmModalText: this.props.translate("text.accept_apt")
        })
        this.handleToggleConfirmModal();
    };

    handleDenyAptClick = () => {
        this.setState({
            confirmModalSubmitResource: Resources.ScheduleItemDenyEvent,
            confirmModalTitle: this.props.translate("modal_title.deny_accept_apt"),
            confirmModalText: this.props.translate("text.deny_apt")
        })
        this.handleToggleConfirmModal();
    };

    handleToggleAptTimeModal = () => {
        this.setState({
            appointmentTimeModalOpen: !this.state.appointmentTimeModalOpen
        })
    }

    handleSelectNoneMembers = () => {
        this.setState({
            MembersList: null
        })
    };

    getEventColorClass = (color) => {
        switch (color) {
            case "green":
                return {
                    bg50: "bg-green-50",
                    hoverBg100: "hover:bg-green-100",
                    text500: "text-green-500",
                    text700: "text-green-700",
                    groupHoverText700: "group-hover:text-green-700",
                    bg400: "bg-green-400"
                };
            case "red":
                return {
                    bg50: "bg-red-50",
                    hoverBg100: "hover:bg-red-100",
                    text500: "text-red-500",
                    text700: "text-red-700",
                    groupHoverText700: "group-hover:text-red-700",
                    bg400: "bg-red-400"
                };
            case "indigo":
                return {
                    bg50: "bg-indigo-50",
                    hoverBg100: "hover:bg-indigo-100",
                    text500: "text-indigo-500",
                    text700: "text-indigo-700",
                    groupHoverText700: "group-hover:text-indigo-700",
                    bg400: "bg-indigo-400"
                };
            case "cyan":
                return {
                    bg50: "bg-cyan-50",
                    hoverBg100: "hover:bg-cyan-100",
                    text500: "text-cyan-500",
                    text700: "text-cyan-700",
                    groupHoverText700: "group-hover:text-cyan-700",
                    bg400: "bg-cyan-400"
                };
            case "purple":
                return {
                    bg50: "bg-purple-50",
                    hoverBg100: "hover:bg-purple-100",
                    text500: "text-purple-500",
                    text700: "text-purple-700",
                    groupHoverText700: "group-hover:text-purple-700",
                    bg400: "bg-purple-400"
                };
            case "blue":
                return {
                    bg50: "bg-blue-50",
                    hoverBg100: "hover:bg-blue-100",
                    text500: "text-blue-500",
                    text700: "text-blue-700",
                    groupHoverText700: "group-hover:text-blue-700",
                    bg400: "bg-blue-400"
                };
            case "yellow":
                return {
                    bg50: "bg-yellow-50",
                    hoverBg100: "hover:bg-yellow-100",
                    text500: "text-yellow-600",
                    text700: "text-yellow-700",
                    groupHoverText700: "group-hover:text-yellow-900",
                    bg400: "bg-yellow-400"
                };
            case "gray":
                return {
                    bg50: "bg-gray-50",
                    hoverBg100: "hover:bg-gray-100",
                    text500: "text-gray-500",
                    text700: "text-gray-700",
                    groupHoverText700: "group-hover:text-gray-700",
                    bg400: "bg-gray-400"
                };
            default:
                return {
                    bg50: "bg-primary-50",
                    hoverBg100: "hover:bg-primary-100",
                    text500: "text-primary-500",
                    text700: "text-primary-700",
                    groupHoverText700: "group-hover:text-primary-700",
                    bg400: "bg-primary-400"
                };
        }
    }

    refreshChildSelectHandleInputChange = (fields, name, value) => {
        if (name === "ClientID" && !!(fields.ClientChildID)) {
            fields.ClientChildID.value = null;
            fields.ClientChildID.props.key = value.value;
        }

        if (name === "ClientChildID" && !!(fields.MemberID)) {
            fields.MemberID.value = null;
            fields.MemberID.props.key = value.value;
        }

        if (name === "AptDate") {
            fields.AptEndDate.type = "datetime";
            if (value > fields.AptEndDate.value || (fields.AptEndDate.value.split(" ")[0] !== value.split(" ")[0])) {
                fields.AptEndDate.value = ''
            }
            fields.AptEndDate.props.minTime = value;
            fields.AptEndDate.props.maxTime = moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss');
        }

        if (name === "AptEndDate") {
            const date = fields.AptDate.value.split(" ")[0]
            const time = value.split(" ")[1]
            value = date + " " + time
        }

        return FieldsManager.updateField(fields, name, value);
    };

    render() {

        const isMemberSudo = getProp(this.props, "resource.data.isMemberSudo");
        const LogedInUserType = getProp(this.props, "resource.data.LogedInUserType");

        const isParent = checkPerm(Resources.ClientProfileInfo, UPDATE_PERM);

        const colors = ['red', 'green', 'indigo', 'blue', 'cyan', 'yellow', 'gray', 'black', 'primary', 'purple'];

        const events = getProp(this.props, "resource.data.list", [])
            .reduce((memo, it) => {
                const date = moment(it.DateTime, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD");
                const time = moment(it.DateTime, 'YYYY-MM-DD HH:mm:ss')
                if (!memo[date]) {
                    memo[date] = [];
                }

                memo[date].push({
                    name: it?.Client?.split(" ").pop() + " - " + it?.Member?.split(" ").pop(),
                    date: date,
                    time: time.format("HH:mm"),
                    timeEnd: time.clone().add(it.Duration, "minutes").format("HH:mm"),
                    duration: it.Duration,
                    displayTime: time.format("h:mm a"),
                    durationDisplay: time.format("h:mm") + " - " + time.add(it.Duration, "minutes").format("h:mma"),
                    color: this.getEventColorClass(colors[it.ScheduleItemStatus]),
                    metadata: {
                        ScheduleItem: it
                    }
                });
                return memo;
            }, []);

        const ScheduleTypeIDLookup = getLookupWithFilter('ScheduleType', 'ScheduleTypeID', 'ScheduleType',
            item => (getProp(this.props, "resource.data.LogedInUserType") == LOGGED_IN_USER_TYPE_ADMIN) ? (item.IsEnabled == 1) : ((checkPerm(Resources.Members, READ_PERM) ? item.IsStaff == 1 : item.IsClient == 1)),
            {});

        const metadata = {
            ClientID: {
                api: 'api/' + (checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal),
                query: {},
                searchMap: (item) => ({
                    value: item.ClientID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            MemberIDs: {
                api: 'api/' + (checkPerm(Resources.Members, READ_PERM) ? Resources.Members : Resources.ExtClientMembers),
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.MemberID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            MemberID: {
                api: 'api/' + (checkPerm(Resources.Members, READ_PERM) ? Resources.Members : Resources.ExtClientMembers),
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    const childID = fieldsCpy.find((it) => {
                        return it.name === "ClientChildID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1,
                        ClientChildID: childID?.value?.value ?? -1.
                    }
                },
                searchMap: (item) => ({
                    value: item.MemberID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            ScheduleLocationID: {
                api: 'api/' + Resources.ScheduleLocations,
                query: {},
                searchMap: (item) => ({
                    value: item.ScheduleLocationID,
                    label: item.LocationName
                })
            },
            ScheduleTypeID: ScheduleTypeIDLookup,
            ClientChildID: {
                api: 'api/' + Resources.ClientChild,
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientChildID,
                    label: item.ChildName
                })
            },
        };

        const metadataAdd = {
            ClientID: {
                api: 'api/' + (checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal),
                query: {},
                searchMap: (item) => ({
                    value: item.ClientID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            MemberIDs: {
                api: 'api/' + (checkPerm(Resources.Members, READ_PERM) ? Resources.Members : Resources.ExtClientMembers),
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.MemberID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            MemberID: {
                api: 'api/' + (checkPerm(Resources.Members, READ_PERM) ? Resources.Members : Resources.ExtClientMembers),
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    const childID = fieldsCpy.find((it) => {
                        return it.name === "ClientChildID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1,
                        ClientChildID: childID?.value?.value ?? null
                    }
                },
                searchMap: (item) => ({
                    value: item.MemberID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            ScheduleLocationID: {
                api: 'api/' + Resources.ScheduleLocations,
                query: {},
                searchMap: (item) => ({
                    value: item.ScheduleLocationID,
                    label: item.LocationName
                })
            },
            ScheduleTypeID: ScheduleTypeIDLookup,
            ClientChildID: {
                api: 'api/' + Resources.ClientChild,
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ClientID";
                    });
                    return {
                        ClientID: child?.value?.value ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientChildID,
                    label: item.ChildName
                })
            }
        };

        const appointmentDateTimeDurationDifference = 30
        const canAcceptDennyAppt = ((LogedInUserType === LOGGED_IN_USER_TYPE_MEMBER && !!this.state.selectedItem?.IsParentScheduled) || (LogedInUserType === LOGGED_IN_USER_TYPE_CLIENT && !this.state.selectedItem?.IsParentScheduled)) && this.state.selectedItem?.ScheduleItemStatus === 0

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12 relative">

                        <PageHeading
                            title="Calendar"
                            titleClass="pb-1"
                        >

                            <div className="flex space-x-4 w-full items-center">

                                {checkPerm(Resources.CancelApt, CREATE_PERM) && (
                                    <button
                                        className="btn btn-danger block capitalize"
                                        onClick={this.handleToggleCancelAptModal}
                                    >
                                        <span>Cancel</span> <span className="hidden sm:inline">appointment</span>
                                    </button>
                                )}

                                <div className="ml-auto justify-end w-full space-x-4 flex items-center">
                                    {checkPerm(Resources.CompanyWorkingHours, CREATE_PERM) && (
                                        <Button
                                            type="secondary"
                                            onClick={this.toggleScheduleDialog}
                                        >
                                            Set Schedule
                                        </Button>
                                    )}

                                    {checkPerm(Resources.ScheduleItem, CREATE_PERM) && LogedInUserType === LOGGED_IN_USER_TYPE_ADMIN && (
                                        <button
                                            className="btn btn-primary px-2 rounded-full"
                                            onClick={this.handleToggleCreateModal}
                                        >
                                            <PlusIcon className="w-5 h-5"/>
                                        </button>
                                    )}
                                </div>
                            </div>


                        </PageHeading>

                        <div className="ml-auto justify-end w-full space-x-4 flex items-center">
                            {isMemberSudo && (
                                <ArchivedSwitch
                                    classNameContainer="h-9 mr-2 mt-1"
                                    translate={this.props.translate}
                                    value={this.state.showOnlyMy}
                                    onChange={(val) => this.setState({showOnlyMy: val}, this.fetchData)}
                                    labelText={this.props.translate("text.show_only_my")}
                                />
                            )}
                        </div>

                        {!!this.props.resource.isLoading && (
                            <div className="absolute inset-0 flex justify-center items-center text-center w-full z-10">
                                <Loader/>
                            </div>
                        )}

                        <Calendar
                            onFetchData={this.fetchCalendarData}
                            events={events}
                            onToggleCreateModal={this.handleToggleCreateModal}
                            onToggleEditModal={this.handleToggleEditModal}
                            translate={this.props.translate}
                            selects={metadata}
                            getQuery={this.getQuery}
                            getFields={this.getFields}
                            dispatch={this.props.dispatch}
                            isLoading={!!this.props.resource.isLoading}
                            calendarHeight={"max-h-[calc(100vh-18rem)]"}
                            isParent={isParent}
                            LogedInUserType={LogedInUserType}
                            handleToggleAptTimeModal={this.handleToggleAptTimeModal}
                            handleAcceptAptClick={this.handleAcceptAptClick}
                            handleDenyAptClick={this.handleDenyAptClick}
                            handleCancelAptClick={this.handleCancelAptClick}
                        />
                    </div>
                </LayoutDashboard>

                <ModalSaveResource
                    title={this.props.translate(isParent ? ("modal_title.request_appointment") : ("modal_title.add_appointment"))}
                    disableOverflow={true}
                    widthClass="max-w-4xl"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields(this.state.templateItem)}
                    handleInputChange={(fields, name, value) => {
                        if (name === "ClientID") {
                            fields.MemberID.props.setKey = "MemberID" + value;
                        }
                        if (name === "ScheduleTypeID") {
                            fields.Notes.validate = ScheduleTypeIDLookup[value] === "Other" ? ['empty'] : [];
                        }
                        return this.refreshChildSelectHandleInputChange(fields, name, value)
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.IsParent = isParent ? 1 : 0;
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.ScheduleItem,
                                piggyResource: Resources.ScheduleItem
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadataAdd}
                />

                <ModalSaveResource
                    title={this.props.translate("modal_title.edit_appointment")}
                    disableOverflow={true}
                    widthClass="max-w-4xl"
                    confirmModalOnSave
                    allowSaveOnInputChange
                    confirmModalTitle={this.props.translate("modal_title.confirm_update_apt")}
                    confirmModalText={this.props.translate("text.update_apt")}
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem, isParent)}
                    onSubmit={this.state.selectedItem?.ScheduleItemID && LogedInUserType === LOGGED_IN_USER_TYPE_ADMIN ? (params) => {
                        if (params) {
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign({ScheduleItemID: this.state.selectedItem.ScheduleItemID}, params),
                                resource: Resources.ScheduleItem,
                                piggyResource: Resources.ScheduleItem
                            }));
                            this.handleToggleEditModal(false);
                        }
                    } : null}
                    translate={this.props.translate}
                    metadata={metadata}
                    onCustomActions={() => {
                        return (
                            <>
                                {LogedInUserType === LOGGED_IN_USER_TYPE_CLIENT && (
                                    <button
                                        type="button"
                                        className="btn rounded-md mr-auto border-yellow-500 bg-yellow-500 text-white sm:bg-white sm:text-yellow-500 hover:bg-yellow-100"
                                        onClick={this.handleToggleAptTimeModal}
                                    >
                                        <ClockIcon className="w-5 h-5 sm:hidden"/>

                                        <span className="hidden sm:inline">Change Appointment Time</span>
                                    </button>
                                )}

                                {canAcceptDennyAppt && (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-danger mr-auto bg-red-600 text-white sm:bg-white sm:text-red-600"
                                            onClick={this.handleDenyAptClick}
                                        >
                                            <XIcon className="w-5 h-5 sm:hidden"/>

                                            <span className="hidden sm:inline">Deny Appointment</span>
                                        </button>

                                        <button
                                            type="button"
                                            className="btn rounded-md mr-auto border-green-600 bg-green-600 text-white sm:bg-white sm:text-green-600 hover:bg-green-100"
                                            onClick={this.handleAcceptAptClick}
                                        >
                                            <XIcon className="w-5 h-5 sm:hidden"/>

                                            <span className="hidden sm:inline">Accept Appointment</span>
                                        </button>
                                    </>
                                )}

                                {checkPerm(Resources.ScheduleItem, DELETE_PERM) && !canAcceptDennyAppt && (
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-danger mr-auto bg-red-600 text-white sm:bg-white sm:text-red-600"
                                            onClick={this.handleCancelAptClick}
                                        >
                                            <XIcon className="w-5 h-5 sm:hidden"/>

                                            <span className="hidden sm:inline">Cancel Appointment</span>
                                        </button>
                                    </>
                                )}
                            </>
                        )
                    }}
                    htmlAfter={(
                        <>
                        <ModalConfirm
                            title={this.state.confirmModalTitle}
                            text={this.state.confirmModalText}
                            onClose={this.handleToggleConfirmModal}
                            onConfirm={() => {
                                if (this.state.confirmModalSubmitResource === Resources.ScheduleItem) {
                                    this.props.dispatch(deleteResource({
                                        user: LocalStorage.get("user"),
                                        query: Object.assign({
                                            ['ScheduleItemID']: this.state.selectedItem['ScheduleItemID']
                                        }, this.getQuery()),
                                        resource: Resources.ScheduleItem,
                                        piggyResource: Resources.ScheduleItem
                                    }));
                                    this.handleToggleConfirmModal();
                                    this.handleToggleEditModal();
                                } else {
                                    this.props.dispatch(updateResource({
                                        user: LocalStorage.get("user"),
                                        params: {
                                            ['ScheduleItemID']: this.state.selectedItem['ScheduleItemID']
                                        },
                                        query: this.getQuery(),
                                        resource: this.state.confirmModalSubmitResource,
                                        piggyResource: Resources.ScheduleItem
                                    }));
                                    this.handleToggleConfirmModal();
                                    this.handleToggleEditModal();
                                }
                            }}
                            visible={this.state.confirmModalOpen}
                        />

                            <ModalSaveResource
                                title={'Appointment Date/Time'}
                                widthClass="max-w-4xl"
                                visible={this.state.appointmentTimeModalOpen}
                                onClose={this.handleToggleAptTimeModal}
                                disableOverflow={true}
                                fields={{
                                    AptStartDateTime: new Field("AptStartDateTime", this.state.selectedItem?.DateTime, ['empty'], false, 'datetime', {}, {
                                        minDate: this.state.selectedItem?.DateTime,
                                        maxDate: moment(this.state.selectedItem?.DateTime).add(this.state.selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        minTime: this.state.selectedItem?.DateTime,
                                        maxTime: moment(this.state.selectedItem?.DateTime).add(this.state.selectedItem?.Duration - appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                    }),
                                    AptEndDateTime: new Field("AptEndDateTime", moment(this.state.selectedItem?.DateTime).add(this.state.selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'datetime', {}, {
                                        minDate: this.state.selectedItem?.DateTime,
                                        maxDate: moment(this.state.selectedItem?.DateTime).add(this.state.selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        minTime: moment(this.state.selectedItem?.DateTime).add(appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        maxTime: moment(this.state.selectedItem?.DateTime).add(this.state.selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                    }),
                                    Notes: new Field('Notes', '', [], false, 'textarea')
                                }}
                                onSubmit={(params) => {
                                    if (params) {
                                        this.props.dispatch(updateResource({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: {
                                                ScheduleItemID: this.state.selectedItem['ScheduleItemID'],
                                                Notes: params.Notes,
                                                DateTime: params.AptStartDateTime,
                                                Duration: moment(params.AptEndDateTime).diff(moment(params.AptStartDateTime), 'minutes')
                                            },
                                            resource: Resources.ScheduleItemParentUpdate,
                                            piggyResource: Resources.ScheduleItem
                                        }));
                                        this.handleToggleEditModal();
                                        this.handleToggleAptTimeModal()
                                    }
                                }}
                                handleInputChange={(fields, name, value) => {
                                    if (name === "AptStartDateTime") {
                                        fields.AptEndDateTime.props.minTime = moment(value).add(appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                                    }
                                    if (name === "AptEndDateTime") {
                                        fields.AptStartDateTime.props.maxTime = moment(value).add(-appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                                    }

                                    return FieldsManager.updateField(fields, name, value);
                                }}
                                translate={this.props.translate}
                                submitButtonName={"Submit"}
                            />
                        </>
                    )}
                    handleInputChange={(fields, name, value) => {
                        if (name === "ClientID") {
                            fields.MemberID.props.setKey = "MemberID" + value;
                        }
                        if (name === "ScheduleTypeID") {
                            fields.Notes.validate = ScheduleTypeIDLookup[value] === "Other" ? ['empty'] : [];
                        }
                        return this.refreshChildSelectHandleInputChange(fields, name, value)
                    }}
                />

                <ModalSaveResource
                    title={(<React.Fragment>
                        {this.props.translate("modal_title.cancel_apt")}

                        {!!this.state.getAllMembersLoader && (
                            <span className="inset-center z-10"><Loader/></span>
                        )}
                    </React.Fragment>)}
                    widthClass="max-w-4xl"
                    visible={this.state.cancelAptModalOpen}
                    onClose={this.handleToggleCancelAptModal}
                    disableOverflow={true}
                    fields={{
                        AptDate: new Field("AptDate", "", ['empty'], false, 'datetime'),
                        AptEndDate: new Field("AptEndDate", "", ['empty'], false, 'hidden', {
                            showTimeSelectOnly: true
                        }),
                        ClientID: new Field("ClientID", "", [isParent ? '' : 'empty'], false, isParent ? "hidden" : 'select-search'),
                        ClientChildID: new Field("ClientChildID", "", ['empty'], false, 'select-search'),
                        Notes: new Field("Notes", '', ['empty'], false, 'textarea')
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.CancelApt,
                                piggyResource: Resources.ScheduleItem
                            }));
                            this.handleToggleCancelAptModal(false)
                        }
                    }}
                    handleInputChange={this.refreshChildSelectHandleInputChange}
                    translate={this.props.translate}
                    metadata={metadata}
                    submitButtonName={"Submit"}
                />

                {!!this.state.isScheduleDialogVisible && (
                    <WorkingHoursDialog
                        title={this.props.translate("modal_title.schedule_item")}
                        visible={this.state.isScheduleDialogVisible}
                        onClose={this.toggleScheduleDialog}
                        fields={this.getFields()}

                        secondResource={this.props.secondResource}
                        translate={this.props.translate}
                        dispatch={this.props.dispatch}
                    />
                )}
            </React.Fragment>
        )
    }
}

export default connect(state => state)(CalendarView);
