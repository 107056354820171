import React, {useEffect, useRef} from "react";
import {classNames} from "../../../util/util"
import moment from "moment";
import ChevronLeftIcon from "@heroicons/react/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/outline/ChevronRightIcon";

export default function CalendarDayView(props) {
    const {
        days,
        selectedDay,
        weekFormat,
        activeMonthDisplay,
        onSetActiveDay,
        setPrevMonth,
        setNextMonth,
        sortDayEvents,
        renderEventButton
    } = props;

    const container = useRef(null)
    const containerNav = useRef(null)
    const containerOffset = useRef(null)

    useEffect(() => {
        // Set the container scroll position based on the current time.
        const currentMinute = new Date().getHours() * 60

        container.current.scrollTop =
            ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
                currentMinute) /
            1440
    }, [])

    const currentWeek = days.filter(it => it.isCurrentWeek);

    return (
        <div className="flex flex-auto overflow-hidden bg-white">
            <div ref={container} className="flex flex-auto flex-col overflow-auto max-h-[calc(100vh-18rem)]">
                <div
                    ref={containerNav}
                    className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
                >
                    {currentWeek.map((day, i) => (
                        <button
                            onClick={() => onSetActiveDay(day.date)}
                            disabled={!day.isCurrentMonth}
                            key={day.day}
                            type="button"
                            className={classNames("flex flex-col items-center pt-3 pb-1.5", !day.isCurrentMonth ? "bg-gray-100" : "")}
                        >
                            <span>{weekFormat[i].abbr}</span>
                            <span
                                className={classNames(
                                    "mt-3 flex h-8 w-8 items-center justify-center rounded-full text-base font-semibold",
                                    day.isSelected && !day.isToday ? "bg-gray-900 text-white" : "",
                                    day.isSelected && day.isToday ? "bg-primary-600 text-white" : "",
                                    !!day.isSelected && !!day.isToday ? "text-gray-900" : "",
                                    !day.isSelected && !!day.isToday ? "text-primary-600" : ""
                                )}
                            >
                                {day.day}
                          </span>
                        </button>
                    ))}
                </div>

                <div className="flex w-full flex-auto">
                    <div className="w-14 flex-none bg-white ring-1 ring-gray-100"/>
                    <div className="grid flex-auto grid-cols-1 grid-rows-1">
                        {/* Horizontal lines */}
                        <div
                            className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                            style={{gridTemplateRows: 'repeat(48, minmax(3.5rem, 1fr))'}}
                        >
                            <div ref={containerOffset} className="row-end-1 h-7"/>

                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    12AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    1AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    2AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    3AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    4AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    5AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    6AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    7AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    8AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    9AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    10AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    11AM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    12PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    1PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    2PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    3PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    4PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    5PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    6PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    7PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    8PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    9PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    10PM
                                </div>
                            </div>
                            <div/>
                            <div>
                                <div
                                    className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    11PM
                                </div>
                            </div>
                            <div/>
                        </div>

                        {/* Events */}
                        <ol
                            className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                            style={{gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto'}}
                        >
                            {!!selectedDay && sortDayEvents(selectedDay).events.map(event => {
                                const eventStartPosition = (moment(event.date + " " + event.time, "YYYY-MM-DD HH:mm").diff(moment(event.date, 'YYYY-MM-DD'), 'minutes') / 5) + 2;
                                const eventDuration = (event?.squashedDuration ?? event?.metadata?.ScheduleItem?.Duration) / 5;

                                return (
                                    <li className={classNames(event.hidden ? "hidden" : "", "relative mt-px flex")}
                                        style={{gridRow: eventStartPosition + ' / span ' + eventDuration}}>
                                        {renderEventButton(event)}
                                    </li>
                                )
                            })}
                        </ol>
                    </div>
                </div>
            </div>

            <div className="hidden w-1/2 max-w-md flex-none border-l border-gray-100 py-10 px-8 md:block">
                <div className="flex items-center text-center text-gray-900">
                    <button
                        onClick={setPrevMonth}
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Previous month</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                    <div className="flex-auto font-semibold">{activeMonthDisplay}</div>
                    <button
                        onClick={setNextMonth}
                        type="button"
                        className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                        <span className="sr-only">Next month</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>
                </div>
                <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    {weekFormat.map(weekDay => <div>{weekDay.abbr}</div>)}
                </div>
                <div
                    className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                    {days.map((day, dayIdx) => (
                        <button
                            key={day.date}
                            type="button"
                            className={classNames(
                                'py-1.5 hover:bg-gray-100 focus:z-10',
                                day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                (day.isSelected || day.isToday) && 'font-semibold',
                                day.isSelected && 'text-white',
                                !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                                day.isToday && !day.isSelected && 'text-primary-600',
                                dayIdx === 0 && 'rounded-tl-lg',
                                dayIdx === 6 && 'rounded-tr-lg',
                                dayIdx === days.length - 7 && 'rounded-bl-lg',
                                dayIdx === days.length - 1 && 'rounded-br-lg'
                            )}
                            onClick={() => onSetActiveDay(day.date)}
                        >
                            <time
                                dateTime={day.date}
                                className={classNames(
                                    'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                    day.isSelected && day.isToday && 'bg-primary-600',
                                    day.isSelected && !day.isToday && 'bg-gray-900'
                                )}
                            >
                                {day.date.split('-').pop().replace(/^0/, '')}
                            </time>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}
