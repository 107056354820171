import React, {Component} from 'react'
import {
    createDialogResourceWithImage,
    deleteDialogResource,
    getDialogResource
} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import SimpleTable from "../../../components/simple-table";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getProp, UPDATE_PERM} from "../../../util/util";
import ModalSaveDialogResource from "../../../components/modal/modal-save-resource";
import {PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import Resources from "../../../data/services/resources";
import {Field, FieldsManager} from "../../../data/services/fields";
import Env from "../../../util/env";

export default class ClientsDialogChildTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: this.props.sortBy ?? "",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,
            removeImage: "",
            updateImage: ""
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen,
            removeImage: !!item ? this.state.removeImage : "",
            updateImage: !!item ? this.state.updateImage : ""
        })
    };

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    };

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    };

    handleInputChange = (fields, name, value) => {
        let removeImage = this.state.removeImage;
        let updateImage = this.state.updateImage;

        if ("ImagePath" === name && value) {
            removeImage = "";
            updateImage = 1;
        }

        if ("ImagePath" === name && !value) {
            removeImage = 1;
            updateImage = "";
        }

        this.setState({
            canSubmit: true,
            removeImage,
            updateImage
        });

        return FieldsManager.updateField(fields, name, value);
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    };

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    };

    getResourceName = () => {
        return this.props.resourceName ?? "";
    };

    getQuery = () => {
        return {
            ClientID: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    };

    getFields = (item = null, excludeFields) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'hidden', {hideTable: true}),
            ClientChildID: new Field("ClientChildID", '', [''], false, 'hidden', {hideTable: true}),
            ChildName: new Field("ChildName", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath;

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientChildImage, {
                        v: it?.CreateUpdateDate || item.CreateUpdateDate,
                        id: it?.ClientChildID || item?.ClientChildID,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                }
            })
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resourceDialogWidthClass, resourceDialogDisableOverflow} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <div className="flex-shrink-0 ml-auto mb-3">
                        {checkPerm(Resources.ClientChild, CREATE_PERM) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {this.props.createLabel || translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}
                    onEdit={checkPerm(Resources.ClientChild, UPDATE_PERM) ? this.handleToggleEditModel : null}
                    onDelete={checkPerm(Resources.ClientChild, UPDATE_PERM) ? this.handleToggleConfirmModal : null}
                />

                <ModalSaveDialogResource
                    title={"Edit Child Information"}
                    widthClass={resourceDialogWidthClass ? resourceDialogWidthClass : "max-w-lg"}
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    disableOverflow={resourceDialogDisableOverflow}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createDialogResourceWithImage({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign(
                                    params,
                                    {
                                        ClientChildID: this.state.selectedItem["ClientChildID"],
                                        removeImage: this.state.removeImage,
                                        updateImage: this.state.updateImage,
                                    }),
                                image: params.ImagePath,
                                resource: Resources.ClientChild,
                                piggyResource: Resources.ClientChild,
                                notificationMessage: translate("message.child_updated")
                            }));
                            this.handleToggleEditModel();
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                />

                <ModalSaveDialogResource
                    title={(this.props.createLabel ? this.props.createLabel : "Create " + this.props.dialogTitle)}
                    widthClass={resourceDialogWidthClass ? resourceDialogWidthClass : "max-w-lg"}
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    handleInputChange={this.handleInputChange}
                    disableOverflow={resourceDialogDisableOverflow}
                    onSubmit={(params) => {
                        if (params) {
                            delete params.ClientChildID;
                            this.props.dispatch(createDialogResourceWithImage({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign(
                                    params,
                                    {
                                        ClientID: this.getId(),
                                        removeImage: this.state.removeImage,
                                        updateImage: this.state.updateImage,
                                    }),
                                image: params.ImagePath,
                                resource: Resources.ClientChildCreate,
                                piggyResource: Resources.ClientChild
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
