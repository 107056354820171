import React, {Component} from 'react'
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import HorizontalTabs from "../../components/horizontal-tabs";
import Resources from "../../data/services/resources";
import {Field} from "../../data/services/fields";
import ClientsTab from "./clients-tab";
import MembersTab from "./members-tab";
import {checkPerm, READ_PERM} from "../../util/util";
import LocalStorage from "../../util/localStorage";
import DepartmentsTab from "./departments-tab";

class OrgChart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {
                    name: props.translate('tab.clients'),
                    resource: checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal,
                    current: true
                },
                {name: props.translate('tab.staff'), resource: Resources.Members, current: false},
                {name: props.translate('tab.departments'), resource: Resources.Departments, current: false}
            ],
            selectedTab: checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {

    }

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    /** Helpers
     ================================================================= */
    getDepartmentFields = () => {
        return {
            Department: new Field("Department", '', ['empty'], false, 'text')
        };
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0
        }
    };

    /** Render
     ================================================================= */
    render() {

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">

                        <HorizontalTabs
                            tabs={this.state.tabs}
                            handleTabChange={this.handleTabChange}
                        />

                        {(checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal) === this.state.selectedTab && (
                            <ClientsTab
                                dialogTitle={"Client"}
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.resource}
                                dialogResource={this.props.dialogResource}
                            />
                        )}

                        {Resources.Members === this.state.selectedTab && (
                            <MembersTab
                                dispatch={this.props.dispatch}
                                translate={this.props.translate}
                                resource={this.props.resource}

                                dialogTitle={"Member"}
                                id={LocalStorage.get("user")["UserID"]}

                                sortBy={"MemberID"}
                                primaryKey={"MemberID"}
                                resourceName={Resources.Members}
                                piggyResourceName={Resources.Members}

                                metadata={{
                                    DepartmentID: {
                                        api: 'api/' + Resources.Departments,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.DepartmentID,
                                            label: item.Department
                                        })
                                    },
                                    ReportsToID: {
                                        api: 'api/' + Resources.Members,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.MemberID,
                                            label: item.FirstName + " " + item.LastName
                                        })
                                    },
                                    SystemRoleID: {
                                        1: "Super Admin",
                                        2: "Clinical Director",
                                        3: "Behavior Analyst",
                                        4: "Behavior Therapist"
                                    }
                                }}
                            />
                        )}

                        {Resources.Departments === this.state.selectedTab && (
                            <>

                                <DepartmentsTab
                                    dispatch={this.props.dispatch}
                                    translate={this.props.translate}
                                    resource={this.props.resource}

                                    dialogTitle={"Department"}
                                    id={LocalStorage.get("user")["UserID"]}

                                    sortBy={"Department"}
                                    primaryKey={"DepartmentID"}
                                    resourceName={Resources.Departments}
                                    piggyResourceName={Resources.Departments}

                                    metadata={{
                                        DepartmentID: {
                                            api: 'api/' + Resources.Departments,
                                            query: {},
                                            searchMap: (item) => ({
                                                value: item.DepartmentID,
                                                label: item.Department
                                            })
                                        },
                                    }}
                                />

                            </>
                        )}
                    </div>
                </LayoutDashboard>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(OrgChart);