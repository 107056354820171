import React, {Component} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/solid";

export default class FieldText extends Component {

    render() {
        const addClass = "form-control " + (this.props.className ? " " + this.props.className : "");

        return (
            <React.Fragment>
                <div className="relative">
                    <input type={this.props.type ?? "text"}
                           onKeyDown={(e) => this.props.handleKeyDown ? this.props.handleKeyDown(e) : null}
                           onChange={(event) => {
                               this.props.onChange(this.props.name, event.target.value);
                           }}
                           name={this.props.name}
                           value={this.props.value}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           className={addClass}
                    />
                    {this.props.errorMessage && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                    )}
                </div>

                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        )
    }
}