import React, {Component} from "react";
import {classNames, fieldsToHtml, scrollErrorIntoView} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import DialogDefault from "../dialog-default";
import {deleteResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import ModalConfirm from "./modal-confirm";

export default class ModalSaveResource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields,
            canSubmit: false,
            confirmModalOpen: false
        };

        this.cancelButtonRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields,
                canSubmit: false
            })
        }

        if (prevProps.updateFieldValue !== this.props.updateFieldValue) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, [this.props.updateFieldName], this.props.updateFieldValue)
            })
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields, canSubmit: true});
    };

    handleToggleConfirmModal = () => {
        this.setState({ confirmModalOpen: !this.state.confirmModalOpen })
    }

    handleSubmit = () => {
        const { confirmModalOnSave, onSubmit } = this.props
        if (confirmModalOnSave) {
            this.handleToggleConfirmModal()
        } else {
            onSubmit(this.getValidFields())
        }
    }
    /** Helpers
     ================================================================= */
    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {onSubmit, onClose, visible, translate, title, metadata, onCustomActions, htmlAfter, allowSaveOnInputChange, confirmModalTitle, confirmModalText} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <DialogDefault
                title={title}
                widthClass={this.props.widthClass}
                visible={visible}
                blurBackdrop={this.props.blurBackdrop}
                onClose={onClose}
                translate={translate}
                disableOverflow={this.props.disableOverflow}
            >
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    {fieldsHtml}

                    {htmlAfter}
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">

                        {!!onCustomActions && onCustomActions()}

                        <button type="button"
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={(e) => onClose(false)}
                        >
                            {this.props.closeButtonName ? this.props.closeButtonName : translate("btn.close")}
                        </button>

                        {onSubmit && (
                            <button
                                type="submit"
                                className={classNames(
                                    "btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer",
                                    (allowSaveOnInputChange && !this.state.canSubmit) ? "hover:cursor-not-allowed bg-primary-300 hover:bg-primary-300" : ""
                                )}
                                onClick={() => {
                                    if (allowSaveOnInputChange) {
                                        this.state.canSubmit && this.handleSubmit(this.getValidFields())
                                    } else {
                                        this.handleSubmit(this.getValidFields())
                                    }
                                }}
                            >
                                {this.props.submitButtonName ? this.props.submitButtonName : translate("btn.save")}
                            </button>
                        )}
                    </div>
                </div>

                <ModalConfirm
                    title={confirmModalTitle}
                    text={confirmModalText}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        onSubmit(this.getValidFields())
                        this.handleToggleConfirmModal()
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </DialogDefault>

        );
    }
}
