import React, {Component} from 'react'
import {Field, FieldsManager} from "../../../data/services/fields";
import DialogDefault from "../../../components/dialog-default";
import FieldDate from "../../../components/field-date";
import FieldSwitch from "../../../components/field-switch";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {fillFieldsFromData} from "../../../util/util";
import Loader from "../../../components/loader";
import {getSecondResource, updateSecondResource} from "../../../data/actions/secondResource";
import FieldRadio from "../../../components/field-radio";
import ArrowSmUpIcon from "@heroicons/react/solid/ArrowSmUpIcon";
import ArrowSmDownIcon from "@heroicons/react/solid/ArrowSmDownIcon";

export default class WorkingHoursDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            workingHours: this.getDays(),
            selectedDay: 0
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.secondResource.data && !this.props.secondResource.isLoading && prevProps.secondResource.isLoading) {
            this.setState({
                workingHours: this.props.secondResource.data.map(it => this.getDay(it))
            });
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            resource: Resources.CompanyWorkingHours
        }))
    };

    submit = () => {
        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get("user"),
            params: {
                days: this.state.workingHours.map((fields, i) => Object.assign(FieldsManager.getFieldKeyValues(fields), {
                    CompanyWorkingHoursID: this.props.secondResource?.data[i]?.CompanyWorkingHoursID,
                    DayStartTime: fields.DayStartTime?.value,
                    DayEndTime: fields.DayEndTime?.value
                }))
            },
            resource: Resources.CompanyWorkingHours,
            piggyResource: Resources.CompanyWorkingHours
        }));
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleWorkingHoursChange = (name, value, i) => {
        let workingHours = this.state.workingHours;
        workingHours[i] = FieldsManager.updateField(workingHours[i], name, value)
        this.setState({
            workingHours: workingHours
        });
    }

    handleDayChange = (selectedDay) => {
        this.setState({selectedDay});
    }

    /** Helpers
     * ================================================================ */
    getDays = () => {
        let days = [
            this.props.translate('text.sunday'),
            this.props.translate('text.monday'),
            this.props.translate('text.tuesday'),
            this.props.translate('text.wednesday'),
            this.props.translate('text.thursday'),
            this.props.translate('text.friday'),
            this.props.translate('text.saturday')
        ];

        return days.map((day, i) => (this.getDay({
            DayName: days[i]
        })))
    }

    getDay = (item) => {
        const fieldTemplate = {
            DayName: new Field('DayName', '', ['']),
            WorkingDay: new Field('WorkingDay', item?.WorkingDay, ['']),
            DayStartTime: new Field('DayStartTime', item?.DayStartTime, ['']),
            DayEndTime: new Field('DayEndTime', item?.DayEndTime, [''])
        };

        return fillFieldsFromData(fieldTemplate, item);
    }


    applyTimesToAll = () => {
        let selectedDay = this.state.workingHours[this.state.selectedDay];

        const update = this.state.workingHours.map(day => {
            day.DayStartTime.value = selectedDay.DayStartTime.value;
            day.DayEndTime.value = selectedDay.DayEndTime.value;
            day.WorkingDay.value = selectedDay.WorkingDay.value;
            return day;
        });

        this.setState({
            workingHours: update
        })
    }

    /** Render
     ================================================================= */

    render() {
        const {translate, visible, onClose} = this.props;

        const days = this.state.workingHours.map((day, i) => {
            return (
                <div
                    key={day}
                    className="px-4 py-1 sm:px-6 grid grid-cols-1 gap-5 sm:grid-cols-4 mb-10 sm:mb-0"
                >
                    <div className="flex items-center bg-secondary-50 -mx-4 px-4 sm:bg-transparent sm:m-0 sm:px-0">
                        <FieldRadio
                            id={day.DayName.value}
                            className="mr-2 cursor-pointer"
                            onChange={() => this.handleDayChange(i)}
                            name="selectedDays"
                            value={this.state.selectedDay === i}
                        />

                        <label htmlFor={day.DayName.value} className="text-base font-medium cursor-pointer">
                            <span className="text-gray-900 text-lg text-bold">{day.DayName.value}</span>
                        </label>
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            Start Time:
                        </div>

                        <FieldDate
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="h:mm aa"
                            onChange={(name, value) => this.handleWorkingHoursChange(name, value, i)}
                            {...this.state.workingHours[i].DayStartTime}
                            className="form-control w-24 text-center"
                        />
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            End Time:
                        </div>

                        <FieldDate
                            showTimeSelect
                            showTimeSelectOnly
                            dateFormat="h:mm aa"
                            onChange={(name, value) => this.handleWorkingHoursChange(name, value, i)}
                            {...this.state.workingHours[i].DayEndTime}
                            className="form-control w-24 text-center"
                        />
                    </div>

                    <div className="flex sm:items-center sm:justify-center">
                        <div className="sm:hidden w-2/3 pr-5">
                            Working Day?
                        </div>

                        <FieldSwitch
                            value={this.state.workingHours[i].WorkingDay.value}
                            onChange={(value) => this.handleWorkingHoursChange("WorkingDay", value, i)}
                        />
                    </div>
                </div>
            )
        })

        return (
            <DialogDefault
                title="Set Working Hours"
                widthClass="max-w-2xl"
                disableOverflow
                // bgClass="bg-gray-50"
                visible={visible}
                blurBackdrop={true}
                onClose={onClose}
                translate={translate}
            >

                <div className="py-4 sm:space-y-4 mt-5">
                    <div className="px-4 py-1 sm:px-6 font-bold hidden sm:grid grid-cols-1 gap-5 sm:grid-cols-4">
                        <p>Day</p>
                        <p>Start Time</p>
                        <p>End Time</p>
                        <p>Working Day?</p>
                    </div>
                    {!!this.props.secondResource.isLoading && (
                        <div className={"sm:inset-center text-center mt-4 mb-4"}>
                            <Loader/>
                        </div>
                    )}

                    {!this.props.secondResource.isLoading && (
                        <button
                            type="button"
                            className="w-full mb-3 inline-flex sm:hidden justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm mx-auto"
                            onClick={() => this.applyTimesToAll()}
                        >
                            <ArrowSmDownIcon className="mr-auto w-5 h-5"/> Apply selected day settings to all
                        </button>
                    )}

                    <div className={this.props.secondResource.isLoading ? "invisible" : ""}>
                        {days}
                    </div>
                </div>

                <div className="pt-5">
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => this.submit()}
                        >
                            Confirm
                        </button>

                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => onClose()}
                        >
                            Cancel
                        </button>

                        <button
                            type="button"
                            className="w-full hidden sm:inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm mx-auto"
                            onClick={() => this.applyTimesToAll()}
                        >
                            <ArrowSmUpIcon className="-ml-2 w-5 h-5"/> Apply to all
                        </button>
                    </div>

                </div>
            </DialogDefault>
        )
    }
}