import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, DELETE_PERM, fillFieldsFromData, getProp, UPDATE_PERM} from "../../util/util";
import FieldSearch from "../../components/field-search";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import PageHeading from "../../components/page-heading";
import ArchivedSwitch from "../../components/archived-switch";

class CategoriesPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "DocumentationCategory",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false,

        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }
    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    handleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "DocumentationCategoryID";
    }

    getResourceName = () => {
        return Resources.Categories;
    }

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            DocumentationCategory: new Field("DocumentationCategory", '', ['empty'], false, "text")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {};

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">

                    <PageHeading
                        title="Categories"
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />

                            <div className="flex ml-auto mb-3 inline-block">
                                {checkPerm(this.getResourceName(), DELETE_PERM) && (
                                    <ArchivedSwitch
                                        classNameContainer="h-9 mr-2 mt-1"
                                        translate={translate}
                                        value={this.state.archived}
                                        onChange={(val) => this.setState({archived: val}, this.fetchData)}
                                    />
                                )}

                                {checkPerm(this.getResourceName(), CREATE_PERM) && (
                                    <div className="ml-auto mb-3">
                                        <button
                                            type="button"
                                            onClick={this.handleToggleCreateModal}
                                            className="btn btn-primary focus:ring-offset-inverse"
                                        >
                                            <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                            {translate("btn.add")}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) ? this.handleToggleEditModel : null}
                            onDelete={checkPerm(this.getResourceName(), DELETE_PERM) ? this.handleToggleConfirmModal : null}
                        />
                    </div>

                    <ModalSaveResource
                        title={"Edit Category"}
                        className="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={"Add Category"}
                        className="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </div>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(CategoriesPage);
