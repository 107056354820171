export function hideModals() {
    return {
        type: 'HIDE_MODALS',
        data: {}
    };
}

export function pushNotification(data) {
    return {
        type: 'PUSH_NOTIFICATION',
        data: data
    }
}

export function hideNotification(data) {
    return {
        type: 'HIDE_NOTIFICATION',
        data
    }
}