import React, {Component} from 'react'
import DialogDefault from "../../components/dialog-default";
import moment from "moment";
import {toFrontDate} from "../../util/util";

export default class MemberCardDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDay: 0
        };
    }

    /** Helpers
     * ================================================================ */


    /** Render
     ================================================================= */
    render() {
        const {translate, visible, onClose} = this.props;

        const clients = this.props?.member?.Clients?.map((client) => {
            return (
                <div
                    key={client}
                    className="px-4 py-1 sm:px-6 grid grid-cols-1 gap-5 sm:grid-cols-4 mb-10 sm:mb-0"
                >

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            Client Name:
                        </div>

                        {client.FirstName + " " + client.LastName}
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            Client Name:
                        </div>

                        {client.ChildName}
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            Joined on:
                        </div>
                        {toFrontDate(client.JoinedOnDate)}
                    </div>
                </div>
            )
        })

        const days = this.props?.member?.WorkingHours?.map((day) => {
            return (
                <div
                    key={day}
                    className="px-4 py-1 sm:px-6 grid grid-cols-1 gap-5 sm:grid-cols-4 mb-10 sm:mb-0"
                >
                    <div className="flex items-center bg-secondary-50 -mx-4 px-4 sm:bg-transparent sm:m-0 sm:px-0">
                        <label htmlFor={day.DayName} className="text-base font-medium cursor-pointer">
                            <span className="text-gray-900 text-lg text-bold">{day.DayName}</span>
                        </label>
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            Start Time:
                        </div>

                        {day.WorkingDay ? moment(day.DayStartTime).format("h:mm a") : "/"}
                    </div>

                    <div className="flex sm:block">
                        <div className="flex items-center sm:hidden w-2/3 pr-5">
                            End Time:
                        </div>

                        {day.WorkingDay ? moment(day.DayEndTime).format("h:mm a") : "/"}
                    </div>

                    <div className="flex sm:items-center sm:justify-center">
                        <div className="sm:hidden w-2/3 pr-5">
                            Working Day
                        </div>

                        <div>{day.WorkingDay ? "Yes" : "No"}</div>
                    </div>
                </div>
            )
        })

        return (
            <DialogDefault
                title="Staff Profile"
                widthClass="max-w-2xl"
                disableOverflow
                visible={visible}
                blurBackdrop={true}
                onClose={onClose}
                translate={translate}
            >
                <div className="border-t border-secondary-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-secondary-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium test-gray-500">Full name</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.props?.member?.FirstName} {this.props?.member?.LastName}</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium test-gray-500">Email</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.props?.member?.Email}</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium test-gray-500">Credentials</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.props?.member?.Credentials}</dd>
                        </div>


                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium test-gray-500">Bio</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.props?.member?.Bio ?? "/"}</dd>
                        </div>

                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium test-gray-500">Personal Statement</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.props?.member?.PersonalStatement ?? "/"}</dd>
                        </div>
                    </dl>
                </div>

                <div className="py-4 sm:space-y-4 mt-5">
                    <div className="px-4 py-1 sm:px-6 font-bold hidden sm:grid grid-cols-1 gap-5 sm:grid-cols-4">
                        <p>Client Name</p>
                        <p>Child Name</p>
                        <p>Joined on</p>
                    </div>

                    <div>
                        {clients}
                        {(!clients || (clients.length === 0)) && (
                            <div className="px-4 py-1 sm:px-6">
                                There are no Clients assigned to the Member.
                            </div>
                        )}
                    </div>
                </div>

                <div className="py-4 sm:space-y-4 mt-5">
                    <div className="px-4 py-1 sm:px-6 font-bold hidden sm:grid grid-cols-1 gap-5 sm:grid-cols-4">
                        <p>Day</p>
                        <p>Start Time</p>
                        <p>End Time</p>
                        <p>Working Day?</p>
                    </div>

                    <div>
                        {days}
                        {(!days || (days.length === 0)) && (
                            <div>
                                Working Hours not set by the member.
                            </div>
                        )}
                    </div>
                </div>

                <div className="pt-5">
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => onClose()}
                        >
                            Close
                        </button>
                    </div>

                </div>
            </DialogDefault>
        )
    }
}
