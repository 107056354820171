import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    CALENDAR_EVENT_ACCEPTED, CALENDAR_EVENT_CANCELED,
    CALENDAR_EVENT_CREATED, CALENDAR_EVENT_DENIED, CALENDAR_EVENT_UPDATED,
    fillFieldsFromData,
    getProp,
    toFrontDateTime
} from "../../util/util";
import FieldSearch from "../../components/field-search";
import SimpleTable from "../../components/simple-table";
import PageHeading from "../../components/page-heading";
import DialogDefault from "../../components/dialog-default";
import {getSecondResource} from "../../data/actions/secondResource";
import LoaderSmall from "../../components/loader-small";

class ScheduleLogsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "DateTime",

            query: "",
            archived: false,

            fields: this.getFields(),

            selectedItem: null,
            viewModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    fetchSingleScheduleLogs = (item) => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: {
                ScheduleItemID: item.ScheduleItemID
            },
            resource: Resources.ScheduleLogsSingle
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleViewModal = (item) => {
        if (item) {
            this.fetchSingleScheduleLogs(item)
        }
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    returnEventTrigger = (item) => {
        switch (item.EventTrigger) {
            case CALENDAR_EVENT_CREATED.toString():
                return "Event created"
            case CALENDAR_EVENT_ACCEPTED.toString():
                return "Event accepted"
            case CALENDAR_EVENT_DENIED.toString():
                return "Event denied"
            case CALENDAR_EVENT_UPDATED.toString():
                return "Event updated"
            case CALENDAR_EVENT_CANCELED.toString():
                return "Event canceled"
        }
    }

    getPrimaryKey = () => {
        return "ScheduleItemID";
    }

    getResourceName = () => {
        return Resources.ScheduleLogs;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            Client: new Field("Client", '', [], false, "text", {}),
            Member: new Field("Member", '', [], false, "text", {}),
            ClientChild: new Field("ClientChild", '', [], false, "text", {}),
            DateTime: new Field("DateTime", '', [], false, "datetime", {}),
            Duration: new Field("Duration", '', [], false, "text", {}),
            ScheduleLocation: new Field("ScheduleLocation", '', [], false, "text", {}),
            ScheduleType: new Field("ScheduleType", '', [], false, "text", {}),
            EventTrigger: new Field("EventTrigger", '', [], false, "custom", {
                render: (elem) => (
                    <td className="border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12">{this.returnEventTrigger(elem)}</td>
                )
            }),
            Notes: new Field("Notes", '', [], false, "text", {})
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resource, secondResource} = this.props;

        const data = getProp(resource, "data.list", []);
        const singleScheduleList = getProp(secondResource, "data.list", []);
        const singleScheduleIsLoading = getProp(secondResource, "isLoading", false);

        const count = getProp(resource, "data.count", 0);

        const isLoading = getProp(resource, "isLoading", false);

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">

                    <PageHeading
                        title="Schedule Logs"
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onRowClick={this.handleViewModal}
                            onView={this.handleViewModal}
                        />
                    </div>
                </div>

                <DialogDefault
                    visible={this.state.viewModalOpen}
                    translate={translate}
                    onClose={this.handleViewModal}
                    title={"Schedule Item logs"}
                >
                    {singleScheduleIsLoading && (
                        <div className="mt-3 flex rounded-btn bg-secondary-100 mb-6">
                            <LoaderSmall text="Loading"/>
                        </div>
                    )}
                    {!singleScheduleIsLoading && (
                        <div className={'grid grid-cols-2 gap-5'}>
                            <div>
                                <ol className="relative border-l border-gray-200 dark:border-gray-700">
                                    {singleScheduleList.map(item => {
                                        return (
                                            <li className="mb-10 ml-4">
                                                <div
                                                    className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                                                <time
                                                    className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                                    {toFrontDateTime(item.CreateUpdateDate)}
                                                </time>
                                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{this.returnEventTrigger(item)}</h3>
                                                <p className=" text-base font-normal text-gray-500 dark:text-gray-400">Date Time: {toFrontDateTime(item.DateTime)}</p>
                                                <p className=" text-base font-normal text-gray-500 dark:text-gray-400">Duration (in minutes): {item.Duration}</p>
                                                <p className=" text-base font-normal text-gray-500 dark:text-gray-400">Notes: {item.Notes}</p>
                                                <p className=" text-base font-normal text-gray-500 dark:text-gray-400">Location: {item.ScheduleLocation}</p>
                                                <p className=" text-base font-normal text-gray-500 dark:text-gray-400">Type: {item.ScheduleType}</p>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </div>
                            <div>
                                <div className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start "}>
                                    <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">Client</dt>
                                    <dd className="mt-1 text-gray-900 text-sm font-medium">{this.state.selectedItem?.Client}</dd>
                                </div>
                                <div className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                                    <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">Member</dt>
                                    <dd className="mt-1 text-gray-900 text-sm font-medium">{this.state.selectedItem?.Member}</dd>
                                </div>
                                <div className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                                    <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">Client Child</dt>
                                    <dd className="mt-1 text-gray-900 text-sm font-medium">{this.state.selectedItem?.ClientChild}</dd>
                                </div>
                            </div>
                        </div>
                    )}
                </DialogDefault>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(ScheduleLogsPage);
