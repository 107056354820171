import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import moment from "moment";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    getPageName = () => {
        switch (this.props.match.path) {
            case "/event-invite/:token":
                return "Appointment Confirmation";
            case "/reset-password":
                return "Reset Password";
            case "/login":
                return "K:P 360";
            case "/reset-password-confirm/:email/:token/:is_new":
            case "/reset-password-confirm/:email/:token":
                return "Set Password";
            default:
                return "Page not found"
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-gray-50 h-full text-default">
                    <Disclosure as="header" className="bg-white shadow">
                        {({open}) => (
                            <>
                                <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                                    <div className="relative h-16 flex justify-between">
                                        <div className="relative z-10 px-2 flex lg:px-0">
                                            <div className="flex-shrink-0 flex items-center">
                                                <Link to="/dashboard">
                                                    <img
                                                        className="block h-12 w-auto"
                                                        src="/images/logos/kp-logo.png?v=1.0.0"
                                                        alt="KP-360"
                                                    />
                                                </Link>
                                            </div>
                                        </div>

                                        <div
                                            className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0"
                                        >
                                            <h1 className="text-4xl text-gray-600">
                                                {this.getPageName()}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Disclosure>

                    <div>
                        {this.props.children}
                    </div>
                    {!this.props.isVideoRoom && (
                        <div className="text-gray-900 py-3 px-6 text-center text-sm">

                            <p className="text-center mb-4">Developed in partnership with <a
                                className="hover:opacity-70 text-primary-700 border-b border-primary-600 uppercase"
                                href={"https://lukasa.com/"} target={"_blank"}>Lukasa</a>, a modernization business and technology consulting firm.</p>
                            <span>©{moment().year()} K:P LABS | All rights reserved.
                                {/*| <Link className="border-transparent hover:opacity-70 text-primary-700" to={"/privacy-policy"}>Privacy</Link>*/}
                            </span>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
