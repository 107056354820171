import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import {connect} from "react-redux";
import {io} from "socket.io-client";
import LocalStorage from "../../util/localStorage";
import moment from "moment";
import Button from "../../components/button";

function currentDateTimeToUtc() {
    return moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss");
}

class Communication extends Component {

    constructor(props) {
        super(props);
        this.state = {
            messageValue: "",

            messages: [],
            conversations: [{
                ID: "john@doe.com",
                Name: "John"
            }, {
                ID: "jane@doe.com",
                Name: "Jane"
            }, {
                ID: "jack@doe.com",
                Name: "Jack"
            }],

            convTitle: "",
            convID: null,

            userList: [],

            connectError: false
        }

        this.socket = null;
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        // Connect to socket, and authorize

        this.socket = io("ws://localhost:4005", {
            auth: {
                token: LocalStorage.get('user')?.access_token
            }
        });

        // When socket is connected init listener for messages
        this.socket.on("connect", () => {
            console.log("Socket Connected", this.socket.id);

            // New messages listener
            this.socket.on("OnMessage", (data) => {
                console.log("OnMessage", data);

                let messages = this.state.messages;
                let conversations = this.state.conversations;

                if (data.IsItMe && (this.state.convID === data.ToID)) {
                    messages.push({
                        DateTime: currentDateTimeToUtc(),
                        ID: "message_" + messages.length,
                        ContactID: data.FromID,
                        IsRead: 1,
                        IsItMe: 1,
                        Name: data.FromName,
                        Msg: data.Message,
                        ImagePath: data.ImagePath
                    });
                } else if ((this.state.convID === data.FromID)) {
                    messages.push({
                        DateTime: currentDateTimeToUtc(),
                        ID: "message_" + messages.length,
                        ContactID: data.FromID,
                        IsRead: 1,
                        IsItMe: 0,
                        Name: data.FromName,
                        Msg: data.Message,
                        ImagePath: data.ImagePath
                    });
                } else {
                    let found = false;
                    conversations = this.state.conversations.map((it) => {
                        if (it.ID === data.FromID) {
                            found = true;
                            it.Unread++;
                            it.IsOnline = 1;
                        }
                        return it;
                    });

                    if (!data.IsItMe) {
                        if (!found) {
                            conversations.push({
                                DateTime: currentDateTimeToUtc(),
                                ID: data.FromID,
                                ContactID: data.ToID,
                                IsOnline: 1,
                                IsRead: false,
                                Name: data.FromName,
                                Msg: data.Message,
                                Unread: 1
                            })
                        }
                        // TODO Notify user with notification
                        alert("Nova poruka od " + data.FromName)
                    }
                }

                this.setState({
                    conversations: conversations,
                    messages: messages
                }, () => {

                });
            })
        });

        // When there is an error
        this.socket.on("connect_error", () => {
            this.setState({connectError: true})
        });

    }

    componentWillUnmount() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket.close();
            this.socket = null;
        }
    }

    /** Data Events
     ================================================================= */
    fetchUsers = () => {

    };

    fetchConversation = () => {

    };

    /** UI Events
     ================================================================= */
    handleSendMessage = () => {
        if (!this.state.messageValue) {
            return;
        }
        const SenderContactID = LocalStorage.get('user')?.Contact.ContactID;

        this.socket.emit("OnMessage", {
            SenderContactID: SenderContactID,
            Message: this.state.messageValue,
            ReceiverContactID: this.state.convID
        });

        this.setState({messageValue: ""});
    };

    handleSelectConversation = (id, name) => {
        this.setState({
            convID: id,
            convTitle: name,
            messages: []
        })
    };

    onEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleSendMessage();
        }
    }

    /** Helpers
     ================================================================= */
    getMyName = () => {
        return LocalStorage.get('user')?.Contact?.FirstName + " " + LocalStorage.get('user')?.Contact?.LastName;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        // List of conversations (users only for now)
        const conversations = this.state.conversations.map((item) => {
            return (
                <div className="hover:cursor-pointer pl-1" key={item.ID}
                     onClick={() => this.handleSelectConversation(item.ID, item.Name)}
                >
                    <p className={"mb-0 truncate user-status" + (item.IsOnline ? " user-status-online" : "")}>{item.Name}</p>
                    <p className="mb-1 text-muted text-small">{item.DateTime}</p>
                </div>
            );
        });

        // List of messages for currently selected conversations
        const messages = this.state.messages.map((item) => {
            return (
                <div key={item.ID}
                >
                    <div className={"w-3/4 p-4 " + (!item.IsItMe ? ("float-left") : ("float-right"))}>
                        <div
                            className={"fit-content p-4 rounded-lg " + (!item.IsItMe ? ("bg-primary-600") : ("bg-gray-300 float-right text-right"))}>
                            <div
                                className={"text-xs"}>{item.IsItMe ? this.getMyName() : item.Name} {item.DateTime}</div>
                            <div className="min-width-zero">
                                <p className="mb-0 truncate list-item-heading"></p>
                            </div>
                            <div className="chat-text-left">
                                <p className="mb-0 text-semi-muted">{item.Msg}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 lg:px-0 col-span-12">
                    <PageHeading
                        title="Communication"
                    />
                    <div className={"grid sm:grid-cols-6 border-primary-600 border-2 communication-height"}>
                        <div className={"col-span-1 border-primary-600 border-r-2 p-2"}>
                            <p className={"font-bold mb-2"}>My Groups</p>
                            <div className="hover:cursor-pointer pl-1 mb-10">
                                In Progress
                            </div>
                            <p className={"font-bold mb-2"}>People</p>
                            {conversations}
                        </div>
                        <div className={"sm:col-span-5"}>
                            <div>
                                <p className={"text-2xl p-4"}>{this.state.convTitle}</p>
                                <div className={"communication-height-messages"}>
                                    {messages}
                                </div>
                            </div>
                            {!!this.state.convTitle && (
                                <div>
                                    <div className="flex justify-between">
                                        <input
                                            name="messageValue"
                                            className="flex-grow m-2 py-2 px-4 mr-1 rounded-full border border-gray-300 bg-gray-200 resize-none"
                                            onKeyDown={(e) => this.onEnter(e)}
                                            onChange={(e) => this.setState({messageValue: e.target.value})}
                                            placeholder={translate("text.say_something")}
                                            value={this.state.messageValue}
                                        />
                                        <Button
                                            onClick={this.handleSendMessage}
                                            className="m-2"
                                            style={{outline: "none"}}>
                                            <svg
                                                className="svg-inline--fa text-green-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                                aria-hidden="true" focusable="false" data-prefix="fas"
                                                data-icon="paper-plane"
                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor"
                                                      d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
                                            </svg>
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    {this.state.connectError && (<div>Error</div>)}
                </div>
            </LayoutDashboard>
        )
    }

}

export default connect(state => state)(Communication);
