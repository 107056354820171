import React, {Component} from "react";
import {connect} from "react-redux";
import Layout from "../../components/layout";
import Resources from "../../data/services/resources";
import {getPublicResource, updatePublicResource} from "../../data/actions/publicResource";
import Button from "../../components/button";
import {classNames, fieldsToHtml, fillFieldsFromData} from "../../util/util";
import {BadgeCheckIcon, ExclamationIcon} from "@heroicons/react/outline";
import {Field} from "../../data/services/fields";
import Loader from "../../components/loader";

const ACTION_DENY = 2
const ACTION_SUBMIT = 1

class EventInviteView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields()
        };
    }

    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.publicResource?.data !== this.props.publicResource?.data) && !this.props.publicResource.isLoading) {
            this.setState({
                fields: this.getFields(this.props.publicResource?.data)
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getPublicResource({
            user: {
                access_token: this.props.match.params.token
            },
            query: {
                ScheduleItemToken: this.props.match.params.token
            },
            resource: Resources.PublicAppointmentConfirmation
        }))
    }

    onSubmit = (action) => {
        this.props.dispatch(updatePublicResource({
            user: {
                access_token: this.props.match.params.token
            },
            params: {
                Action: action,
                ScheduleItemToken: this.props.match.params.token
            },
            resource: Resources.PublicAppointmentConfirmation
        }))
    }

    getFields = (item = null) => {
        let fieldTemplates = {
            Client: new Field('Client', '', [], false, 'readonly'),
            ClientChild: new Field('ClientChild', '', [], false, 'readonly'),
            Member: new Field('Member', '', [], false, 'readonly'),
            ScheduleType: new Field('ScheduleType', '', [], false, 'readonly', {
                label: "Type"
            }),
            ScheduleLocation: new Field('ScheduleLocation', '', [], false, 'readonly'),
            DateTime: new Field('DateTime', '', [], false, 'readonly-datetime'),
            Duration: new Field('Duration', '', [], false, 'readonly'),
            Notes: new Field('Notes', '', [], false, 'readonly'),
        }
        return fillFieldsFromData(fieldTemplates, item);
    }

    render() {
        const {publicResource, translate} = this.props;

        const infoParagraphStyle = publicResource.error ? "bg-red-600/10" : "bg-green-600/10"
        const infoParagraphText = publicResource.error ? "Invite request failed." : "Appointment successfully confirmed! All involved parties will be notified via email."
        const infoParagraphIcon = publicResource.error ? <ExclamationIcon className="h-6 w-6"/> :
            <BadgeCheckIcon className="h-6 w-6"/>

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, () => {
        }, {});

        return (
            <Layout {...this.props}>
                <div className="px-6 pb-6 pt-12 sm:px-14 sm:pb-14 sm:pt-24">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg pt-8 px-8 pb-2  mx-auto">

                        {!!publicResource.isLoading && (
                            <div className={'flex justify-center p-5'}>
                                <Loader/>
                            </div>
                        )}

                        {!!publicResource.error && !publicResource.isLoading && (
                            <div>
                                <div
                                    className={"flex"}
                                >
                                    <div
                                        className={classNames("text-tm-gray-900 flex items-center flex-grow rounded-md overflow-hidden bg-red-600/10 mb-5")}
                                    >
                                        <div
                                            className={classNames("px-2 p-2 h-full flex items-center justify-center")}>
                                            <ExclamationIcon className="h-6 w-6"/>
                                        </div>

                                        <div className="pl-4 pr-6 py-2 text-current w-full text-center">
                                            Something went wrong, please try again by clicking link in your email.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {publicResource.update && !publicResource.isLoading && (
                            <div
                                className={"flex"}
                            >
                                <div
                                    className={classNames("text-tm-gray-900 flex items-center flex-grow rounded-md overflow-hidden mb-5", infoParagraphStyle)}
                                >
                                    <div
                                        className={classNames("px-2 p-2 h-full flex items-center justify-center")}>
                                        {infoParagraphIcon}
                                    </div>

                                    <div className="pl-4 pr-6 py-2 text-current w-full">
                                        {infoParagraphText}
                                    </div>
                                </div>
                            </div>
                        )}

                        {!publicResource.data && !publicResource.isLoading && (
                            <div>
                                <div
                                    className={"flex"}
                                >
                                    <div
                                        className={classNames("text-tm-gray-900 flex items-center flex-grow rounded-md overflow-hidden bg-red-600/10 mb-5")}
                                    >
                                        <div
                                            className={classNames("px-2 p-2 h-full flex items-center justify-center")}>
                                            <ExclamationIcon className="h-6 w-6"/>
                                        </div>

                                        <div className="pl-4 pr-6 py-2 text-current w-full text-center">
                                            Confirmation Link is not valid or has expired. <br/>
                                            Contact us directly if you have any questions.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!publicResource.error && publicResource.data && !publicResource.update && !publicResource.isLoading && (
                            <>
                                <h1 className={'text-2xl text-gray-600'}>Appointment Details</h1>
                                <div>{fieldsHtml}</div>
                                <div className="grid grid-cols-2 gap-5 mt-10 mb-3">
                                    <Button
                                        className="w-full justify-center capitalize"
                                        type="danger"
                                        onClick={() => this.onSubmit(ACTION_DENY)}>
                                        Deny
                                    </Button>
                                    <Button
                                        className="w-full justify-center capitalize"
                                        type="primary"
                                        onClick={() => this.onSubmit(ACTION_SUBMIT)}>
                                        Accept
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default connect(state => state)(EventInviteView);
