import React, {Component} from 'react'
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import SimpleTable from "../../components/simple-table";
import {
    checkPerm,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    fieldsSearchToHtml,
    fillFieldsFromData,
    getProp,
    READ_PERM, UPDATE_PERM
} from "../../util/util";
import {PlusIcon, RefreshIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../components/modal/modal-confirm";
import FieldSearch from "../../components/field-search";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import OrgChartClientsDialog from "./org-chart-clients-dialog";
import ArchivedSwitch from "../../components/archived-switch";
import Button from "../../components/button";
import ModalSaveResource from "../../components/modal/modal-save-resource";

export default class ClientsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "ClientID",

            searchFields: this.props.searchFields ?? {},

            fields: this.getFields(),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: Resources.Client,
            piggyResource: this.getResourceName()
        }));
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        }, !!item ? null : this.fetchData())
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        }, this.fetchData);
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    handleToggleInviteModal = (it) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ClientID: it.ClientID
            },
            resource: Resources.ClientInvite,
            piggyResource: this.getResourceName(),
            notificationMessage: "Client invited to the system"
        }));
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id ?? 0;
    }

    getPrimaryKey = () => {
        return "ClientID";
    }

    getResourceName = () => {
        return checkPerm(Resources.Clients, READ_PERM) ? Resources.Clients : Resources.MemberClientsExternal;
    }

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            showOnlyMy: this.state.showOnlyMy ? 1 : 0,
            searchFields: JSON.stringify(this.getValidSearchFields())
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', ['email'], false, 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            Children: new Field("Children", '', ['empty'], false, 'text'),
            /*
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath

                    return  !!imagePath ? Env.getApiUrl('api/' + Resources.ClientImage, {
                        v: it?.CreateUpdateDate,
                        id: it.ClientID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                id: item?.ClientID,
            })
             */
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, disableEdit, disableDelete} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isMemberSudo = getProp(this.props, "resource.data.isMemberSudo");

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const selectSearchFields = fieldsSearchToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchSelectQueryChange, []);

        return (
            <div className="">

                <div className="flex flex-wrap">
                    <FieldSearch
                        onChange={this.handleQueryChange}
                        name={"query"}
                        value={this.state.query}
                        placeholder="Search"
                        classNameContainer={"mr-6 mb-3"}
                    />

                    {!!selectSearchFields && selectSearchFields}

                    <div className="flex ml-auto mb-3 inline-block">

                        {isMemberSudo && (
                            <ArchivedSwitch
                                classNameContainer="h-9 mr-2 mt-1"
                                translate={translate}
                                value={this.state.showOnlyMy}
                                onChange={(val) => this.setState({showOnlyMy: val}, this.fetchData)}
                                labelText={translate("text.show_only_my")}
                            />
                        )}

                        <ArchivedSwitch
                            classNameContainer="h-9 mr-2 mt-1"
                            translate={translate}
                            value={this.state.archived}
                            onChange={(val) => this.setState({archived: val}, this.fetchData)}
                        />

                        {!this.props.disableCreate && checkPerm(Resources.Clients, CREATE_PERM) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {translate("btn.create")}
                            </button>
                        )}
                    </div>

                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onEdit={!disableEdit ? this.handleToggleEditModel : null}
                    onEditLabel={checkPerm(Resources.ClientInfo, UPDATE_PERM) ? "Edit" : "View"}

                    onDelete={(!disableDelete && checkPerm(Resources.Clients, DELETE_PERM)) ? this.handleToggleConfirmModal : null}
                    onDeleteLabel={translate("btn.archive")}
                    onRestore={this.handleRestore}

                    onCustomAction={(it) => {
                        return (
                            <>
                                {checkPerm(Resources.ClientInvite, CREATE_PERM) && !it.ArchivedDate && (
                                    <Button
                                        type="button"
                                        onClick={() => this.handleToggleInviteModal(it)}
                                        className={"mx-1 inline-flex items-center px-4 py-2 border border-primary-300 rounded-md shadow-sm text-sm font-medium " +
                                        "text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500 " + (it.ContactID ? ("hover:bg-primary-600") : ("hover:bg-primary-50"))}
                                    >
                                        <RefreshIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                        <span className="hidden sm:inline">{it.ContactID ? "Resend" : "Invite"}</span>
                                    </Button>
                                )}
                            </>
                        )
                    }}
                />

                <OrgChartClientsDialog
                    translate={translate}
                    visible={this.state.editModalOpen}
                    selectedItem={this.state.selectedItem}
                    getClientFields={(item = null) => this.getFields(item, ['ChildName'])}
                    getQuery={this.getQuery}
                    onClose={this.handleToggleEditModel}
                    {...this.props}
                />

                <ModalSaveResource
                    title={"Add Client"}
                    disableOverflow={true}
                    widthClass="max-w-4xl"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields(null, ['ImagePath', 'ChildName', 'Children'])}
                    handleInputChange={(fields, name, value) => {
                        return FieldsManager.updateField(fields, name, value);
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            params.AssignMemberToClient = (checkPerm(Resources.Clients, READ_PERM) ? 0 : 1);
                            this.props.dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.Client,
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={{}}
                />

                <ModalConfirm
                    title={translate("modal_title.confirm_archive")}
                    text={translate("text.confirm_archive")}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.Client,
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
