import React, {Component} from 'react'
import {classNames, fieldsToHtml, scrollErrorIntoView} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import Loader from "../loader";

export default class ResourceTabForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItem !== prevProps.selectedItem) {
            this.setState({
                fields: this.props.fields
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, title, onClose, onCustomActions, onSubmit, isLoading, metadata} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <div className="">
                {!!title && (
                    <h2 className="text-lg font-semibold leading-7 text-gray-700 sm:text-xl">{title}</h2>
                )}

                <div className="relative">
                    {isLoading && (
                        <div className="inset-0 absolute flex justify-center items-center">
                            <Loader/>
                        </div>
                    )}

                    <div className={isLoading ? "invisible" : ""}>
                        {fieldsHtml}
                    </div>

                    <div
                        className={classNames("flex border-t border-gray-200 pt-6 mt-6", isLoading ? "invisible" : "")}>
                        <div className="ml-auto">
                            {!!onCustomActions && onCustomActions()}

                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={(e) => onClose()}
                            >
                                {this.props.cancelButtonName ? this.props.cancelButtonName : translate("btn.cancel")}
                            </button>

                            <button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={() => onSubmit(this.getValidFields())}
                            >
                                {this.props.submitButtonName ? this.props.submitButtonName : translate("btn.save")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
