import React from "react";

const Button = (props) => {
    let {className, type, disabled, onClick, innerRef} = props;

    let typeClass;
    switch (type) {
        case "danger":
            typeClass = "btn-danger"
            break;
        case "primary":
            typeClass = "btn-primary"
            break;
        case "outline":
            typeClass = "btn-outline"
            break;
        case "secondary":
            typeClass = "btn-outline"
            break;
        case "secondary-outline":
            typeClass = "btn-outline"
            break;
        case "link":
            typeClass = "btn-link"
            break;
        default:
            typeClass = ""
    }

    className = className ? " " + className : "";
    let disabledClass = disabled ? " btn-disabled" : " " + typeClass;

    return (
        <React.Fragment>
            <button
                ref={innerRef}
                className={"select-none btn" + className + disabledClass} onClick={(e) => {

                !disabled && !!onClick && onClick(e);
            }}
            >
                {props.children}
            </button>
        </React.Fragment>
    )

}

export default Button;
