import React, {Fragment, useEffect, useState} from 'react';
import moment from "moment";
import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    DotsHorizontalIcon,
} from '@heroicons/react/solid'
import {Menu, Transition} from '@headlessui/react'

import CalendarMonthView from "./calendar-month-view";
import CalendarWeekView from "./calendar-week-view";
import CalendarDayView from "./calendar-day-view";
import CalendarYearView from "./calendar-year-view";
import {
    checkPerm,
    CREATE_PERM,
    DELETE_PERM,
    getProp,
    LOGGED_IN_USER_TYPE_ADMIN,
    LOGGED_IN_USER_TYPE_CLIENT,
    LOGGED_IN_USER_TYPE_MEMBER,
    UPDATE_PERM
} from "../../util/util";
import DialogDefault from "../dialog-default";
import DocumentIcon from "@heroicons/react/solid/DocumentIcon";
import {cloneDeep} from "tailwindcss/lib/util/cloneDeep";
import {createResource, deleteResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import ModalSaveResource from "../modal/modal-save-resource";
import Loader from "../loader";

import PencilIcon from "@heroicons/react/solid/PencilIcon";
import XIcon from "@heroicons/react/solid/XIcon";
import PlusIcon from "@heroicons/react/solid/PlusIcon";
import ModalConfirm from "../modal/modal-confirm";
import {Field, FieldsManager} from "../../data/services/fields";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const viewMenu = [
    {id: "day", name: "Day view",},
    {id: "week", name: "Week view"},
    {id: "month", name: "Month view"},
    // {id: "year", name: "Year view"},
]

const weekFormat = [
    {id: 0, name: "Sunday", abbr: "S", abbrExt: "un"},
    {id: 1, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 2, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 3, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 4, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 5, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 6, name: "Saturday", abbr: "S", abbrExt: "at"},
];

const isoWeekFormat = [ // may be used in future
    {id: 0, name: "Monday", abbr: "M", abbrExt: "on"},
    {id: 1, name: "Tuesday", abbr: "T", abbrExt: "ue"},
    {id: 2, name: "Wednesday", abbr: "W", abbrExt: "ed"},
    {id: 3, name: "Thursday", abbr: "T", abbrExt: "hu"},
    {id: 4, name: "Friday", abbr: "F", abbrExt: "ri"},
    {id: 5, name: "Saturday", abbr: "S", abbrExt: "at"},
    {id: 6, name: "Sunday", abbr: "S", abbrExt: "un"},
];

const getEventDurationClasses = (duration) => {
    switch (duration) {
        case 15:
            return "px-2";
        case 30:
            return "px-2 py-1";
        default:
            return "p-2";
    }
}

const getWeekFormat = () => {
    return weekFormat;
}

const getMonth = () => {
    return moment().month() + 1;  // +1 since months are zero indexed
}

const DailyEvents = (props) => {
    const {selectedEvents, editEvent, translate} = props;
    return (
        <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {!!selectedEvents && selectedEvents.map((event) => (
                <li key={event.id}
                    className="group relative flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                    <div className="flex-auto">
                        <div className="flex-shrink-0 flex items-center">
                                        <span
                                            className={classNames("h-1.5 w-1.5 rounded-full mr-2", event.color['bg400'])}/>
                            <p className="font-semibold text-gray-900">{event.name}</p>
                        </div>

                        <time dateTime={event.datetime}
                              className="mt-2 flex items-center text-gray-700">
                            <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                            {event.time}
                        </time>

                        {!!event.metadata?.ScheduleItem?.Notes && (
                            <div className="hidden lg:block mt-2 ">
                                <DocumentIcon className="mr-2 h-5 w-5 text-gray-400 float-left" aria-hidden="true"/>
                                {event.metadata.ScheduleItem.Notes}
                            </div>
                        )}
                    </div>

                    {checkPerm(Resources.ScheduleItem, UPDATE_PERM) && (
                        <button
                            onClick={() => editEvent(event?.metadata?.ScheduleItem)}
                            className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 shadow-sm hover:bg-gray-50 focus:opacity-100"
                        >
                            <span className="hidden sm:inline">{translate("btn.edit")}</span> <PencilIcon
                            className="w-5 h-5 sm:hidden"/><span className="sr-only">, {event.name}</span>
                        </button>
                    )}
                </li>
            ))}
        </ol>
    )
}

export default function CalendarNew(props) {
    const {
        events,
        onFetchData,
        onToggleCreateModal,
        onToggleEditModal,
        translate,
        selects,
        getQuery,
        getFields,
        dispatch,
        isLoading,
        calendarHeight = ""
    } = props;
    const numOfDaysDisplayed = 42;
    const today = moment().format("YYYY-MM-DD");
    const appointmentDateTimeDurationDifference = 30

    const [activeView, setActiveView] = useState("month");

    const [activeDay, setActiveDay] = useState(today);
    const [activeWeek, setActiveWeek] = useState(moment().week())
    const [activeMonth, setActiveMonth] = useState(getMonth())
    const [activeYear, setActiveYear] = useState(moment().year());

    const [isMoreDialogVisible, setIsMoreDialogVisible] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [appointmentTimeModalOpen, setAppointmentTimeModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);

    const [squashedEventTitle, setSquashedEventTitle] = useState("");

    const firstDay = moment(activeMonth + " " + activeYear, "M YYYY").startOf('month');
    const activeMonthDisplay = moment(activeMonth + " " + activeYear, "M YYYY").format("MMMM YYYY");

    const buffer = !!firstDay.day() ? firstDay.day() : 7; // Draw one whole buffer row is there are no buffer days

    let calendarDay = firstDay.subtract("days", buffer);
    const calendarDays = Array.from(Array(numOfDaysDisplayed).keys());

    const days = calendarDays.map((it, i) => {
        const thisDay = calendarDay.clone().add("days", i).format('YYYY-MM-DD');

        return (
            {
                date: thisDay,
                day: moment(thisDay, 'YYYY-MM-DD').format("DD"),
                isCurrentMonth: moment(activeMonth + " " + activeYear, "M YYYY").isSame(thisDay, 'month'),
                isCurrentWeek: moment(moment().week(activeWeek)).isSame(thisDay, 'week'),
                isSelected: thisDay === activeDay,
                isToday: thisDay === today,
                events: getProp(events, thisDay, [])
            }
        );
    })

    let selectedDay = days.find(day => day.date === activeDay);

    if (!selectedDay) {
        days.find(day => day.date === activeDay)
    }

    const [selectedEvents, setSelectedEvents] = useState([]);
    const handleMoreClick = (events, date, squashedEventTitle) => {
        setIsMoreDialogVisible(true);
        setSelectedEvents(events);
        setActiveDay(date);

        if (squashedEventTitle) {
            setSquashedEventTitle(squashedEventTitle)
        }
    }

    const handleToggleAptTimeModal = () => {
        setAppointmentTimeModalOpen(!appointmentTimeModalOpen)
    }

    const handleSelectDayClick = (day) => {
        if (day.date === activeDay) {
            if (!day.events.length && props.LogedInUserType !== LOGGED_IN_USER_TYPE_ADMIN) {
                onToggleCreateModal(day.date)
            } else {
                showEvents(day)
            }
        } else {
            setActiveDay(day.date);
        }
    }

    const showEvents = (day) => {
        setSelectedEvents(day.events);
        setActiveDay(day.date);
        setIsMoreDialogVisible(true);
    }

    const handleCreateEventClick = (day) => {
        onToggleCreateModal(day.date);
        setActiveDay(day.date);
    }

    const setNextMonth = () => {
        if (activeMonth === 12) {
            setActiveMonth(1);
            setActiveYear(Number(activeYear) + 1);
        } else {
            setActiveMonth(Number(activeMonth) + 1);
        }
    }

    const setPrevMonth = () => {

        if (activeMonth === 1) {
            setActiveMonth(12);
            setActiveYear(Number(activeYear) - 1);
        } else {
            setActiveMonth(Number(activeMonth) - 1);
        }
    }

    const setNextWeek = () => {
        const nextWeek = Number(activeWeek) + 1;
        const nextWeekMonth = moment(nextWeek, 'w').format('M');

        if (activeMonth !== Number(nextWeekMonth)) {
            setActiveMonth(Number(activeMonth) + 1);
        } else {
            setActiveWeek(nextWeek);
        }
    }

    const setPrevWeek = () => {
        const prevWeek = activeWeek - 1;
        const prevWeekMonth = moment(activeWeek, 'w').format('M');

        if (activeMonth !== Number(prevWeekMonth)) {
            setActiveMonth(Number(activeMonth) - 1);
        } else {
            setActiveWeek(prevWeek);
        }
    }

    const setNextDay = () => {
        const nextDay = moment(activeDay, 'YYYY-MM-DD').add(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const nextDayMonth = nextDay.format('M');

        if (currentDayMonth !== nextDayMonth) {
            setActiveMonth(Number(currentDayMonth) + 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            setActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (nextDay.week() !== activeWeek) {
            setActiveWeek(nextDay.week());
        }

        setActiveDay(nextDay.format('YYYY-MM-DD'));
    }

    const setPrevDay = () => {
        const prevDay = moment(activeDay, 'YYYY-MM-DD').subtract(1, "days");
        const currentDayMonth = moment(activeDay, 'YYYY-MM-DD').format('M');
        const prevDayMonth = prevDay.format('M');

        if (activeMonth !== Number(prevDayMonth)) {
            setActiveMonth(Number(activeMonth) - 1);
        }

        if (Number(currentDayMonth) !== Number(activeMonth)) {
            setActiveMonth(currentDayMonth); // Brings back focus to activeDay month in day view
        }

        if (prevDay.week() !== activeWeek) {
            setActiveWeek(prevDay.week());
        }

        setActiveDay(prevDay.format('YYYY-MM-DD'));
    }

    const resetActiveDate = () => {
        setActiveYear(moment().year());
        setActiveMonth(getMonth());
        setActiveWeek(moment().week());
        setActiveDay(today);
    }

    const handleNextClick = () => {
        if ("month" === activeView) {
            setNextMonth();
        }

        if ("week" === activeView) {
            setNextWeek();
        }

        if ("day" === activeView) {
            setNextDay();
        }
    }

    const handlePrevClick = () => {
        if ("month" === activeView) {
            setPrevMonth()
        }

        if ("week" === activeView) {
            setPrevWeek();
        }

        if ("day" === activeView) {
            setPrevDay();
        }
    }

    const handleViewChangeClick = (id) => {
        setActiveView(id);
        resetActiveDate();
    }

    const editEvent = (it, isInnerModal = false) => {
        if (isInnerModal) {
            setIsEditModalOpen(true);
            setSelectedItem(it);
        } else {
            onToggleEditModal(it);
        }
    }

    const renderEventButton = (event) => {
        const eventDurationMinutes = event?.metadata?.ScheduleItem?.Duration;
        const eventSquashedDurationMinutes = event.squashedDuration;
        const squashedEventTitle = !!event.squashed ? translate("text.overlap_events_title") + " " + moment(event.squashedTimeStart, "HH:mm").format("hh:mm a") : null;

        return (
            <div tabIndex="0"
                 onClick={() => event.squashed ? handleMoreClick(event.eventSquashedItems, event.date, squashedEventTitle) : onToggleEditModal(event?.metadata?.ScheduleItem)}
                 className={classNames(eventSquashedDurationMinutes === 15 ? "overflow-hidden" : "overflow-y-auto", "cursor-pointer group absolute text-left w-full max-w-[calc(100%-.5rem)] inset-1 flex flex-col rounded-lg text-xs leading-5", event.squashedBg ?? event.color["bg50"], event.color["hoverBg100"], getEventDurationClasses(eventDurationMinutes))}
            >
                {!event.squashed && (
                    <React.Fragment>
                        <p className={classNames("order-1 font-semibold", event.color["text700"])}>{event.name}</p>
                        <p className={classNames("flex", event.color["text500"], event.color["groupHoverText700"])}>
                            <span
                                className={classNames("flex-auto truncate", eventDurationMinutes !== 15 ? "hidden" : "")}>{event.name}</span>
                            <span>{event.displayTime}</span>
                        </p>

                        {/*{event?.metadata?.ScheduleItem.Notes && (*/}
                        {/*    <p className={classNames("order-2 mt-3", event.color["text500"])}>{event.metadata.ScheduleItem.Notes}</p>*/}
                        {/*)}*/}
                    </React.Fragment>
                )}

                {event.squashed && (
                    <React.Fragment>
                        <p className={classNames("flex text-gray-700 group-hover:text-gray-900")}>
                            <span
                                className={classNames("flex-auto truncate", eventDurationMinutes !== 15 ? "hidden" : "")}>{event.eventSquashedItems.length} items</span>
                            <span>{event.displayTime}</span>
                        </p>

                        <p className="text-gray-800 font-bold mb-2">{event.eventSquashedItems.length} <span
                            className="hidden xl:inline">Overlapping</span> items</p>

                        {event.eventSquashedItems.map((squashedEvent, i) => {
                            return (
                                <span className="mb-2">
                                    <p className={classNames(squashedEvent.color["groupHoverText700"], "flex-auto truncate font-medium text-gray-900 leading-tight flex items-center")}>
                                        <div className={classNames(
                                            squashedEvent.color["bg400"],
                                            "w-2 h-2 shrink-0 rounded-full mr-1"
                                        )}
                                        />

                                        {squashedEvent.name}
                                    </p>
                                    <time
                                        dateTime={squashedEvent.datetime}
                                        className={classNames(squashedEvent.color["groupHoverText700"], "ml-3 block flex-none text-gray-500 leading-tight")}
                                    >
                                        {squashedEvent.durationDisplay.replaceAll(":00", "")}
                                    </time>
                                </span>
                            )
                        })}
                    </React.Fragment>
                )}
            </div>
        )
    }

    const sortDayEvents = (day) => {
        let dayClone = cloneDeep(day);
        let eventSquashedItems = [];
        let squashedTimeStart = null;
        let squashedDuration = 0;

        dayClone.events = dayClone.events
            .sort((a, b) => {
                return moment(a.time, 'HH:mm').unix() - moment(b.time, 'HH:mm').unix()
            })

        dayClone.events
            .sort((a, b) => {
                return moment(a.time, 'HH:mm').unix() - moment(b.time, 'HH:mm').unix()
            }) // sort by date
            .map((event, eventIndex) => {
                const previousEvent = !!dayClone.events[eventIndex - 1] && dayClone.events[eventIndex - 1];
                const nextEvent = !!dayClone.events[eventIndex + 1] && dayClone.events[eventIndex + 1];

                const isOverlappingWithPrevious = !!previousEvent && moment(event.time, "HH:mm").isBefore(moment(previousEvent.timeEnd, "HH:mm"));
                const isOverlappingWithNext = !!nextEvent && moment(event.timeEnd, "HH:mm").isAfter(moment(nextEvent.time, "HH:mm"));

                // First squashed event

                if (!isOverlappingWithPrevious && isOverlappingWithNext) {
                    squashedTimeStart = event.time;
                    squashedDuration = event.duration;
                }

                if (isOverlappingWithPrevious) {
                    event.squashedTimeStart = squashedTimeStart;
                    event.squashedDuration = Math.max(squashedDuration, event.duration);
                }

                if (isOverlappingWithNext) {
                    event.hidden = true;
                }

                if (isOverlappingWithPrevious || isOverlappingWithNext) {
                    eventSquashedItems.push({
                        color: event.color,
                        time: event.time,
                        name: event.name,
                        durationDisplay: event.durationDisplay,
                        date: event.date,
                        metadata: {ScheduleItem: event?.metadata?.ScheduleItem}
                    });
                }

                // Set values to last/visible event
                if (isOverlappingWithPrevious && !isOverlappingWithNext) {
                    squashedDuration = 0;
                    event.eventSquashedItems = eventSquashedItems;
                    event.squashedBg = "bg-white border shadow"
                    eventSquashedItems = [];
                    event.squashed = true;
                }

                return event;
            });

        return dayClone;
    }

    const getCurrentWeek = (days) => {
        return days.filter((it, i) => it.isCurrentWeek).map(day => {
            return sortDayEvents(day);
        });
    }

    useEffect(() => {
        onFetchData(activeYear, activeMonth);
    }, [activeYear, activeMonth])

    useEffect(() => {
        if (!!selectedDay?.date) {
            setSelectedEvents(selectedEvents ?? events[selectedDay.date]);
        }
    }, [events])

    const canAcceptDennyAppt = ((props.LogedInUserType === LOGGED_IN_USER_TYPE_MEMBER && !!selectedItem?.IsParentScheduled) || (props.LogedInUserType === LOGGED_IN_USER_TYPE_CLIENT && !selectedItem?.IsParentScheduled)) && selectedItem?.ScheduleItemStatus === 0

    return (
        <React.Fragment>
            <div className="sm:mb-6 lg:mb-8 rounded-lg border border-gray-200 overflow-hidden">
                <header
                    className="relative flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none z-20">
                    <h1 className="text-lg font-semibold text-gray-900">
                        <span>{activeMonthDisplay}</span>
                    </h1>
                    <div className="flex items-center">
                        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                            <button
                                onClick={handlePrevClick}
                                type="button"
                                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous month</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            <button
                                onClick={resetActiveDate}
                                type="button"
                                className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            >
                                Today
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                            <button
                                onClick={handleNextClick}
                                type="button"
                                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Next month</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <Menu as="div" className="relative">
                                <Menu.Button
                                    type="button"
                                    className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                                >
                                    <span><span className="capitalize">{activeView}</span> view</span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items
                                        className="focus:outline-none absolute text-left right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            {viewMenu.map(it => (
                                                <Menu.Item key={it.id}>
                                                    {({active}) => (
                                                        <button
                                                            onClick={() => handleViewChangeClick(it.id)}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'text-left w-full block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            {it.name}
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>

                            {checkPerm(Resources.ScheduleItem, CREATE_PERM) && props.LogedInUserType !== LOGGED_IN_USER_TYPE_ADMIN && (
                                <>
                                    <div className="ml-6 h-6 w-px bg-gray-300"/>
                                    <button
                                        onClick={() => onToggleCreateModal(activeDay)}
                                        type="button"
                                        className="focus:outline-none ml-6 rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                    >
                                        {translate(props.isParent ? ("btn.request_appointment") : ("btn.add_appointment"))}
                                    </button>
                                </>
                            )}
                        </div>
                        <Menu as="div" className="relative ml-6 md:hidden">
                            <Menu.Button
                                className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Open menu</span>
                                <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true"/>
                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                    {checkPerm(Resources.ScheduleItem, CREATE_PERM) && (
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <button
                                                        onClick={() => onToggleCreateModal(activeDay)}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'w-full text-left block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Add Appointment
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    )}
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <button
                                                    onClick={resetActiveDate}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'w-full text-left block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    Go to today
                                                </button>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                        {viewMenu.map(it => (
                                            <Menu.Item key={it.id}>
                                                {({active}) => (
                                                    <button
                                                        onClick={() => handleViewChangeClick(it.id)}
                                                        className={classNames(
                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                            'text-left w-full block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        {it.name}
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </header>

                {activeView === 'day' && (
                    <CalendarDayView
                        days={days}
                        weekFormat={getWeekFormat()}
                        selectedDay={selectedDay}
                        activeMonthDisplay={activeMonthDisplay}
                        onSetActiveDay={setActiveDay}
                        setNextMonth={setNextMonth}
                        setPrevMonth={setPrevMonth}
                        onToggleEditModal={onToggleEditModal}
                        renderEventButton={renderEventButton}
                        calendarHeight={calendarHeight}
                        sortDayEvents={sortDayEvents}
                    />
                )}

                {activeView === 'week' && (
                    <CalendarWeekView
                        days={days}
                        weekFormat={getWeekFormat()}
                        selectedDay={selectedDay}
                        activeMonth={activeMonth}
                        setActiveDay={setActiveDay}
                        activeMonthDisplay={activeMonthDisplay}
                        onSetActiveDay={setActiveDay}
                        renderEventButton={renderEventButton}
                        getCurrentWeek={getCurrentWeek}
                        calendarHeight={calendarHeight}
                        sortDayEvents={sortDayEvents}
                    />
                )}

                {activeView === 'month' && (
                    <CalendarMonthView
                        days={days}
                        weekFormat={getWeekFormat()}
                        onSetActiveDay={setActiveDay}
                        onMoreClick={handleMoreClick}
                        onSelectDayClick={handleSelectDayClick}
                        onToggleEditModal={onToggleEditModal}
                        onCreateEventClick={handleCreateEventClick}
                        onShowEvents={showEvents}
                    />
                )}

                {activeView === 'year' && (
                    <CalendarYearView/>
                )}
            </div>

            <DialogDefault
                title={!!squashedEventTitle ? squashedEventTitle : "Events for " + moment(activeDay).format("MM/DD/YYYY")}
                widthClass="max-w-2xl"
                disableOverflow
                visible={isMoreDialogVisible}
                onClose={() => {
                    setIsMoreDialogVisible(false);
                }}
                translate={translate}
            >
                {isLoading && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <Loader/>
                    </div>
                )}

                <div className={classNames(isLoading ? "invisible" : "", "pt-2 pb-10 px-4 sm:px-6 relative")}>
                    {checkPerm(Resources.ScheduleItem, CREATE_PERM) && props.LogedInUserType !== LOGGED_IN_USER_TYPE_ADMIN && (
                        <div className="text-right">
                            <button
                                className="btn btn-outline mb-6 hidden sm:inline"
                                onClick={() => setIsCreateModalOpen(true)}
                            >
                                {translate(props.isParent ? ("btn.request_appointment") : ("btn.add_appointment"))}
                            </button>

                            <button
                                className="btn btn-primary px-2 rounded-full mb-6 sm:hidden"
                                onClick={() => setIsCreateModalOpen(true)}
                            >
                                <PlusIcon className="w-5 h-5"/>
                            </button>
                        </div>
                    )}

                    <DailyEvents
                        selectedEvents={selectedEvents}
                        editEvent={(event) => editEvent(event, true)}
                        translate={translate}
                    />

                </div>

                {/* Inner dialogs */}
                <ModalSaveResource
                    title={translate(props.isParent ? "modal_title.appointment" : "modal_title.edit_appointment")}
                    widthClass="max-w-4xl"
                    visible={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    fields={getFields(selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: getQuery(),
                                params: Object.assign({ScheduleItemID: selectedItem.ScheduleItemID}, params),
                                resource: Resources.ScheduleItem,
                                piggyResource: Resources.ScheduleItem
                            }));
                            setIsEditModalOpen(false);
                            setIsMoreDialogVisible(false);
                        }
                    }}
                    confirmModalOnSave={!props.isParent}
                    allowSaveOnInputChange={!props.isParent}
                    confirmModalTitle={translate("modal_title.confirm_update_apt")}
                    confirmModalText={translate("text.update_apt")}
                    translate={translate}
                    metadata={selects}
                    onCustomActions={selectedItem?.ScheduleItemID ? () => {
                        return (
                            <React.Fragment>
                                <>
                                    {props.LogedInUserType === LOGGED_IN_USER_TYPE_CLIENT && (
                                        <button
                                            type="button"
                                            className="btn rounded-md mr-auto border-yellow-500 bg-yellow-500 text-white sm:bg-white sm:text-yellow-500 hover:bg-yellow-100"
                                            onClick={handleToggleAptTimeModal}
                                        >
                                            <ClockIcon className="w-5 h-5 sm:hidden"/>

                                            <span className="hidden sm:inline">Change Appointment Time</span>
                                        </button>
                                    )}

                                    {canAcceptDennyAppt && (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-danger mr-auto bg-red-600 text-white sm:bg-white sm:text-red-600"
                                                // onClick={props.handleDenyAptClick}
                                            >
                                                <XIcon className="w-5 h-5 sm:hidden"/>

                                                <span className="hidden sm:inline">Deny Appointment</span>
                                            </button>

                                            <button
                                                type="button"
                                                className="btn rounded-md mr-auto border-green-600 bg-green-600 text-white sm:bg-white sm:text-green-600 hover:bg-green-100"
                                                onClick={props.handleAcceptAptClick}
                                            >
                                                <XIcon className="w-5 h-5 sm:hidden"/>

                                                <span className="hidden sm:inline">Accept Appointment</span>
                                            </button>
                                        </>
                                    )}

                                    {checkPerm(Resources.ScheduleItem, DELETE_PERM) && !canAcceptDennyAppt && (
                                        <>
                                            <button
                                                type="button"
                                                className="btn btn-danger mr-auto bg-red-600 text-white sm:bg-white sm:text-red-600"
                                                onClick={() => setIsConfirmModalOpen(true)}
                                            >
                                                <XIcon className="w-5 h-5 sm:hidden"/>

                                                <span className="hidden sm:inline">Cancel Appointment</span>
                                            </button>
                                        </>
                                    )}
                                </>
                            </React.Fragment>
                        )
                    } : null}
                    htmlAfter={(
                        <>
                        <ModalConfirm
                            title={translate("modal_title.confirm_cancel_apt")}
                            text={translate("text.cancel_apt")}
                            onClose={() => setIsConfirmModalOpen(false)}
                            onConfirm={() => {
                                dispatch(deleteResource({
                                    user: LocalStorage.get("user"),
                                    query: Object.assign({
                                        ['ScheduleItemID']: selectedItem['ScheduleItemID']
                                    }, getQuery()),
                                    resource: Resources.ScheduleItem,
                                    piggyResource: Resources.ScheduleItem
                                }));
                                setIsConfirmModalOpen(false);
                                setIsEditModalOpen(false);
                                setIsMoreDialogVisible(false);
                            }}
                            visible={isConfirmModalOpen}
                        />

                            <ModalSaveResource
                                title={'Appointment Date/Time'}
                                widthClass="max-w-4xl"
                                visible={appointmentTimeModalOpen}
                                onClose={handleToggleAptTimeModal}
                                disableOverflow={true}
                                fields={{
                                    AptStartDateTime: new Field("AptStartDateTime", selectedItem?.DateTime, ['empty'], false, 'datetime', {}, {
                                        minDate: selectedItem?.DateTime,
                                        maxDate: moment(selectedItem?.DateTime).add(selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        minTime: selectedItem?.DateTime,
                                        maxTime: moment(selectedItem?.DateTime).add(selectedItem?.Duration - appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                    }),
                                    AptEndDateTime: new Field("AptEndDateTime", moment(selectedItem?.DateTime).add(selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'), ['empty'], false, 'datetime', {}, {
                                        minDate: selectedItem?.DateTime,
                                        maxDate: moment(selectedItem?.DateTime).add(selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        minTime: moment(selectedItem?.DateTime).add(appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                        maxTime: moment(selectedItem?.DateTime).add(selectedItem?.Duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                                    }),
                                    Notes: new Field('Notes', '', [], false, 'textarea')
                                }}
                                onSubmit={(params) => {
                                    if (params) {
                                        dispatch(updateResource({
                                            user: LocalStorage.get("user"),
                                            query: getQuery(),
                                            params: {
                                                ScheduleItemID: selectedItem['ScheduleItemID'],
                                                Notes: params.Notes,
                                                DateTime: params.AptStartDateTime,
                                                Duration: moment(params.AptEndDateTime).diff(moment(params.AptStartDateTime), 'minutes')
                                            },
                                            resource: Resources.ScheduleItemParentUpdate,
                                            piggyResource: Resources.ScheduleItem
                                        }));
                                        handleToggleAptTimeModal()
                                        setIsEditModalOpen(false);
                                        setIsMoreDialogVisible(false);
                                    }
                                }}
                                handleInputChange={(fields, name, value) => {
                                    if (name === "AptStartDateTime") {
                                        fields.AptEndDateTime.props.minTime = moment(value).add(appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                                    }
                                    if (name === "AptEndDateTime") {
                                        fields.AptStartDateTime.props.maxTime = moment(value).add(-appointmentDateTimeDurationDifference, 'minutes').format('YYYY-MM-DD HH:mm:ss')
                                    }

                                    return FieldsManager.updateField(fields, name, value);
                                }}
                                translate={translate}
                                submitButtonName={"Submit"}
                            />
                        </>
                    )}
                />

                <ModalSaveResource
                    title={translate(props.isParent ? "modal_title.appointment" : "modal_title.request_appointment")}
                    disableOverflow={true}
                    widthClass="max-w-4xl"
                    visible={isCreateModalOpen}
                    onClose={setIsCreateModalOpen}
                    fields={getFields({DateTime: activeDay})}
                    handleInputChange={(fields, name, value) => {
                        if (name === "ClientID") {
                            fields.MemberID.props.setKey = "MemberID" + value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                    onSubmit={(params) => {
                        if (params) {
                            dispatch(createResource({
                                user: LocalStorage.get("user"),
                                query: getQuery(),
                                params: params,
                                resource: Resources.ScheduleItem,
                                piggyResource: Resources.ScheduleItem
                            }));
                            setIsCreateModalOpen(false);
                            setIsMoreDialogVisible(false);
                        }
                    }}
                    translate={translate}
                    metadata={selects}
                />
            </DialogDefault>
        </React.Fragment>
    )
}
