export function getPublicResource(data) {
    return {
        type: 'GET_PUBLIC_RESOURCE',
        data: data
    };
}

export function doneGetPublicResource(data) {
    return {
        type: 'DONE_GET_PUBLIC_RESOURCE',
        data: data
    };
}

export function errorGetPublicResource(data) {
    return {
        type: 'ERROR_GET_PUBLIC_RESOURCE',
        data: data
    };
}

export function resetPublicResource() {
    return {
        type: 'RESET_PUBLIC_RESOURCE',
        data: []
    }
}

export function updatePublicResource(data) {
    return {
        type: 'UPDATE_PUBLIC_RESOURCE',
        data: data
    };
}

export function doneUpdatePublicResource(data) {
    return {
        type: 'DONE_UPDATE_PUBLIC_RESOURCE',
        data: data
    };
}

export function errorUpdatePublicResource(data) {
    return {
        type: 'ERROR_UPDATE_PUBLIC_RESOURCE',
        data: data
    };
}

export function createPublicResource(data) {
    return {
        type: 'CREATE_PUBLIC_RESOURCE',
        data: data
    };
}

export function doneCreatePublicResource(data) {
    return {
        type: 'DONE_CREATE_PUBLIC_RESOURCE',
        data: data
    };
}

export function errorCreatePublicResource(data) {
    return {
        type: 'ERROR_CREATE_PUBLIC_RESOURCE',
        data: data
    };
}
