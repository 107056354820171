const uiReducer = (state = {}, action) => {
    switch (action.type) {
        case 'HIDE_MODALS':
            return Object.assign({}, state, {});
        case 'PUSH_NOTIFICATION':
            const defaultMessageDuration = 3;
            let newMessageCount = state.messagesCount ? state.messagesCount + 1 : 1;
            let messageDuration = action.data.data.messageDuration === 0 || action.data.data.messageDuration ? action.data.data.messageDuration : defaultMessageDuration;
            let newMessagesArray = state && state.messages ? state.messages : [];

            const color = action.data.data.color ? action.data.data.color : "primary";

            newMessagesArray.push({
                id: newMessageCount,
                title: action.data.data?.notificationTitle,
                message: action.data.data.notificationMessage ? action.data.data.notificationMessage : action.data.type,
                duration: messageDuration,
                isError: action.data.data.error,
                color: color
            })

            return Object.assign({}, state, {
                messagesCount: newMessageCount,
                messageDuration: messageDuration,
                messages: newMessagesArray,
                unreadCount: action.data.data.unreadCount
            })
        case 'HIDE_NOTIFICATION':
            if (!state.messages) {
                return state;
            }

            let updatedNotifications = state.messages.filter(notification => notification.id != action.data);

            return Object.assign({}, state, {
                messages: updatedNotifications,
            });
        default:
            return state;
    }
};

export default uiReducer;