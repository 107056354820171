export function getConversations(data) {
    return {
        type: 'GET_CONVERSATIONS',
        data: data
    };
}

export function doneGetConversations(data) {
    return {
        type: 'DONE_GET_CONVERSATIONS',
        data: data
    };
}

export function errorGetConversations(data) {
    return {
        type: 'ERROR_GET_CONVERSATIONS',
        data: data
    };
}

export function getMessages(data) {
    return {
        type: 'GET_MESSAGES',
        data: data
    };
}

export function doneGetMessages(data) {
    return {
        type: 'DONE_GET_MESSAGES',
        data: data
    };
}

export function errorGetMessages(data) {
    return {
        type: 'ERROR_GET_MESSAGES',
        data: data
    };
}

export function getContacts(data) {
    return {
        type: 'GET_CONTACTS',
        data: data
    };
}

export function doneGetContacts(data) {
    return {
        type: 'DONE_GET_CONTACTS',
        data: data
    };
}

export function errorGetContacts(data) {
    return {
        type: 'ERROR_GET_CONTACTS',
        data: data
    };
}

export function showChatPanel(data) {
    return {
        type: 'SHOW_CHAT_PANEL',
        data: data
    };
}

export function hideChatPanel(data) {
    return {
        type: 'HIDE_CHAT_PANEL',
        data: data
    };
}

export function getSMSMessages(data) {
    return {
        type: 'GET_SMS_MESSAGES',
        data: data
    };
}

export function doneGetSMSMessages(data) {
    return {
        type: 'DONE_GET_SMS_MESSAGES',
        data: data
    };
}

export function errorGetSMSMessages(data) {
    return {
        type: 'ERROR_GET_SMS_MESSAGES',
        data: data
    };
}