import React from "react";

const PageHeading = (props) => {
    const addTitleClass = props.titleClass ? " " + props.titleClass : "";
    return (
        <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
                <h2 className={"text-2xl font-bold leading-7 text-gray-900 sm:text-3xl" + addTitleClass}>{props.title}</h2>
            </div>

            {!!props.children && (
                <div className="mt-4 flex md:mt-0 md:ml-4 flex-wrap">
                    {props.children}
                </div>
            )}
        </div>
    )
}

export default PageHeading;