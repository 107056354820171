const publicReducer = (state = {}, action) => {
    switch (action.type) {
        case 'RESET_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                create: null,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'GET_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null,
                showRedirectDialog: false
            });
        case 'DONE_GET_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'UPDATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_UPDATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                update: true,
                isLoading: false,
                error: false,
                errorMessage: null,
                updated: action.data?.id
            });
        case 'ERROR_UPDATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                update: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CREATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_CREATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                create: action.data,
                isLoading: false,
                error: false,
                errorMessage: null,
                showRedirectDialog: action.data.showRedirectDialog
            });
        case 'ERROR_CREATE_PUBLIC_RESOURCE':
            return Object.assign({}, state, {
                create: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        default:
            return state;
    }
}

export default publicReducer;
