import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import Button from "../../components/button";
import PageHeading from "../../components/page-heading";
import {checkPerm, CREATE_PERM, DELETE_PERM, fillFieldsFromData, getProp, UPDATE_PERM} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import UploadDocumentDialog from "./upload-document-dialog";
import {connect} from "react-redux";
import {deleteResource, downloadFile, getResource, updateResource, uploadDocument} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import ModalConfirm from "../../components/modal/modal-confirm";
import ModalViewDocument from "../../components/modal/modal-view-document";
import ModalVideoPlayer from "../../components/modal/modal-video-player";
import Env from "../../util/env";
import axios from "axios";
import {processResponse} from "../../data/services/api-util";
import FieldDropdownSelect from "../../components/field-dropdown-select";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ArchivedSwitch from "../../components/archived-switch";

class Documentation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            fieldCategory: {
                DocumentationCategoryID: new Field("DocumentationCategoryID", '', []),
            },

            filterFields: this.getFilterFields(),
            limit: 10,
            offset: 0,
            sort: "ASC",
            sortBy: "DocumentationName",
            query: "",

            selectedItem: null,
            isFormModalVisible: false,
            confirmModalOpen: false,
            viewModalOpen: false,
            viewVideoModalOpen: false,
            selectedDocument: null,
            updateDocumentModalOpen: false,
            paginationPage: 1
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({filterFields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    getFilterFields = (item = null) => {
        const fieldTemplates = {
            Query: new Field("Query", '', []),
            Date: new Field("Date", '', [])
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleFormModal = (item = this.state.selectedDocument) => {
        this.setState({
            isFormModalVisible: !this.state.isFormModalVisible,
            selectedDocument: item
        })
    }

    handleCategoryChange = (name, value) => {
        const fieldCategory = FieldsManager.updateField(this.state.fieldCategory, name, value)

        this.setState({fieldCategory: fieldCategory, offset: 0, paginationPage: 1}, this.fetchData);
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    toggleDocumentEditModal = (item = this.state.selectedDocument) => {
        this.setState({
            selectedDocument: item,
            updateDocumentModalOpen: !this.state.updateDocumentModalOpen
        })
    }

    handleViewModal = (item = null) => {
        if (item && (item.OriginalFileName.includes("mp4") || item.OriginalFileName.includes("webm"))) {
            this.handleViewVideoModal(item);
        } else {
            this.setState({
                selectedItem: item,
                viewModalOpen: !this.state.viewModalOpen
            });
        }
    }

    handleViewVideoModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewVideoModalOpen: !this.state.viewVideoModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "DocumentationID";
    }

    getResourceName = () => {
        return Resources.Documentation;
    }

    getQuery = () => {
        return {
            id: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            DocumentationCategoryID: this.state.fieldCategory.DocumentationCategoryID?.value?.value,
            IsPublic: checkPerm(Resources.Documentation, CREATE_PERM) ? 0 : 1
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            DocumentationName: new Field("DocumentationName", '', []),
            CreateUpdateDate: new Field("CreateUpdateDate", '', [], false, 'datetime'),
            DocumentationCategory: new Field("DocumentationCategory", '', [], false, {
                hideDialog: true
            }),
            Description: new Field("Description", '', [], false, "textarea"),
            IsPublic: new Field("IsPublic", '', [], false, 'checkbox', {
                hideTable: !checkPerm(Resources.Documentation, CREATE_PERM)
            })
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                        <PageHeading
                            title="Resources"
                        >
                            {checkPerm(this.getResourceName(), DELETE_PERM) && (
                                <ArchivedSwitch
                                    classNameContainer="h-9 mr-2 mt-1"
                                    translate={translate}
                                    value={this.state.archived}
                                    onChange={(val) => this.setState({archived: val}, this.fetchData)}
                                />
                            )}
                            {checkPerm(Resources.Documentation, CREATE_PERM) && (
                                <Button
                                    type="primary"
                                    onClick={this.handleToggleFormModal}
                                >
                                    Add Document
                                </Button>
                            )}
                        </PageHeading>

                        <div className="mt-6 flex flex-wrap">
                            <div className=" flex-auto lg:flex-1 md:w-1/3 w-1/2">
                                <div className="mr-6">
                                    <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                        Document Category
                                    </label>

                                    <FieldDropdownSelect
                                        {...this.state.fieldCategory.DocumentationCategoryID}
                                        onChange={this.handleCategoryChange}
                                        isClearable={this.state.fieldCategory.DocumentationCategoryID.value}
                                        name={"DocumentationCategoryID"}
                                        className="select-search h-9 w-56 mb-8"
                                        defaultOptions={true}
                                        loadOptions={
                                            (inputValue, callback) => {
                                                axios.get(
                                                    Env.getApiUrl("/api/" + Resources.DocumentationCategories, {query: inputValue}),
                                                    {
                                                        headers: {
                                                            'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                                        }
                                                    }
                                                )
                                                    .then((response) => {
                                                        const result = processResponse(response);
                                                        if (result && result.status === 0) {
                                                            const list = result.data.list.map((it) => {
                                                                return {
                                                                    value: it.DocumentationCategoryID,
                                                                    label: it.DocumentationCategory
                                                                };
                                                            });
                                                            callback(list);
                                                        }
                                                    })
                                                    .catch((error) => {
                                                    });
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleViewModal}
                            onEdit={checkPerm(Resources.Documentation, UPDATE_PERM) ? (item) => this.toggleDocumentEditModal(item) : null}
                            onDelete={checkPerm(Resources.Documentation, DELETE_PERM) ? this.handleToggleConfirmModal : null}
                        />
                    </div>
                </LayoutDashboard>

                <ModalVideoPlayer
                    title={"View video"}
                    className="max-w-2xl"
                    visible={this.state.viewVideoModalOpen}
                    onClose={this.handleViewVideoModal}
                    translate={this.props.translate}
                    filePath={Env.getApiUrl('api/' + this.getResourceName(), {
                        [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                        id: this.getId(),
                        token: LocalStorage.get('user').access_token,
                        video: 1
                    })}
                    bookmarks={[]}
                />

                <ModalViewDocument
                    title={"View Document"}
                    className="max-w-2xl"
                    visible={this.state.viewModalOpen}
                    onClose={this.handleViewModal}
                    fields={this.getFields()}
                    translate={this.props.translate}
                    fileType={getProp(this.state, "selectedItem.OriginalFileName", "") ? getProp(this.state, "selectedItem.OriginalFileName", "").split(".").pop() : ""}
                    filePath={Env.getApiUrl('api/' + this.getResourceName(), {
                        [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                        id: this.getId(),
                        token: LocalStorage.get('user').access_token
                    })}
                    onDownload={() => {
                        this.props.dispatch(downloadFile({
                            user: LocalStorage.get('user'),
                            query: {
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                name: this.state.selectedItem.OriginalFileName
                            },
                            resource: this.getResourceName()
                        }))
                    }}
                />

                <UploadDocumentDialog
                    title={"Create document"}
                    widthClass="max-w-lg"
                    visible={this.state.isFormModalVisible}
                    onClose={this.handleToggleFormModal}
                    fields={this.getFields()}
                    fieldCategories={this.state.fieldCategories}
                    blurBackdrop={true}
                    translate={this.props.translate}
                    onSubmit={(files, params, descriptions, names, type, categories, isPublic) => {
                        params.id = this.props.id;

                        params.DocumentationCategoryID = params.DocumentationCategoryID?.value;

                        files.forEach((file, i) => {
                            if (!names[i]) names[i] = file.name;
                        });

                        this.props.dispatch(uploadDocument({
                            user: LocalStorage.get('user'),
                            piggyQuery: this.getQuery(),
                            params: params,
                            files: files,
                            descriptions: descriptions,
                            IsPublic: isPublic,
                            names: names,
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleFormModal();
                    }}
                    mediaTypesText={this.props.mediaTypesText}
                />

                <ModalSaveResource
                    title={"Update document"}
                    widthClass="max-w-md"
                    visible={this.state.updateDocumentModalOpen}
                    onClose={this.toggleDocumentEditModal}
                    fields={this.getFields(this.state.selectedDocument, ["CreateUpdateDate", "DocumentationCategory"])}
                    onSubmit={(params) => {
                        if (params) {
                            params.DocumentationID = this.state.selectedDocument.DocumentationID;

                            this.props.dispatch(updateResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.toggleDocumentEditModal(false)
                        }
                    }}
                    handleInputChange={(fields, name, value) => {
                        return FieldsManager.updateField(fields, name, value);
                    }}
                    translate={this.props.translate}
                    disableOverflow
                />

                <ModalConfirm
                    visible={this.state.confirmModalOpen}
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            piggyQuery: this.getQuery(),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                />
            </React.Fragment>
        )
    }

}

export default connect(state => state)(Documentation);
