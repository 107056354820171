import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import LocalStorage from "./util/localStorage";
import CheckInView from "./views/check-in";
import ReportsView from "./views/reports";
import OrgChartView from "./views/org-chart";
import DocumentationView from "./views/documentation";
import GroupsView from "./views/groups";
import CommunicationView from "./views/communication";
import NotFoundView from "./views/not-found";
import CalendarView from "./views/calendar";
import SettingsView from "./views/settings-page"
import SocketListener from "./package/chat/socket-listener";
import UsersPage from "./views/users-page";
import LoggedDevicesPage from "./views/logged-devices-page";
import ScheduleLocationsPage from "./views/schedule-locations";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import CategoriesPage from "./views/categories";
import ProfileClientPage from "./views/profile-client";
import ProfileMemberPage from "./views/profile-member";
import EventInviteView from "./views/event-invite-view";
import ScheduleLogsPage from "./views/schedule-logs";


const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/dashboard",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);


class RouterListener extends Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {

        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>
                    <PublicRoute exact path="/event-invite/:token"
                                 component={(props) => getComponent(EventInviteView, props)}/>

                    <PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPassword, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                    <PrivateRoute exact path="/" component={(props) => getComponent(CalendarView, props)}/>
                    <PrivateRoute exact path="/dashboard" component={(props) => getComponent(CalendarView, props)}/>
                    <PrivateRoute exact path="/communication"
                                  component={(props) => getComponent(CommunicationView, props)}/>
                    <PrivateRoute exact path="/calendar" component={(props) => getComponent(CalendarView, props)}/>
                    <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsView, props)}/>
                    <PrivateRoute exact path="/groups" component={(props) => getComponent(GroupsView, props)}/>
                    <PrivateRoute exact path="/resources"
                                  component={(props) => getComponent(DocumentationView, props)}/>
                    <PrivateRoute exact path="/org-chart" component={(props) => getComponent(OrgChartView, props)}/>
                    <PrivateRoute exact path="/checkin" component={(props) => getComponent(CheckInView, props)}/>
                    <PrivateRoute exact path="/locations"
                                  component={(props) => getComponent(ScheduleLocationsPage, props)}/>
                    <PrivateRoute exact path="/schedule-logs"
                                  component={(props) => getComponent(ScheduleLogsPage, props)}/>
                    <PrivateRoute exact path="/reports" component={(props) => getComponent(ReportsView, props)}/>

                    <PrivateRoute exact path="/users" component={(props) => getComponent(UsersPage, props)}/>
                    <PrivateRoute exact path="/settings" component={(props) => getComponent(SettingsView, props)}/>
                    <PrivateRoute exact path="/categories" component={(props) => getComponent(CategoriesPage, props)}/>
                    <PrivateRoute exact path="/devices" component={(props) => getComponent(LoggedDevicesPage, props)}/>

                    <PrivateRoute exact path="/member-profile"
                                  component={(props) => getComponent(ProfileMemberPage, props)}/>
                    <PrivateRoute exact path="/client-profile"
                                  component={(props) => getComponent(ProfileClientPage, props)}/>

                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>

                <SocketListener
                    translate={(key, args) => translator.translate(key, args)}
                />
            </RouterListener>
        </Router>
    );
}
