import {watchLoginCall, watchRegisterCall, watchResetPasswordCall, watchResetPasswordConfirmCall} from "./userSaga";
import {all} from "redux-saga/effects";
import {
    watchCreateResource,
    watchCreateResourceWithImage,
    watchCreateSecondResource,
    watchDeleteResource,
    watchDeleteSecondResource,
    watchDownloadDocument,
    watchGetAuthResource,
    watchGetResource,
    watchGetResourceDetails,
    watchGetSecondResource,
    watchUpdateResource,
    watchUpdateSecondResource,
    watchUploadDocument
} from "./resourceSaga";
import {
    watchCreateDialogResource,
    watchCreateDialogResourceWithImage,
    watchDeleteDialogResource,
    watchGetDialogResource,
    watchGetDialogResourceDetails,
    watchUpdateDialogResource,
    watchUploadDialogDocument
} from "./dialogResourceSaga";
import {watchPushNotification} from "./ui";
import {watchGetContacts, watchGetConversations, watchGetMessages} from "../../package/chat/sagas/messagingSaga";
import {watchCreateClientResource, watchUpdateClientResource} from "./customResourceSaga";
import {watchCreatePublicResource, watchGetPublicResource, watchUpdatePublicResource} from "./publicSaga";

/*
 * Single entry point to start all Sagas at once
 =========================================================================================*/
export default function* rootSaga() {
    yield all([
        // Login/Register
        watchLoginCall(),
        watchRegisterCall(),
        watchResetPasswordCall(),
        watchResetPasswordConfirmCall(),

        // Resource
        watchGetResource(),
        watchGetSecondResource(),
        watchGetResourceDetails(),
        watchCreateResource(),
        watchCreateSecondResource(),
        watchUpdateResource(),
        watchUpdateSecondResource(),
        watchDeleteResource(),
        watchDeleteSecondResource(),

        watchGetPublicResource(),
        watchUpdatePublicResource(),
        watchCreatePublicResource(),

        watchUploadDocument(),
        watchDownloadDocument(),

        watchGetAuthResource(),
        watchCreateResourceWithImage(),
        watchCreateDialogResourceWithImage(),

        // Client specific
        watchCreateClientResource(),
        watchUpdateClientResource(),

        // Resource dialog
        watchGetDialogResource(),
        watchGetDialogResourceDetails(),
        watchCreateDialogResource(),
        watchUpdateDialogResource(),
        watchDeleteDialogResource(),
        watchUploadDialogDocument(),

        // Notifications
        watchPushNotification(),

        // Messages
        watchGetMessages(),
        watchGetConversations(),
        watchGetContacts(),
    ]);
}
