import {SearchIcon} from "@heroicons/react/solid";
import React from "react";

export default function FieldSearch(props) {
    return (
        <div className={(props.classNameContainer ?? "")}>
            <label htmlFor={props.name} className="sr-only">
                Search
            </label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                </div>
                <input
                    type="search"
                    onKeyDown={(e) => props.handleKeyDown ? props.handleKeyDown(e) : null}
                    onChange={(event) => {
                        props.onChange(props.name, event.target.value);
                    }}
                    name={props.name}
                    value={props.value}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    className={"focus:ring-primary-500 focus:border-primary-500 block pl-10 text-sm border-gray-300 rounded-md " + (props.className ?? "")}
                />
            </div>
        </div>
    );
}