import React, {Component} from 'react'
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import HorizontalTabs from "../../components/horizontal-tabs";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import PageHeading from "../../components/page-heading";
import ResourceTabForm from "../../components/resource-tab-form";
import Env from "../../util/env";
import LocalStorage from "../../util/localStorage";
import {fillFieldsFromData, getProp} from "../../util/util";
import {createResourceWithImage, deleteResource, getResource} from "../../data/actions/resource";
import ClientAuthorizedPickups from "./client-authorized-pickups";
import ModalConfirm from "../../components/modal/modal-confirm";
import SimpleTable from "../../components/simple-table";
import ClientChild from "./client-child";


class ProfileClient extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {name: props.translate('tab.info'), resource: Resources.ClientProfileInfo, current: true},
                {
                    name: props.translate('tab.care_team'),
                    resource: Resources.ClientProfileCareTeam,
                    current: false
                },
                {
                    name: props.translate('tab.children'),
                    resource: Resources.ClientProfileChild,
                    current: false
                },
                {
                    name: props.translate('tab.authorized_pickup'),
                    resource: Resources.ClientProfileAuthPickup,
                    current: false
                }
            ],
            selectedTab: Resources.ClientProfileInfo,
            selectedItem: null,
            authorizedPickupData: [],
            authorizedPickupImages: {},
            childData: [],
            childImages: {},
            removeImage: null,
            updateImage: null,
            handleToggleConfirmModal: null,
            selectedConfirmItem: false,
            confirmModalOpen: false,
            confirmChildModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedTab !== prevState.selectedTab) {
            this.fetchData();
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading && this.state.selectedTab === Resources.ClientProfileAuthPickup) {
            this.setState({
                authorizedPickupData: getProp(this.props.resource, "data." + this.state.selectedTab, []),
            })
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading && this.state.selectedTab === Resources.ClientProfileChild) {
            this.setState({
                childData: getProp(this.props.resource, "data." + this.state.selectedTab, []),
            })
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading && this.state.selectedTab === Resources.ClientProfileInfo) {
            this.setState({
                selectedItem: getProp(this.props.resource, "data." + this.state.selectedTab, []),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: "",
            resource: this.state.selectedTab
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleInputChange = (fields, name, value, selectedItem = null) => {
        if ("ImagePath" === name && this.state.selectedTab === Resources.ClientProfileInfo) {
            this.setState({
                removeImage: null,
                updateImage: 1,
            })
        }

        if ("ImagePath" === name && this.state.selectedTab === Resources.ClientProfileAuthPickup) {
            let authorizedPickupImages = Object.assign({}, this.state.authorizedPickupImages);

            authorizedPickupImages[selectedItem?.ClientPickupID || -1] = {
                removeImage: null,
                updateImage: 1,
            };

            this.setState({authorizedPickupImages});
        }

        if ("ImagePath" === name && this.state.selectedTab === Resources.ClientProfileChild) {
            let childImages = Object.assign({}, this.state.childImages);

            childImages[selectedItem?.ClientChildID || -1] = {
                removeImage: null,
                updateImage: 1,
            };

            this.setState({childImages});
        }

        return FieldsManager.updateField(fields, name, value);
    };

    /** Helpers
     ================================================================= */
    getAuthorizedPickupFields = (item = null) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'hidden', {hideTable: true}),
            ClientPickupID: new Field("ClientPickupID", '', [''], false, 'hidden', {hideTable: true}),
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Relation: new Field("Relation", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientPickupImage, {
                        v: it.CreateUpdateDate,
                        id: it.ClientPickupID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                removePhoto: this.removePickupPhoto,
                id: item?.ClientPickupID,
            })
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getClientChildFields = (item = null) => {
        const fieldTemplates = {
            ClientID: new Field("ClientID", '', [''], false, 'hidden', {hideTable: true}),
            ClientChildID: new Field("ClientChildID", '', [''], false, 'hidden', {hideTable: true}),
            ChildName: new Field("ChildName", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientChildImage, {
                        v: it.CreateUpdateDate,
                        id: it.ClientChildID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                removePhoto: this.removeChildPhoto,
                id: item?.ClientChildID,
            })
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getClientFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', ['email'], false, !!item ? 'readonly' : 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            AlternatePhone: new Field("AlternatePhone", '', [], false, 'text'),
            /*ChildName: new Field("ChildName", '', ['empty'], false, 'text'),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath

                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientImage, {
                        v: item.CreateUpdateDate,
                        id: item?.ClientID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                removePhoto: this.removeClientPhoto,
                id: item?.ClientID,
            }),*/
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0
        }
    }

    removeClientPhoto = () => {
        let selectedItem = Object.assign({}, this.state.selectedItem, {
            ImagePath: "",
        });

        this.setState({
            selectedItem: selectedItem,
            removeImage: 1,
            updateImage: null,
            imageFile: null
        })
    };

    removePickupPhoto = (item) => {
        let authorizedPickupData = [...this.state.authorizedPickupData];

        authorizedPickupData.forEach((it, i) => {
            if (it.ClientPickupID === item?.metadata?.id) {
                let update = Object.assign(it, {
                    ImagePath: "",
                    imageFile: null
                })
                authorizedPickupData[i] = update;
            }
        });

        let authorizedPickupImages = Object.assign({}, this.state.authorizedPickupImages)

        authorizedPickupImages[item?.metadata?.id] = {
            removeImage: 1,
            updateImage: "",
        };

        this.setState({authorizedPickupImages, authorizedPickupData});
    };

    removeChildPhoto = (item) => {
        let childData = [...this.state.childData];

        childData.forEach((it, i) => {
            if (it.ClientChildID === item?.metadata?.id) {
                let update = Object.assign(it, {
                    ImagePath: "",
                    imageFile: null
                });
                childData[i] = update;
            }
        });

        let childImages = Object.assign({}, this.state.childImages);

        childImages[item?.metadata?.id] = {
            removeImage: 1,
            updateImage: "",
        };

        this.setState({childImages, childData});
    };

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedConfirmItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    };

    handleToggleConfirmChildModal = (item = null) => {
        this.setState({
            selectedConfirmChildItem: item,
            confirmChildModalOpen: !this.state.confirmChildModalOpen
        })
    };


    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const isLoading = getProp(resource, "isLoading", null);

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">

                        <PageHeading
                            title={translate("page.title.profile")}
                        />

                        <HorizontalTabs
                            tabs={this.state.tabs}
                            handleTabChange={this.handleTabChange}
                        />

                        {Resources.ClientProfileInfo === this.state.selectedTab && (
                            <ResourceTabForm
                                title={translate("tab.edit_profile")}
                                fields={this.getClientFields(this.state.selectedItem)}
                                selectedItem={this.state.selectedItem}
                                translate={this.props.translate}
                                resource={this.props.resource}
                                isLoading={isLoading}
                                handleInputChange={this.handleInputChange}
                                onSubmit={(params) => {
                                    if (params) {
                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(params, {
                                                removeImage: this.state.removeImage,
                                                updateImage: this.state.updateImage,
                                            }),
                                            image: params.ImagePath,
                                            resource: this.state.selectedTab,
                                            piggyResource: this.state.selectedTab,
                                            notificationMessage: translate("message.profile_updated")
                                        }));
                                    }
                                }}
                            />
                        )}

                        {Resources.ClientProfileCareTeam === this.state.selectedTab && (
                            <div>
                                <SimpleTable
                                    data={getProp(this.props.resource, "data.client-profile/care-team", [])}

                                    fields={{
                                        CareTeam: new Field("CareTeam", '', [], false, 'text'),
                                        Email: new Field("Email", '', [], false, 'text'),
                                        Phone: new Field("Phone", '', [], false, 'text'),
                                        CareTeamRoleID: new Field("CareTeamRoleID", '', [], false, 'select'),
                                        ClientChildID: new Field("ClientChildID", '', [], false, 'select'),
                                        JoinedOnDate: new Field("JoinedOnDate", '', ['empty'], false, 'date')
                                    }}
                                    translate={this.props.translate}
                                    isLoading={isLoading}
                                />
                            </div>
                        )}

                        {Resources.ClientProfileAuthPickup === this.state.selectedTab && (
                            <React.Fragment>
                                <ClientAuthorizedPickups
                                    getFields={this.getAuthorizedPickupFields}
                                    data={this.state.authorizedPickupData}
                                    translate={translate}
                                    isLoading={isLoading}
                                    handleInputChange={this.handleInputChange}
                                    onAdd={(it) => {
                                        delete it.ClientID;
                                        delete it.ClientPickupID;

                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(it, this.state.authorizedPickupImages[-1]),
                                            image: it.ImagePath,
                                            resource: Resources.ClientProfileAuthPickupCreate,
                                            piggyResource: Resources.ClientProfileAuthPickup
                                        }));
                                    }}
                                    onEdit={(it, selectedItem) => {
                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(it, this.state.authorizedPickupImages[selectedItem.ClientPickupID]),
                                            image: it.ImagePath,
                                            resource: Resources.ClientProfileAuthPickupUpdate,
                                            piggyResource: Resources.ClientProfileAuthPickup
                                        }));
                                    }}
                                    onDelete={(it) => {
                                        this.handleToggleConfirmModal(it)
                                    }}
                                />
                            </React.Fragment>
                        )}

                        {Resources.ClientProfileChild === this.state.selectedTab && (
                            <React.Fragment>
                                <ClientChild
                                    getFields={this.getClientChildFields}
                                    data={this.state.childData}
                                    translate={translate}
                                    isLoading={isLoading}
                                    handleInputChange={this.handleInputChange}
                                    onAdd={(it) => {
                                        delete it.ClientID;
                                        delete it.ClientChildID;

                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(it, this.state.childImages[-1]),
                                            image: it.ImagePath,
                                            resource: Resources.ClientProfileChildCreate,
                                            piggyResource: Resources.ClientProfileChild
                                        }));
                                    }}
                                    onEdit={(it, selectedItem) => {
                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(it, this.state.childImages[selectedItem.ClientChildID]),
                                            image: it.ImagePath,
                                            resource: Resources.ClientProfileChildUpdate,
                                            piggyResource: Resources.ClientProfileChild
                                        }));
                                    }}
                                    onDelete={(it) => {
                                        this.handleToggleConfirmChildModal(it)
                                    }}
                                />
                            </React.Fragment>
                        )}
                    </div>

                    <ModalConfirm
                        title={translate("modal_title.confirm_remove")}
                        text={translate("text.delete_authorize_pickup")}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    id: this.state.selectedConfirmItem.ClientPickupID
                                }, this.getQuery()),
                                resource: Resources.ClientProfileAuthPickup,
                                piggyResource: Resources.ClientProfileAuthPickup
                            }));
                            this.handleToggleConfirmModal(false);
                        }}
                        visible={this.state.confirmModalOpen}
                    />

                    <ModalConfirm
                        title={translate("modal_title.confirm_remove")}
                        text={translate("text.delete_child")}
                        onClose={this.handleToggleConfirmChildModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    id: this.state.selectedConfirmChildItem.ClientChildID
                                }, this.getQuery()),
                                resource: Resources.ClientProfileChild,
                                piggyResource: Resources.ClientProfileChild
                            }));
                            this.handleToggleConfirmChildModal(false);
                        }}
                        visible={this.state.confirmChildModalOpen}
                    />

                </LayoutDashboard>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(ProfileClient);
