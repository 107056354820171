import React from 'react'
import {fieldsToCells} from "../../util/util";

const TableMobile = (props) => {
    const {data, fields, translate, isLoading} = props;

    const tables = data.map((it) => {
        const smallTable = Object.keys(fields).map((fieldName, i) => {
            return (
                <tr className="">
                    <td
                        className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-t border-r font-bold w-32" + (!i ? " border-t-0" : "")}
                    >
                        {translate("table." + fieldName)}
                    </td>
                    {fieldsToCells({[fieldName]: fields[fieldName]}, it, "border-secondary-200 hover:bg-gray-100 p-3 bg-inverse" + (i ? " border-t" : ""))}
                </tr>
            )
        });
        {
            props.haveActions && (
                smallTable.push(
                    <tr className="bg-inverse border-t">
                        <td
                            className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-t border-r font-bold w-32 border-b-0"}
                        >
                            Actions
                        </td>
                        {props.getActions(it)}
                    </tr>
                )
            )
        }

        return (
            <div className="overflow-hidden rounded-lg border mb-4">
                <table className="sm:bg-white shadow-lg w-full">
                    {smallTable}
                </table>
            </div>
        )
    });


    const generateRandomSkeletonWidth = () => {
        let classes = ['w-6/12', 'w-7/12', 'w-8/12', 'w-9/12', 'w-10/12', 'w-11/12', 'w-full']
        return classes[Math.floor(Math.random() * classes.length)];
    }

    const renderSkeletonTable = (n = 4) => {

        const rows = [];

        for (let i = 1; i <= n; i++) {
            rows.push(
                <tr className="">
                    <td
                        className={"border-secondary-200 bg-secondary-50 p-3 text-left text-secondary-900 border-r font-bold w-32 h-12" + (i !== 1 ? " border-t" : "")}
                    >
                        <div className={"h-4 bg-secondary-400 rounded " + (generateRandomSkeletonWidth())}/>
                    </td>

                    <td className={"border-secondary-200 hover:bg-gray-100 p-3 bg-inverse" + (i !== 1 ? " border-t" : "")}>
                        <div className={"h-4 bg-secondary-200 rounded " + (generateRandomSkeletonWidth())}/>
                    </td>
                </tr>
            )
        }

        return (
            <div className="overflow-hidden rounded-lg border my-6">
                <table className="text-sm table-mobile overflow-x-auto w-full animate-pulse rounded-lg overflow-hidden">
                    {rows}
                </table>
            </div>
        );
    }

    return (
        <React.Fragment>
            {!isLoading && (
                <div className="text-sm table-mobile overflow-x-auto">
                    {tables}
                </div>
            )}

            {!!isLoading && (
                renderSkeletonTable()
            )}

        </React.Fragment>
    );
}

export default TableMobile;
