import React, {Component} from "react";
import {fieldsToHtml, fillFieldsFromData, getProp, scrollErrorIntoView,} from "../../../util/util";
import {Field, FieldsManager} from "../../../data/services/fields";
import DialogDefault from "../../../components/dialog-default";
import SimpleTable from "../../../components/simple-table";

export default class EditDepartment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields,
        };

        this.cancelButtonRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields
            })
        }

        if (prevProps.updateFieldValue !== this.props.updateFieldValue) {
            this.setState({
                fields: FieldsManager.updateField(this.state.fields, [this.props.updateFieldName], this.props.updateFieldValue)
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    };

    getTableFields = (item = null) => {
        const fieldTemplates = {
            Member: new Field("Member", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', ['empty'], false, 'text'),
            Phone: new Field("Phone", '', ['empty'], false, 'text'),
            Credentials: new Field("Credentials", '', ['empty'], false, 'text'),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    render() {
        const {onSubmit, onClose, visible, translate, title, metadata, onCustomActions, htmlAfter} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        const staff = getProp(this.props, "item.Staff", []);

        return (
            <DialogDefault
                title={title}
                widthClass={this.props.widthClass}
                visible={visible}
                blurBackdrop={this.props.blurBackdrop}
                onClose={onClose}
                translate={translate}
                disableOverflow={this.props.disableOverflow}
            >
                <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                    {fieldsHtml}

                    {htmlAfter}
                </div>

                <div className="pt-5">

                    <div className="pb-2">
                        <SimpleTable
                            addClassToTableWrapper="sm:h-96 sm:overflow-y-auto"
                            data={staff}
                            count={staff}

                            fields={this.getTableFields()}
                            translate={this.props.translate}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={false}
                            onEdit={false}
                            onDelete={false}
                        />
                    </div>

                    <div className="flex justify-end">

                        {!!onCustomActions && onCustomActions()}

                        <button type="button"
                                className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={(e) => onClose(false)}
                        >
                            {this.props.closeButtonName ? this.props.closeButtonName : translate("btn.close")}
                        </button>

                        <button
                            type="submit"
                            className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                            onClick={() => onSubmit(this.getValidFields())}
                        >
                            {this.props.submitButtonName ? this.props.submitButtonName : translate("btn.save")}
                        </button>
                    </div>
                </div>

            </DialogDefault>

        );
    }
}
