import React, {Component} from "react";
import FieldText from "../../../components/field-text";
import {Field, FieldsManager} from "../../../data/services/fields";
import Dropzone from 'react-dropzone';
import FieldSelect from "../../../components/field-select";
import DialogDefault from "../../../components/dialog-default";
import FieldCheckbox from "../../../components/field-checkbox";
import axios from "axios";
import Env from "../../../util/env";
import Resources from "../../../data/services/resources";
import LocalStorage from "../../../util/localStorage";
import {processResponse} from "../../../data/services/api-util";
import FieldDropdownSelect from "../../../components/field-dropdown-select";
import FieldTextarea from "../../../components/field-textarea";

export default class uploadDocumentDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            files: [],
            Descriptions: {},
            Names: {},
            IsPublic: {},
            DocumentType: {},
            dropzoneActive: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: {DocumentationCategoryID: new Field("DocumentationCategoryID", '', [])},
                files: [],
                Descriptions: {},
                Names: {},
                Public: {},
                DocumentType: {},
                dropzoneActive: false
            })
        }
    }

    handleDescChange = (id, value) => {
        const desc = this.state.Descriptions;
        desc[id] = value;
        this.setState({Descriptions: desc});
    };

    handleNameChange = (id, value) => {
        const names = this.state.Names;
        names[id] = value;
        this.setState({Names: names});
    };

    handleTypeChange = (id, value) => {
        const type = this.state.DocumentType;
        type[id] = value;
        this.setState({DocumentType: type});
    };

    handleIsPublicChange = (id, value) => {
        const IsPublic = this.state.IsPublic;
        IsPublic[id] = value;
        this.setState({IsPublic: IsPublic});
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleSubmit = (event) => {
        event && event.preventDefault();
        if (this.state.files.length === 0) {
            return;
        }

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if ((this.state.files.length > 0) && FieldsManager.checkFieldsForErrors(this.state.fields)) {
                if (this.props.onSubmit) {
                    this.props.onSubmit(this.state.files, FieldsManager.getFieldKeyValues(this.state.fields), this.state.Descriptions, this.state.Names, this.state.DocumentType, this.state.fieldCategories, this.state.IsPublic)
                }
            }
        })
    };

    onDragEnter = () => {
        this.setState({
            dropzoneActive: true
        });
    };

    onDragLeave = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    onDrop = () => {
        this.setState({
            dropzoneActive: false
        });
    };

    removeDocument = (i) => {
        let descriptions = this.state.Descriptions;
        let files = this.state.files;
        let IsPublic = this.state.IsPublic;
        let names = this.state.Names;

        if (descriptions[i]) {
            delete descriptions.i;
        }
        if (names[i]) {
            delete names.i;
        }
        if (files[i]) {
            delete files.i;
            files.length = files.length - 1;
        }

        if (IsPublic[i]) {
            delete IsPublic.i;
        }

        this.setState({
            Descriptions: descriptions,
            files: files,
            IsPublic: IsPublic,
            Names: names
        })
    };

    render() {
        const {onClose, visible, title, blurBackdrop, widthClass, translate} = this.props;

        const files = this.state.files.map((item, i) => {
            return (
                <React.Fragment>
                    <div
                        key={i}
                        className="sm:grid sm:grid-cols-3 sm:gap-4 p-2 bg-gray-100 rounded-md my-4 border border-gray-300">
                        <h2 className="relative col-span-3">
                            Document #{i + 1}

                            <span onClick={() => this.removeDocument(i)}
                                  className="absolute top-0 right-0 text-sm ml-2 text-red-500 hover:cursor-pointer"
                                  title="Remove document">X</span>
                        </h2>


                        {!!this.props.documentTypes && (
                            <React.Fragment>
                                <label htmlFor={i} className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Type
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <FieldSelect
                                        name={i}
                                        className="react-select h-9"
                                        values={this.props.documentTypes}
                                        onChange={this.handleTypeChange}
                                        {...this.state.DocumentType[i]}
                                    />
                                </div>
                            </React.Fragment>
                        )}

                        <div htmlFor={i}
                             className="col-span-3 flex text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 break-all">
                            <div className="w-20">File Name:</div>

                            <div className="font-bold">{item.name}</div>
                        </div>

                        <div className={"col-span-3 flex relative"}>
                            <label className="text-sm font-medium text-gray-700 sm:mt-px w-20">Is Public</label>

                            <FieldCheckbox
                                className="mt-0.5"
                                name={i}
                                value={this.state.IsPublic[i]}
                                onChange={this.handleIsPublicChange}
                            />
                        </div>

                        <div className="col-span-3 mt-1 sm:mt-0 col-span-3 relative">
                            <FieldText
                                name={i}
                                className="form-control"
                                value={this.state.Names[i]}
                                onChange={this.handleNameChange}
                                placeholder={translate("placeholder.document_name")}
                            />
                        </div>

                        <div className="col-span-3 mt-1 sm:mt-0 col-span-3 relative">
                            <FieldTextarea
                                name={i}
                                className="form-control"
                                value={this.state.Descriptions[i]}
                                onChange={this.handleDescChange}
                                placeholder={translate("placeholder.description")}
                            />
                        </div>
                    </div>
                </React.Fragment>
            )
        });

        return (
            <DialogDefault
                title={title}
                widthClass={widthClass}
                visible={visible}
                blurBackdrop={blurBackdrop}
                onClose={onClose}
                translate={translate}
                disableOverflow
            >
                <div>
                    <div>
                        <label htmlFor={"DocumentationCategoryID"}
                               className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Document Category
                        </label>
                        <FieldDropdownSelect
                            {...this.state.fields.DocumentationCategoryID}
                            onChange={this.handleInputChange}
                            name={"DocumentationCategoryID"}
                            className="select-search h-9 w-56 mb-8"
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl("/api/" + Resources.Categories, {query: inputValue}),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return {
                                                        value: it.DocumentationCategoryID,
                                                        label: it.DocumentationCategory
                                                    };
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }
                            }
                        />
                    </div>
                </div>

                <div className="mt-2 relative bg-white">
                    <p className="text-sm font-bold">You can upload one or more files below</p>
                    <Dropzone
                        onDrop={(acceptedFiles) => {
                            const files = this.state.files.concat(acceptedFiles);
                            let Descriptions = this.state.Descriptions;
                            let IsPublic = this.state.IsPublic;
                            let Names = this.state.Names;

                            files.forEach((file, index) => {
                                Descriptions[index] = Descriptions[index] ?? "";
                                IsPublic[index] = IsPublic[index] ?? 0;
                                Names[index] = Names[index] ?? "";
                            });

                            this.setState({
                                files,
                                Descriptions,
                                IsPublic,
                                Names
                            });
                        }}
                        onDragEnter={this.onDragEnter}
                        onDragLeave={this.onDragLeave}
                        onDropAccepted={this.onDrop}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div className="mt-1 sm:mt-0" {...getRootProps()}>
                                <div
                                    className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input {...getInputProps()} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">{this.props.mediaTypesText ? this.props.mediaTypesText : "PDF, DOC, XLSX, PNG, JPG, GIF up to 10MB"}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Dropzone>

                    {files}
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={this.handleSubmit}
                    >
                        Confirm
                    </button>
                    <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </button>
                </div>
            </DialogDefault>
        );
    }
}
