import React, {Component} from "react";
import Async from "react-select/async";
import {getCustomStyles} from "../../styles/theme-styles"

export default class FieldDropdownSelect extends Component {

    render() {
        const pairs = this.props.values ? this.props.values : {};

        return (
            <React.Fragment>
                <div className={this.props.className ?? "display-block w-full col-span-2"}>
                    <Async
                        key={this.props.setKey}
                        autoFocus={this.props.autoFocus}
                        ref={this.props.innerRef}
                        styles={getCustomStyles()}
                        className={"select-search " + (!!this.props.addClass ? this.props.addClass : '')}
                        isDisabled={!!this.props.disabled}
                        onChange={(selectedOption) => {
                            this.props.onChange(this.props.name, selectedOption);
                        }}
                        name={this.props.name}
                        value={!this.props.loadOptions ? (this.props.value ? {
                            value: this.props.value,
                            label: pairs[this.props.value]
                        } : null) : this.props.value}
                        options={this.props.options}
                        loadOptions={this.props.loadOptions}
                        defaultOptions={!!this.props.defaultOptions}
                        isClearable={!!this.props.isClearable}
                        isMulti={this.props.isMulti}
                        placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    />

                    {this.props.errorMessage && (
                        <div className={"text-red-700 italic mt-1"}>
                            {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
