export default class Resources {

    static UserDevices = "user/logged-devices";
    static UserReports = "users/report";

    static User = "user";
    static Users = "users";
    static UserImage = "user/image";

    static Company = "company";
    static CompanyEmail = "company/email";
    static CompanyWorkingHours = "company/working-hours";

    static Dashboard = "dashboard";

    static ScheduleItem = "schedule-item";
    static ScheduleItemParentUpdate = "schedule-item/parent-update";
    static ScheduleItemAcceptEvent = "schedule-item/accept-event";
    static ScheduleItemDenyEvent = "schedule-item/deny-event";
    static PublicAppointmentConfirmation = "public-appointment-confirmation";

    static CancelApt = "cancel-apt";

    static ScheduleLocations = "schedule-item-locations";
    static ScheduleLogs = "schedule-logs";
    static ScheduleLogsSingle = "schedule-logs/single";

    static Meeting = 'meeting';

    static Client = "client";
    static ClientInfo = "client/info";
    static ClientInvite = "client/invite";
    static ClientImage = "client/image";
    static Clients = "clients";
    static ClientMembers = "client/members";
    static ClientPickups = "client/pickup";
    static ClientPickupsCreate = "client/pickup/create";

    static ClientChild = "client/child";
    static ClientChildCreate = "client/child/create";

    static ClientProfile = "client-profile";
    static ClientProfileInfo = "client-profile/info";
    static ClientProfileCareTeam = "client-profile/care-team";
    static ClientProfileAuthPickup = "client-profile/authorized-pickup";
    static ClientProfileAuthPickupCreate = "client-profile/authorized-pickup/create";
    static ClientProfileAuthPickupUpdate = "client-profile/authorized-pickup/update";
    static ClientPickupImage = "client/pickup/image";

    static ClientProfileChild = "client-profile/child";
    static ClientProfileChildCreate = "client-profile/child/create";
    static ClientProfileChildUpdate = "client-profile/child/update";
    static ClientChildImage = "client/child/image";

    static ClientCareTeam = "client/care-team";
    static ClientCalendarExternal = "ext-client/calendar";
    static ExtClientClient = "ext-client/client";
    static ExtClientMembers = "ext-client/members";

    static Departments = "departments";

    static Member = "member";
    static MemberInvite = "member/invite";
    static Members = "members";
    static MemberProfile = "member-profile";
    static MemberImage = "member/image";
    static MemberClientsExternal = "ext-member/clients";
    static MemberCalendarExternal = "ext-member/calendar";
    static MemberUpdate = "member/update";

    static Documentation = "documentation";
    static DocumentationCategories = "documentation-categories";

    static CheckIn = "check-in";
    static CheckInUpdate = "check-in/update";

    static Groups = "groups";
    static GroupsLeave = "groups/leave";
    static GroupsMessages = "groups/message";
    static GroupsJoin = "groups/join";
    static GroupsMessagesLike = "groups/message/like";
    static GroupsMessagesRemoveLike = "groups/message/remove-like";
    static GroupsMessagesReport = "groups/message/report";

    static Messages = "messages";

    static Contacts = "contacts";

    static Categories = "categories";
}
