import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getProp} from "../../util/util";
import FieldSearch from "../../components/field-search";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import PageHeading from "../../components/page-heading";
import DialogDefault from "../../components/dialog-default";
import icons from "../../util/icons";

class UsersPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "first_name",

            query: "",
            archived: false,

            fields: this.getFields(null, ["RoleName"]),
            selectedItemFields: this.getSelectedItemFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC",
            offset: 0,
            paginationPage: 1
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1,
        }, this.fetchData)
    }

    handleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ContactID";
    }

    getResourceName = () => {
        return Resources.Users;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getSelectedItemFields = () => {
        return {
            login_time: new Field("login_time", '', [], false, 'datetime'),
            os: new Field("os", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnOSIcon(item.os)} <span
                            className='capitalize'>{item.os}</span></span>

                    </td>
                )
            }),
            Browser: new Field("Browser", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnBrowserIcon(item.Browser)} <span
                            className='capitalize'>{item.Browser}</span></span>
                    </td>
                )
            }),
            user_agent: new Field("user_agent", '', ['empty'], false, "text"),
            location: new Field("location", '', ['empty'], false, "text"),
            ip: new Field("ip", '', ['empty'], false, "text")
        };
    }

    getFields = (it = null, excludeFields = null, disabledEdit) => {
        let item = Object.assign({}, it);

        const fieldTemplates = {
            first_name: new Field("first_name", '', ['empty'], false, "text"),
            last_name: new Field("last_name", '', ['empty'], false, "text"),
            username: new Field("username", '', ['empty'], false, "text"),
            Roles: new Field("Roles", 1, ['empty'], true, 'select'),
            RoleName: new Field("RoleName", !!it && !!it.Roles && it.Roles[0]?.role_name, [], true, 'text'),
            /* Roles: new Field("Roles", '', [], (!!it && !!it.Roles && it.Roles[0]?.role_id == 5), "select-search", {
                 omitSort: true
             }),*/
            restrict: new Field("restrict", '', [], (!!it && !!it.Roles && it.Roles[0]?.role_id == 5), "checkbox", {
                omitSort: true
            })
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (item) {
            /*item.Roles = item?.Roles?.map((it) => {
                return {
                    value: it.role_id,
                    label: it.role_name
                }
            });*/

            item.restrict = (it !== null) && (((item?.Roles ?? []).length === 0) ? 1 : 0);
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    returnOSIcon = (os) => {
        switch (os) {
            case "linux":
                return icons.linux
            case "mac":
                return icons.mac
            case "windows":
                return icons.windows
            case "iPad":
                return icons.iPad
            case "iPhone":
                return icons.iPhone
            case "Android":
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ["Chrome", "Safari", "Firefox", "Opera", "Explorer", "Edge"].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []).map((it) => {
            it.restrict = ((it?.Roles ?? []).length === 0) ? 1 : 0;
            if (it.username === LocalStorage.get('user')?.Contact?.Email) {
                it.username += " (Current User)";
            }
            return it;
        });

        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            /* Roles: {
                 visibleFilter: (fieldsCpy) => {
                     const restrict = fieldsCpy.find(it => it.name === 'restrict');
                     return !!restrict?.value;
                 }
             },*/
            Roles: {
                1: "Super Admin",
                2: "Clinical Director",
                3: "Behavior Analyst",
                4: "Behavior Therapist"
            },
            /*filters: {
                Roles: (fieldsCpy) => {
                    return {
                        1: "Super Admin",
                        2: "Clinical Director",
                        3: "Behavior Analyst",
                        4: "Behavior Therapist"
                    }
                }
            }*/
        };

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                    <PageHeading
                        title="Users"
                    />
                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <div className="flex flex-wrap">
                            <FieldSearch
                                onChange={this.handleQueryChange}
                                name={"query"}
                                value={this.state.query}
                                placeholder="Search"
                                classNameContainer={"mr-6 mb-3"}
                            />

                            {checkPerm(Resources.ClientPickups, CREATE_PERM) && (
                                <div className="ml-auto mb-3">
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.add")}
                                    </button>
                                </div>
                            )}
                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleViewModal}
                            onEdit={this.handleToggleEditModel}
                            onDelete={this.handleToggleConfirmModal}
                        />
                    </div>

                    <DialogDefault
                        title={this.state.selectedItem?.first_name + " " + this.state.selectedItem?.last_name}
                        secondTitle={translate("text.LastTenLogins")}
                        widthClass="max-w-4xl"
                        visible={this.state.viewModalOpen}
                        blurBackdrop={true}
                        onClose={this.handleViewModal}
                        translate={translate}
                    >
                        <SimpleTable
                            data={Array.isArray(this.state.selectedItem?.Logins) ? this.state.selectedItem?.Logins : []}
                            fields={this.state.selectedItemFields}
                            translate={this.props.translate}
                            isLoading={isLoading}
                            limit={10}
                            offset={0}
                            hideCaption={true}
                        />
                    </DialogDefault>

                    <ModalSaveResource
                        title={"Edit User"}
                        className="max-w-lg"
                        disableOverflow={true}
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFields(this.state.selectedItem, ["username", "Roles"], true)}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: Object.assign({}, {
                                        UserID: this.state.selectedItem.user_id,
                                        FirstName: params.first_name,
                                        LastName: params.last_name,
                                        Roles: params.Roles?.map(it => it) ?? [],
                                        Email: this.state.selectedItem.username
                                    }),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        handleInputChange={(fields, name, value) => {
                            if ((name === "restrict") && !!value) {
                                fields.Roles.value = null;
                            }
                            return FieldsManager.updateField(fields, name, value);
                        }}
                    />

                    <ModalSaveResource
                        title={"Add User"}
                        className="max-w-lg"
                        disableOverflow={true}
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields(null, ['RoleName'])}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: Object.assign({}, params, {
                                        FirstName: params.first_name,
                                        LastName: params.last_name,
                                        Email: params.username,
                                        //Roles: params.Roles?.map(it => it) ?? []
                                        Roles: params.Roles
                                    }),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        handleInputChange={(fields, name, value) => {
                            if ((name === "restrict") && !!value) {
                                fields.Roles.value = null;
                            }
                            return FieldsManager.updateField(fields, name, value);
                        }}
                    />

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    id: this.state.selectedItem?.user_id
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </div>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(UsersPage);
