import {checkUserHelper} from "../services/api-util";
import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {
    doneCreateResource,
    doneGetResource,
    doneUpdateResource,
    errorCreateResource,
    errorUpdateResource,
    setLoading
} from "../actions/resource";
import {pushNotification} from "../actions/ui";
import Resources from "../services/resources";

export function* createClientResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    yield put(setLoading());

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.file) {
            yield call(Api.uploadFile, user, result.data.id, action.data.file, 0, action.data.fileResource, action.data.fileDescription);
        }

        if (result.data.ids) {
            yield call(Api.uploadFilesWithParams, user, Resources.ClientPickupImage, action.data.pickupFiles, [], result.data.ids.map((it) => {
                return {id: it};
            }));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateClientResource() {
    yield takeLatest('CREATE_CLIENT_RESOURCE', createClientResourceCall);
}

export function* updateClientResource(action) {
    const user = yield* checkUserHelper(action.data.user);

    yield put(setLoading());

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.file && !!action.data.updateImage) {
            yield call(Api.uploadFile, user, result.data.id, action.data.file, 0, action.data.fileResource, action.data.fileDescription);
        }

        if (result.data.ids) {
            let updatedImageIDs = [];

            let updatedImages = action.data.pickupFiles.filter(it => {
                if (result.data.ids.includes(it.id)) {
                    updatedImageIDs.push(it.id)
                    return true
                }

                return false
            })

            action.data.params.pickups.forEach(it => {
                if (!!it.newPersonImage) {
                    updatedImages.push(it.ImagePath)
                }
            });

            yield call(Api.uploadFilesWithParams, user, Resources.ClientPickupImage, updatedImages, [], result.data.ids.map((it) => {
                return {id: it};
            }));
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateClientResource() {
    yield takeLatest('UPDATE_CLIENT_RESOURCE', updateClientResource);
}


