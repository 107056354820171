import React, {useState} from 'react';
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";
import MyModal from "../modal";

const ImageViewCell = ({classNames, fields, item, itemKey, i, type}) => {

    const [open, setModalOpen] = useState(false);

    const content = (
        <>
            {(!(item[itemKey]?.[0]?.preview || item[itemKey]?.preview) && !!item[itemKey]) && (
                <img className="h-12 w-12 rounded-full border border-primary-600 object-cover"
                     src={fields[itemKey]?.metadata?.src(item)} alt=""/>
            )}
            {(!!item[itemKey]?.preview || !!item[itemKey]?.[0]?.preview) && (
                <img className="h-12 w-12 rounded-full border border-primary-600 object-cover"
                     src={item[itemKey]?.preview || item[itemKey]?.[0]?.preview} alt=""/>
            )}
            {!item[itemKey] && (
                <QuestionMarkCircleIcon
                    className="h-12 w-12 rounded-full border border-primary-600 object-cover"
                />
            )}
        </>
    );

    return (
        <>
            <td className={classNames + (!!item[itemKey] ? " hover:cursor-pointer" : "")} key={i}
                onClick={() => {
                    !!item[itemKey] && setModalOpen(true)
                }}
            >
                {content}
            </td>
            <MyModal
                visible={open}
                onClose={() => setModalOpen(false)}
            >
                {!item[itemKey]?.[0]?.preview && !!item[itemKey] && (
                    <img className=" w-full align-middle" src={fields[itemKey]?.metadata?.src(item)} alt=""/>
                )}
                {!!item[itemKey]?.[0]?.preview && (
                    <img className=" w-full align-middle" src={item[itemKey][0].preview} alt=""/>
                )}
            </MyModal>
        </>
    )
}

export default ImageViewCell;