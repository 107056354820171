import React, {Component} from 'react'
import {
    createDialogResource,
    deleteDialogResource,
    getDialogResource,
    updateDialogResource
} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import SimpleTable from "../../../components/simple-table";
import {checkPerm, CREATE_PERM, DELETE_PERM, fillFieldsFromData, getProp, UPDATE_PERM} from "../../../util/util";
import ModalSaveDialogResource from "../../../components/modal/modal-save-resource";
import {PencilIcon, PlusIcon} from "@heroicons/react/solid";
import ModalConfirm from "../../../components/modal/modal-confirm";
import Resources from "../../../data/services/resources";
import {ArchiveIcon} from "@heroicons/react/outline";


export default class ClientsDialogCareTeamTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: this.props.sortBy ?? "",

            query: "",
            archived: false,

            fields: this.getFields(null, ['MemberID', 'ClientMemberID']),

            selectedItem: null,
            editModalOpen: false,
            createModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return this.props.primaryKey ?? "";
    }

    getResourceName = () => {
        return this.props.resourceName ?? "";
    }

    getQuery = () => {
        return {
            ClientID: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields) => {
        const fieldTemplates = this.props.fields ?? {};

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resourceDialogWidthClass, resourceDialogDisableOverflow} = this.props;

        const data = getProp(this.props, "dialogResource.data.list", []);
        const count = getProp(this.props, "dialogResource.data.count", 0);

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        return (
            <div className="">
                <div className="mt-6 flex flex-wrap">
                    <div className="flex-shrink-0 ml-auto mb-3">
                        {checkPerm(Resources.ClientCareTeam, CREATE_PERM) && (
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                {this.props.createLabel || translate("btn.create")}
                            </button>
                        )}
                    </div>
                </div>

                <SimpleTable
                    data={data}
                    count={count}

                    fields={this.state.fields}
                    translate={this.props.translate}
                    isLoading={isLoading}

                    limit={this.state.limit}
                    offset={this.state.offset}
                    paginationPage={this.state.paginationPage}
                    onOffsetChange={this.handleUpdateOffset}

                    sort={this.state.sort}
                    sortBy={this.state.sortBy}
                    onSortChange={this.handleUpdateSort}

                    onCustomAction={(it) => {
                        if (it.Email !== LocalStorage.get("user").Contact.Email) {
                            return (
                                <>
                                    {checkPerm(Resources.ClientCareTeam, UPDATE_PERM) && (
                                        <button
                                            onClick={() => this.handleToggleEditModel(it)}
                                            title="Edit"
                                            type="button"
                                            className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                                        >
                                            <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"/>

                                            <span className="hidden sm:inline">Edit</span>
                                        </button>

                                    )}
                                    {checkPerm(Resources.ClientCareTeam, DELETE_PERM) && (
                                        <button
                                            title={translate("btn.delete")}
                                            onClick={() => this.handleToggleConfirmModal(it)}
                                            type="button"
                                            className="mx-1 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-red-500"
                                        >
                                            <ArchiveIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                                         aria-hidden="true"/>

                                            <span className="hidden sm:inline">
                                                {translate("btn.delete")}
                                            </span>
                                        </button>
                                    )}
                                </>
                            )
                        }
                    }}

                />

                <ModalSaveDialogResource
                    title={"Edit " + this.props.dialogTitle}
                    widthClass={resourceDialogWidthClass ? resourceDialogWidthClass : "max-w-lg"}
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModel}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.props.handleInputChange}
                    disableOverflow={resourceDialogDisableOverflow}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(updateDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: Object.assign(params),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleEditModel();
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                />

                <ModalSaveDialogResource
                    title={(this.props.createLabel ? this.props.createLabel : "Create " + this.props.dialogTitle)}
                    widthClass={resourceDialogWidthClass ? resourceDialogWidthClass : "max-w-lg"}
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    handleInputChange={this.props.handleInputChange}
                    disableOverflow={resourceDialogDisableOverflow}
                    onSubmit={(params) => {
                        if (params) {
                            params.ClientID = this.getId();
                            this.props.dispatch(createDialogResource({
                                user: LocalStorage.get("user"),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={this.props.metadata ?? {}}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        this.props.dispatch(deleteDialogResource({
                            user: LocalStorage.get("user"),
                            query: Object.assign({
                                ClientMemberID: this.state.selectedItem["ClientMemberID"]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </div>
        )
    }
}
