import React, {Component} from 'react'
import {getDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../data/services/resources";
import {checkPerm, classNames, CREATE_PERM, fieldsToHtml, fillFieldsFromData, getProp} from "../../../util/util";
import {Field, FieldsManager} from "../../../data/services/fields";
import Loader from "../../../components/loader";
import Button from "../../../components/button";
import Env from "../../../util/env";
import MyModal from "../../../components/modal";


export default class CompensationTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            removeImage: "",
            updateImage: "",
            photoVisible: false,
            selectedPhoto: null
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dialogResource.isLoading && !this.props.dialogResource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "dialogResource.data." + this.getDialogResourceName(), {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getDialogResourceName()
        }))
    };

    onSubmit = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {

            this.props.dispatch(updateDialogResource({
                user: LocalStorage.get("user"),
                query: this.getQuery(),
                params: Object.assign({
                        ClientID: this.getId(),
                    },
                    FieldsManager.getFieldKeyValues(this.state.fields)),
                resource: Resources.Client,
                piggyResource: Resources.ClientInfo,
                notificationMessage: this.props.translate("message.client_updated")
            }));


        } else {
            this.setState({fields});
        }
    };

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let removeImage = this.state.removeImage;
        let updateImage = this.state.updateImage;

        if ("ImagePath" === name && value) {
            removeImage = "";
            updateImage = 1;
        }

        if ("ImagePath" === name && !value) {
            removeImage = 1;
            updateImage = "";
        }


        this.setState({
            canSubmit: true,
            fields: FieldsManager.updateField(this.state.fields, name, value),
            removeImage,
            updateImage
        });
    };


    handleCancel = () => {
        this.setState({
            fields: this.getFields(getProp(this.props, "dialogResource.data." + this.getDialogResourceName(), {})),
            canSubmit: false
        });
    };

    toggleShowPhoto = (it = null) => {
        this.setState({
            selectedPhoto: it,
            photoVisible: !this.state.photoVisible
        })
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getDialogResourceName = () => {
        return Resources.ClientInfo;
    }

    getQuery = () => {
        return {
            ClientID: this.getId()
        }
    }

    getFields = (item = null) => {

        const canCreate = checkPerm(Resources.ClientInfo, CREATE_PERM);

        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], !canCreate, 'text'),
            LastName: new Field("LastName", '', ['empty'], !canCreate, 'text'),
            Email: new Field("Email", '', ['email'], !canCreate, !!item ? 'readonly' : 'text'),
            Phone: new Field("Phone", '', [], !canCreate, 'text'),
            AlternatePhone: new Field("AlternatePhone", '', [], !canCreate, 'text'),
            /*
            ImagePath: new Field("ImagePath", '', [], !canCreate, (this.props.createModal ? 'hidden' : (!!canCreate? 'photo' : "readonly-photo")), {
                src: (it) => {
                    const imagePath = it?.value || it?.ImagePath
                    return !!imagePath ? Env.getApiUrl('api/' + Resources.ClientImage, {
                        v: item.CreateUpdateDate,
                        id: it.ClientID || it?.metadata?.id,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                },
                id: item?.ClientID,
                onClick: !canCreate ? this.toggleShowPhoto : null
            })
             */
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "dialogResource.isLoading", false);

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange);

        return (
            <div className="">
                {isLoading && (
                    <div className={"inset-center"}>
                        <Loader/>
                    </div>
                )}

                <form className={classNames("space-y-8 divide-y divide-gray-200", isLoading ? "invisible" : "")}>
                    {fieldsHtml}
                </form>

                {!isLoading && checkPerm(Resources.ClientInfo, CREATE_PERM) && (
                    <div className={classNames("pt-5", isLoading ? "invisible" : "")}>
                        <div className="flex justify-end">
                            <Button type="outline"
                                    className="btn btn-outline focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.handleCancel}
                            >
                                {translate("btn.cancel")}
                            </Button>

                            <Button
                                type="submit"
                                className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                disabled={!this.state.canSubmit}
                                onClick={this.state.canSubmit && this.onSubmit}
                            >
                                {translate("btn.save")}
                            </Button>
                        </div>
                    </div>
                )}

                <MyModal
                    visible={this.state.photoVisible}
                    onClose={() => this.toggleShowPhoto(false)}
                >
                    <img
                        className=" w-full align-middle"
                        src={Env.getApiUrl('api/' + Resources.ClientImage, {
                            id: this.state.selectedPhoto?.metadata?.id,
                            token: LocalStorage.get('user').access_token
                        })}
                        alt=""
                    />
                </MyModal>
            </div>

        )
    }
}
