export function getDialogResource(data) {
    return {
        type: 'GET_DIALOG_RESOURCE',
        data: data
    };
}

export function doneGetDialogResource(data) {
    return {
        type: 'DONE_GET_DIALOG_RESOURCE',
        data: data
    };
}

export function errorGetDialogResource(data) {
    return {
        type: 'ERROR_GET_DIALOG_RESOURCE',
        data: data
    };
}

export function getDialogResourceDetails(data) {
    return {
        type: 'GET_DIALOG_RESOURCE_DETAILS',
        data: data
    };
}

export function doneGetDialogResourceDetails(data) {
    return {
        type: 'DONE_GET_DIALOG_RESOURCE_DETAILS',
        data: data
    };
}

export function errorGetDialogResourceDetails(data) {
    return {
        type: 'ERROR_GET_DIALOG_RESOURCE_DETAILS',
        data: data
    };
}

export function createDialogResourceWithImage(data) {
    return {
        type: 'CREATE_DIALOG_RESOURCE_WITH_IMAGE',
        data: data
    };
}

export function doneCreateDialogResourceWithImage(data) {
    return {
        type: 'DONE_CREATE_DIALOG_RESOURCE_WITH_IMAGE',
        data: data
    };
}

export function errorCreateDialogResourceWithImage(data) {
    return {
        type: 'ERROR_CREATE_DIALOG_RESOURCE_WITH_IMAGE',
        data: data
    };
}

export function createDialogResource(data) {
    return {
        type: 'CREATE_DIALOG_RESOURCE',
        data: data
    };
}

export function doneCreateDialogResource(data) {
    return {
        type: 'DONE_CREATE_DIALOG_RESOURCE',
        data: data
    };
}

export function errorCreateDialogResource(data) {
    return {
        type: 'ERROR_CREATE_DIALOG_RESOURCE',
        data: data
    };
}

export function updateDialogResource(data) {
    return {
        type: 'UPDATE_DIALOG_RESOURCE',
        data: data
    };
}

export function doneUpdateDialogResource(data) {
    return {
        type: 'DONE_UPDATE_DIALOG_RESOURCE',
        data: data
    };
}

export function errorUpdateDialogResource(data) {
    return {
        type: 'ERROR_UPDATE_DIALOG_RESOURCE',
        data: data
    };
}

export function deleteDialogResource(data) {
    return {
        type: 'DELETE_DIALOG_RESOURCE',
        data: data
    };
}

export function doneDeleteDialogResource(data) {
    return {
        type: 'DONE_DELETE_DIALOG_RESOURCE',
        data: data
    };
}

export function errorDeleteDialogResource(data) {
    return {
        type: 'ERROR_DELETE_DIALOG_RESOURCE',
        data: data
    };
}

export function resetDialogResourceMessage() {
    return {
        type: 'RESET_DIALOG_RESOURCE_MESSAGE'
    }
}

export function uploadDialogDocument(data) {
    return {
        type: 'UPLOAD_DIALOG_DOCUMENT',
        data: data
    };
}

export function doneUploadDialogDocument(data) {
    return {
        type: 'DONE_UPLOAD_DIALOG_DOCUMENT',
        data: data
    };
}

export function errorUploadDialogDocument(data) {
    return {
        type: 'ERROR_UPLOAD_DIALOG_DOCUMENT',
        data: data
    };
}