import React, {Component} from 'react'
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import HorizontalTabs from "../../components/horizontal-tabs";
import Resources from "../../data/services/resources";
import {Field, FieldsManager} from "../../data/services/fields";
import PageHeading from "../../components/page-heading";
import ResourceTabForm from "../../components/resource-tab-form";
import Env from "../../util/env";
import LocalStorage from "../../util/localStorage";
import {fillFieldsFromData, getProp} from "../../util/util";
import {createResourceWithImage, getResource} from "../../data/actions/resource";


class ProfileMember extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                {name: props.translate('tab.info'), resource: Resources.MemberProfile, current: true},
            ],
            selectedTab: Resources.MemberProfile,
            selectedItem: null,
            authorizedPickupData: [],
            removeImage: null,
            updateImage: null
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.selectedTab !== prevState.selectedTab) {
            this.fetchData();
        }

        if (prevProps.resource.isLoading && !this.props.resource.isLoading && this.state.selectedTab) {
            this.setState({
                selectedItem: getProp(this.props.resource, "data." + this.state.selectedTab, []),
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: "",
            resource: this.state.selectedTab
        }))
    };

    /** UI Events
     ================================================================= */
    handleTabChange = (resource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === resource;
                return it;
            }),
            selectedTab: resource
        })
    };

    handleInputChange = (fields, name, value) => {
        if ("ImagePath" === name) {
            this.setState({
                removeImage: null,
                updateImage: 1
            });
        }

        return FieldsManager.updateField(fields, name, value);
    };

    /** Helpers
     ================================================================= */
    getMemberFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            DepartmentID: new Field("DepartmentID", "N/A", [''], false, 'readonly-select-search'),
            FirstName: new Field("FirstName", '', ['empty'], false, 'text'),
            LastName: new Field("LastName", '', ['empty'], false, 'text'),
            Email: new Field("Email", '', [], false, !!item ? 'readonly' : 'text'),
            Phone: new Field("Phone", '', [], false, 'text'),
            Credentials: new Field("Credentials", '', [], false, 'text'),
            ReportsToID: new Field("ReportsToID", "N/A", [], false, 'readonly-select-search'),
            SystemRoleID: new Field("SystemRoleID", "", [], false, 'custom'),
            Bio: new Field("Bio", '', [], false, 'textarea', {
                hideTable: true
            }),
            PersonalStatement: new Field("PersonalStatement", '', [], false, 'textarea', {
                hideTable: true
            }),
            ImagePath: new Field("ImagePath", '', [], false, 'photo', {
                src: () => {
                    return !!item?.ImagePath ? Env.getApiUrl('api/' + Resources.MemberImage, {
                        v: item?.CreateUpdateDate,
                        id: item?.MemberID,
                        token: LocalStorage.get('user').access_token
                    }) : null;
                }, removePhoto: this.removePhoto
            }),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    removePhoto = () => {
        let selectedItem = Object.assign({}, this.state.selectedItem, {
            ImagePath: "",
        });

        this.setState({
            selectedItem: selectedItem,
            removeImage: 1,
            updateImage: null,
            imageFile: null
        })
    };

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            archived: this.state.archived ? 1 : 0
        }
    };

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props;

        const isLoading = getProp(resource, "isLoading", null);

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">

                        <PageHeading
                            title={translate("page.title.profile")}
                        />

                        <HorizontalTabs
                            tabs={this.state.tabs}
                            handleTabChange={this.handleTabChange}
                        />

                        {Resources.MemberProfile === this.state.selectedTab && (
                            <ResourceTabForm
                                fields={this.getMemberFields(this.state.selectedItem)}
                                selectedItem={this.state.selectedItem}
                                translate={this.props.translate}
                                resource={this.props.resource}
                                isLoading={isLoading}
                                handleInputChange={this.handleInputChange}
                                onSubmit={(params) => {
                                    if (params) {
                                        this.props.dispatch(createResourceWithImage({
                                            user: LocalStorage.get("user"),
                                            query: this.getQuery(),
                                            params: Object.assign(params, {
                                                removeImage: this.state.removeImage,
                                                updateImage: this.state.updateImage
                                            }),
                                            image: params.ImagePath,
                                            resource: this.state.selectedTab,
                                            piggyResource: this.state.selectedTab,
                                            notificationMessage: translate("message.profile_updated")
                                        }));
                                    }
                                }}
                                metadata={{
                                    DepartmentID: {
                                        api: 'api/' + Resources.Departments,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.DepartmentID,
                                            label: item.Department
                                        })
                                    },
                                    ReportsToID: {
                                        api: 'api/' + Resources.Members,
                                        query: {},
                                        searchMap: (item) => ({
                                            value: item.MemberID,
                                            label: item.FirstName + " " + item.LastName
                                        })
                                    },
                                    SystemRoleID: (fieldsCpy, item) => {
                                        const SystemRoleID = {
                                            1: "Super Admin",
                                            2: "Clinical Director",
                                            3: "Behavior Analyst",
                                            4: "Behavior Therapist"
                                        }
                                        return (
                                            <div
                                                className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                                                <label
                                                    className={"block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2"}>
                                                    {translate("field.SystemRoleID")}
                                                </label>
                                                <dd className="mt-1 text-gray-900">{SystemRoleID[item?.value]}</dd>
                                            </div>
                                        )
                                    }
                                }}
                            />
                        )}
                    </div>
                </LayoutDashboard>
            </React.Fragment>
        )
    }
}

export default connect(state => state)(ProfileMember);
