import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import {fillFieldsFromData} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import FieldSelectSearch from "../../components/field-select-search";
import {connect} from "react-redux";

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: this.getFields(),
            filterFields: this.getFilterFields(),
            limit: 10,
            offset: 0
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({filterFields: FieldsManager.updateField(this.state.filterFields, name, value)});
    };

    getFilterFields = (item = null) => {
        const fieldTemplates = {
            Report: new Field("Report", '', [], false,),
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    getFields = (item = null) => {
        const fieldTemplates = {
            Year: new Field("Year", '', []),
            Month: new Field(" Month", '', [],),
            Name: new Field("Name", '', []),
            Available: new Field("Available", '', []),
            Session: new Field("Session", '', []),
            TravelTime: new Field("TravelTime", '', []),
            Vacation: new Field("Vacation", '', [])
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    render() {
        let resource = [];
        const data = [];
        const count = 0;

        const values = ["Utilization Report", "Report 2", "Report 3"];

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                    <PageHeading
                        title="Reports"
                    />

                    <div className="mt-6 grid grid-cols-12 gap-6">
                        <div className="col-span-12 lg:col-span-3 sm:col-span-6">
                            <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                Report
                            </label>

                            <FieldSelectSearch
                                placeholder="Filter Reports"
                                values={values}
                                all={true}
                                onChange={this.handleInputChange}
                                {...this.state.filterFields.Report}
                            />
                        </div>
                    </div>

                    <SimpleTable
                        data={data}
                        count={count}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={resource.isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={this.handleToggleEditModel}
                        onDelete={this.handleToggleConfirmModal}
                    />
                </div>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(Reports);