import React from "react";

export default (props) => {
    const {text} = props;
    return (
        <div className="px-6 py-3 flex rounded-btn bg-document text-secondary-900 flex">
            <svg className="animate-spin h-6 w-6 text-soft inline-block mr-3"
                 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                        strokeWidth="4"/>
                <path className="opacity-75" fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
            </svg>

            {!!text && (
                <span className="text-secondary-800">{text}</span>
            )}
        </div>
    )
}