import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import uiReducer from "./ui";
import dialogResourceReducer from "./dialogResource";
import messagesReducer from "../../package/chat/reducers/messaging";
import publicReducer from "./publicResource";

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    dialogResource: dialogResourceReducer,
    publicResource: publicReducer,
    messages: messagesReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            secondResource: {},
            dialogResource: {},
            publicResource: {},
            messages: {}
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
