import {Dialog, Transition} from "@headlessui/react";
import {Fragment, useRef} from "react";

export default function MyModal(props) {

    const {onClose, visible} = props;

    const cancelButtonRef = useRef();

    return (
        <Transition show={visible} as={Fragment}>
            <Dialog
                as="div"
                id="modal"
                className="fixed inset-0 z-50 overflow-y-auto"
                initialFocus={cancelButtonRef}
                static
                open={visible}
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                    &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                            <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                {props.children}
                            </div>

                            <div className="pt-5">
                                <div className="flex justify-end">
                                    <button
                                        ref={cancelButtonRef}
                                        type="button"
                                        className="btn btn-outline focus:ring-offset-secondary-50"
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}
