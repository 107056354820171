import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import Button from "../../components/button";
import {createResource, deleteResource, getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {Field} from "../../data/services/fields";
import {
    checkPerm,
    classNames,
    CREATE_PERM,
    DELETE_PERM,
    fillFieldsFromData,
    getProp, LOGGED_IN_USER_TYPE_ADMIN,
    toFrontDateTimeFromUTC,
    UPDATE_PERM
} from "../../util/util";
import {connect} from "react-redux";
import Loader from "../../components/loader";
import {
    createSecondResource,
    deleteSecondResource,
    getSecondResource,
    updateSecondResource
} from "../../data/actions/secondResource";
import {AnnotationIcon, FlagIcon, ThumbUpIcon} from "@heroicons/react/outline";
import ModalConfirm from "../../components/modal/modal-confirm";
import DialogDefault from "../../components/dialog-default";
import {DocumentRemoveIcon, MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/solid";
import MemberCardDialog from "../../components/member-card";
import Env from "../../util/env";

class Groups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 500,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "GroupName",

            query: "",
            archived: false,

            fields: this.getFields(),

            isCreateGroupModalOpen: false,
            isCreateCommentModalOpen: false,
            isCreateReportModalOpen: false,
            isLikesModalOpen: false,

            groupID: null,
            groupTitle: null,
            messageValue: null,
            groupMessageID: null,
            confirmModalOpen: false,
            confirmLeaveModalOpen: false,
            discModalOpen: false,
            confirmMessageModalOpen: false,
            selectedLikes: [],

            viewModalOpen: false,
            selectedItem: null,

            closedThreads: {}
        }

        this.scrollRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.secondResource.data && !this.props.secondResource.isLoading && prevProps.secondResource.isLoading) {
            this.scrollToEnd()
        }
        if (!this.props.secondResource.create && prevProps.secondResource.create) {
            this.scrollToEnd()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.Groups
        }))
    };

    fetchMessages = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getSecondQuery(),
            resource: Resources.GroupsMessages
        }))
    };

    joinGroup = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                id: this.state.selectedItem.GroupID
            },
            resource: Resources.GroupsJoin,
            piggyResource: Resources.Groups
        }));
    };

    /** UI Events
     ================================================================= */

    handleToggleThread = (i) => {
        let closedThreads = this.state.closedThreads;

        closedThreads[i] = !closedThreads[i];

        this.setState({
            closedThreads: closedThreads
        })
    }

    handleSendMessage = () => {
        if (!this.state.messageValue) {
            return;
        }

        const value = this.state.messageValue;

        this.setState({
            messageValue: null
        }, () => {
            this.props.dispatch(createSecondResource({
                user: LocalStorage.get("user"),
                query: this.getSecondQuery(),
                params: {
                    message: value,
                    id: this.state.groupID
                },
                resource: Resources.GroupsMessages,
                piggyResource: Resources.GroupsMessages,
                notificationMessage: "Message posted to the group."
            }));
            this.scrollToEnd();
        });
    }

    handleLikeMessage = (GroupMessageID, Likes) => {
        const didUserAlreadyLike = Likes.some(item => item.ContactID == LocalStorage.get('user')?.Contact?.ContactID)
        const Answer = didUserAlreadyLike ? 0 : 1

        if (didUserAlreadyLike) {
            this.props.dispatch(deleteSecondResource({
                user: LocalStorage.get("user"),
                query: {
                    GroupMessageID
                },
                resource: Resources.GroupsMessagesRemoveLike,
                piggyResource: Resources.GroupsMessages,
                notificationMessage: `Message unliked!`,
                piggyQuery: this.getSecondQuery()
            }))
        } else {
            this.props.dispatch(createSecondResource({
                user: LocalStorage.get("user"),
                query: this.getSecondQuery(),
                params: {
                    id: this.state.groupID,
                    GroupMessageID,
                    Answer
                },
                resource: Resources.GroupsMessagesLike,
                piggyResource: Resources.GroupsMessages,
                notificationMessage: `Message ${Answer ? "liked" : "unliked"}!`
            }));
        }

        this.setState({
            messageValue: null
        });
    }

    handleRemoveMessage = (GroupMessageID) => {
        this.props.dispatch(updateSecondResource({
            user: LocalStorage.get("user"),
            query: this.getSecondQuery(),
            params: {
                id: this.state.groupID,
                GroupMessageID: GroupMessageID
            },
            resource: Resources.GroupsMessagesReport,
            piggyResource: Resources.GroupsMessages,
            notificationMessage: "Message removed!"
        }));
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleConfirmLeaveModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmLeaveModalOpen: !this.state.confirmLeaveModalOpen
        })
    }

    handleToggleMessageDeleteModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmMessageModalOpen: !this.state.confirmMessageModalOpen
        })
    }

    toggleCreateGroupDialog = () => {
        this.setState({
            isCreateGroupModalOpen: !this.state.isCreateGroupModalOpen
        });
    }

    toggleCreateCommentDialog = (GroupMessageID = null) => {
        this.setState({
            isCreateCommentModalOpen: !this.state.isCreateCommentModalOpen,
            groupMessageID: GroupMessageID
        });
    }

    toggleCreateReportDialog = (GroupMessageID = null) => {
        this.setState({
            isCreateReportModalOpen: !this.state.isCreateReportModalOpen,
            groupMessageID: GroupMessageID
        });
    }

    handleToggleDiscModal = (item = null) => {
        this.setState({
            selectedItem: item,
            discModalOpen: !this.state.discModalOpen
        })
    }

    handleSelectGroup = (item) => {
        const members = getProp(this.props, "resource.data.members", {});

        if (!members[item.GroupID]) {
            this.handleToggleDiscModal(item);
        } else {
            this.setState({
                groupID: item.GroupID,
                groupTitle: item.GroupName,
            }, this.fetchMessages)
        }
    }

    handleToggleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    onEnter = (e) => {
        if (e.key === 'Enter') {
            this.handleSendMessage();
        }
    }

    handleToggleMessageLikesDialog = (likes = []) => {
        this.setState({
            isLikesModalOpen: !this.state.isLikesModalOpen,
            selectedLikes: likes
        })
    }
    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id ?? 0;
    }

    getPrimaryKey = () => {
        return "GroupID";
    }

    getResourceName = () => {
        return Resources.Groups;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
        }
    }

    getSecondQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: "",
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            GroupID: this.state.groupID
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            GroupName: new Field("GroupName", '', ['empty'], '', 'text')
        };
        return fillFieldsFromData(fieldTemplates, item);
    };

    getFieldsComment = (item = null) => {
        const fieldTemplates = {
            message: new Field("message", '', ['empty'], '', 'textarea')
        };
        return fillFieldsFromData(fieldTemplates, item);
    };

    scrollToEnd = () => {
        const scrollEl = this.scrollRef.current
        if (!!scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    renderMessageLikedUsers = (likes) => {
        return likes.length > 0 && (
            <div
                onClick={() => this.handleToggleMessageLikesDialog(likes)}
                className="flex justify-end items-center mr-2">
                <div className="flex items-center space-x-2 relative pl-2">
                    <span className="font-medium text-default-soft">Liked by:</span>

                    <div className="flex flex-shrink-0 -space-x-1 cursor-pointer">
                        {likes
                            .filter((_, index) => index < 3)
                            .map((user) => {
                                return !!user.ImagePath ?
                                    <img
                                        key={user.UserID}
                                        className="max-w-none h-6 w-6 rounded-full ring-2 ring-white"
                                        src={Env.getApiUrl("api/" + this.getImageResourcePathForUser(user), {
                                            token: LocalStorage.get('user')?.access_token,
                                            id: this.getImagePersonID(user)
                                        })}
                                        alt={user.Email}
                                    />
                                    :
                                    <svg className="max-w-none h-6 w-6 rounded-full ring-2 ring-white text-gray-300"
                                         fill="currentColor" viewBox="0 0 24 24">
                                        <path
                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                        ></path>
                                    </svg>
                            })
                        }
                    </div>
                    {likes.length > 3 ? (
                        <span
                            className="flex-shrink-0 text-xs leading-5 font-medium text-default-soft ">
                                    +{likes.length - 3}
                                  </span>
                    ) : null}
                </div>
            </div>
        )
    }

    renderMessageButtons = (item) => {
        const isMessageLikedByUser = item?.Likes.some(item => item.ContactID == LocalStorage.get('user')?.Contact?.ContactID)

        return (
            <>
                <span title={"Report"}
                      onClick={() => this.toggleCreateReportDialog(item.GroupMessageID)}
                      className="hover:cursor-pointer hover:text-primary-600"><FlagIcon
                    style={{fill: item.Reported ? 'red' : 'white'}}
                    className={"hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top pt-0.5"}
                    aria-hidden="true"/>
                </span>
                {checkPerm(Resources.GroupsMessagesReport, UPDATE_PERM) && (
                    <span title={"Click to remove message"}
                          onClick={() => this.handleToggleMessageDeleteModal(item)}
                          className="hover:cursor-pointer hover:text-primary-600"><DocumentRemoveIcon
                        className={"hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top pt-0.5 "}
                        aria-hidden="true"/>
                    </span>
                )}
                <span className={"float-right flex"}>
                    {this.renderMessageLikedUsers(item?.Likes ?? [])}

                    <span title={`Click to ${isMessageLikedByUser ? "unlike" : "like"} message`}>
                        <ThumbUpIcon
                            onClick={() => this.handleLikeMessage(item.GroupMessageID, item.Likes)}
                            className={`hover:cursor-pointer h-5 w-5 inline-block align-top pt-0.5`}
                            aria-hidden="true"
                            style={{fill: isMessageLikedByUser ? 'red' : 'white'}}
                        />
                    </span>
                </span>
            </>
        )
    }

    findGroupMember = (id) => {
        return getProp(this.props, "secondResource.data.members", {})[id];
    }

    getImageResourcePathForUser = (user) => {
        switch (user.ContactType) {
            case 'User':
                return Resources.UserImage
            case 'Client':
                return Resources.ClientImage
            case 'Member':
                return Resources.MemberImage
        }
    }

    getImagePersonID = (user) => {
        switch (user.ContactType) {
            case 'User':
                return user.ContactID
            case 'Client':
                return user.ClientID
            case 'Member':
                return user.MemberID
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const members = getProp(this.props, "resource.data.members", {});

        const groups = getProp(this.props, "resource.data.list", []).map((item, i) => {
            const current = this.state.groupID == item.GroupID;
            const member = members[item.GroupID];

            return (
                <div
                    key={item.GroupName}
                    onClick={() => this.handleSelectGroup(item)}
                    className={classNames(
                        current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-3 py-2 text-sm font-medium rounded-md hover:cursor-pointer'
                    )}
                >
                    <span className="truncate inline-block mr-2">{item.GroupName}</span>

                    {!!member && (
                        <span
                            className="inline-block items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800 ml-2">
                            Member
                        </span>
                    )}

                    {item.count ? (
                        <span
                            className={classNames(
                                item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                                'ml-auto inline-block py-0.5 px-3 text-xs rounded-full'
                            )}
                        >
                          {item.count}
                        </span>
                    ) : null}
                </div>
            );
        });

        const groupMessages = getProp(this.props, "secondResource.data.list", []).map((item) => {

            let groupComments = item?.comments?.map((item2) => {
                let groupCommentComments = item2?.comments?.map((item3) => {
                    const member = this.findGroupMember(item3.ContactID);
                    return (
                        <div className="pl-10">
                            <div className={"border-primary-600 rounded-lg border-2 mb-4"}>
                                <div className={"p-2"}>
                                    <div

                                        className="pb-4">
                                    <span
                                        onClick={() => member?.FirstName && this.handleToggleViewModal(member)}
                                        className={"font-bold " + (member?.FirstName ? "hover:cursor-pointer text-primary" : "")}>{item3.ContactName}</span> {toFrontDateTimeFromUTC(item2.CreateUpdateDate)}
                                    </div>
                                    <div>
                                        {item3.Message}
                                    </div>
                                </div>
                                <div className="border-t-2 border-primary-600 py-1 px-2">
                                    <div>
                                        {this.renderMessageButtons(item3)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                });

                const member = this.findGroupMember(item2.ContactID);
                return (
                    <div className="pl-10">
                        <div className={"border-primary-600 rounded-lg border-2 mb-4"}>
                            <div className={"p-2"}>
                                <div

                                    className="pb-4">
                                    <span
                                        onClick={() => member?.FirstName && this.handleToggleViewModal(member)}
                                        className={"font-bold " + (member?.FirstName ? "hover:cursor-pointer text-primary" : "")}>{item2.ContactName}</span> {toFrontDateTimeFromUTC(item2.CreateUpdateDate)}
                                </div>
                                <div>
                                    {item2.Message}
                                </div>
                            </div>
                            <div className="border-t-2 border-primary-600 py-1 px-2">
                                <div>
                                    <span title={"Comment"}
                                          onClick={() => this.toggleCreateCommentDialog(item2.GroupMessageID)}
                                          className="hover:cursor-pointer hover:text-primary-600 mr-2">
                                        <AnnotationIcon
                                            className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top pt-0.5"
                                            aria-hidden="true"/>
                                    </span>
                                    {this.renderMessageButtons(item2)}
                                </div>
                            </div>
                        </div>


                        <div className={"mt-1 mb-4"}>
                            {(groupCommentComments?.length > 0) && (
                                <React.Fragment>
                                    {!this.state.closedThreads[item2.GroupMessageID] && (
                                        <MinusCircleIcon
                                            className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top"
                                            aria-hidden="true"
                                            onClick={() => this.handleToggleThread(item2.GroupMessageID)}
                                        />
                                    )}

                                    {!!this.state.closedThreads[item2.GroupMessageID] && (
                                        <PlusCircleIcon
                                            className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top"
                                            aria-hidden="true"
                                            onClick={() => this.handleToggleThread(item2.GroupMessageID)}
                                        />
                                    )}

                                    {!this.state.closedThreads[item2.GroupMessageID] && (
                                        groupCommentComments
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                )
            });

            const member = this.findGroupMember(item.ContactID);

            return (
                <div>
                    <div className={"border-primary-600 rounded-lg border-2 "}>
                        <div className={"p-2"}>
                            <div className="pb-4">
                                    <span
                                        onClick={() => member?.FirstName && this.handleToggleViewModal(member)}
                                        className={"font-bold " + (member?.FirstName ? "hover:cursor-pointer text-primary" : "")}>{item.ContactName}</span> {toFrontDateTimeFromUTC(item.CreateUpdateDate)}
                            </div>
                            <div>
                                {item.Message}
                            </div>
                        </div>
                        <div className="border-t-2 border-primary-600 py-1 px-2">
                            <div>
                                <span title={"Comment"}
                                      onClick={() => this.toggleCreateCommentDialog(item.GroupMessageID)}
                                      className="hover:cursor-pointer hover:text-primary-600 mr-2">
                                    <AnnotationIcon
                                        className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top pt-0.5"
                                        aria-hidden="true"/>
                                </span>
                                {this.renderMessageButtons(item)}
                            </div>
                        </div>
                    </div>

                    <div className={"mt-1 mb-4"}>
                        {(groupComments?.length > 0) && (
                            <React.Fragment>
                                {!this.state.closedThreads[item.GroupMessageID] && (
                                    <MinusCircleIcon
                                        className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top"
                                        aria-hidden="true"
                                        onClick={() => this.handleToggleThread(item.GroupMessageID)}
                                    />
                                )}

                                {!!this.state.closedThreads[item.GroupMessageID] && (
                                    <PlusCircleIcon
                                        className="hover:cursor-pointer hover:text-primary-600 h-6 w-6 inline-block align-top"
                                        aria-hidden="true"
                                        onClick={() => this.handleToggleThread(item.GroupMessageID)}
                                    />
                                )}

                                {!this.state.closedThreads[item.GroupMessageID] && (
                                    groupComments
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            );
        });

        const isLoadingSecondResource = getProp(this.props, "secondResource.isLoading", false);
        const LogedInUserType = getProp(this.props, 'resource.data.LogedInUserType', '')

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                    <PageHeading
                        title="Groups"
                    >
                        {checkPerm(this.getResourceName(), DELETE_PERM) && (
                            <button
                                type="button"
                                className={"mb-2 sm:mb-0 sm:mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm " + (!this.state.groupID ? "btn-disabled" : "")}
                                onClick={() => !!this.state.groupID && this.handleToggleConfirmModal(this.state.groupID)}
                            >
                                Delete Current Group
                            </button>
                        )}

                        <button
                            type="button"
                            className={"mb-2 sm:mb-0 sm:mr-2 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm " + (!this.state.groupID ? "btn-disabled" : "")}
                            onClick={() => !!this.state.groupID && this.handleToggleConfirmLeaveModal(this.state.groupID)}
                        >
                            Leave Current Group
                        </button>

                        {checkPerm(this.getResourceName(), CREATE_PERM) && LogedInUserType === LOGGED_IN_USER_TYPE_ADMIN && (
                            <Button
                                type="primary"
                                onClick={this.toggleCreateGroupDialog}
                            >
                                Create Group
                            </Button>
                        )}
                    </PageHeading>


                    {isLoading && (
                        <div className="text-center py-48">
                            <Loader/>
                        </div>
                    )}

                    {!isLoading && (
                        <div className="mt-6 md:grid grid-cols-8">

                            <nav className="col-span-2 max-h-[calc(100vh-16rem)] overflow-auto" aria-label="Sidebar">
                                {groups}
                            </nav>

                            {!this.state.groupID && (
                                <div className="col-span-6 pl-4">
                                    <div className="">
                                        Please select a group to see the messages.
                                    </div>
                                </div>
                            )}

                            {this.state.groupID && (
                                <div className="col-span-6 pl-4 mt-5 md:mt-0 pt-5 md:pt-0 border-t border-gray-200">
                                    <>
                                        <div className="text-2xl ">
                                            {this.state.groupTitle}
                                        </div>
                                        {isLoadingSecondResource && (
                                            <div className="text-center groups-loader-absolute">
                                                <Loader/>
                                            </div>
                                        )}
                                        <div className="group-messages-container pt-8" ref={this.scrollRef}>
                                            <div className="pr-3">
                                                {groupMessages}
                                                {!isLoadingSecondResource && groupMessages.length < 1 && (
                                                    <div className="pt-6">
                                                        Currently there are no messages in this group. Be the first to
                                                        write something.
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        {!isLoadingSecondResource && (
                                            <div className="w-full flex justify-between">
                                                <input name="messageValue"
                                                       autoComplete="off"
                                                       type={"text"}
                                                       onKeyDown={(e) => this.onEnter(e)}
                                                       onChange={(e) => this.setState({messageValue: e.target.value})}
                                                       className="form-control"
                                                       placeholder={translate("text.send_message")}
                                                       value={this.state.messageValue}
                                                />
                                                <button
                                                    onClick={this.handleSendMessage}
                                                    className="m-2">
                                                    <svg
                                                        className="svg-inline--fa text-green-400 fa-paper-plane fa-w-16 w-12 h-12 py-2 mr-2"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="paper-plane"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        )}
                                    </>
                                </div>
                            )}
                        </div>
                    )}

                    <ModalSaveResource
                        title={"Create Group"}
                        className="max-w-lg"
                        visible={this.state.isCreateGroupModalOpen}
                        onClose={this.toggleCreateGroupDialog}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                params.id = this.getId();
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.Groups,
                                    piggyResource: this.getResourceName()
                                }));
                                this.toggleCreateGroupDialog(false)
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalSaveResource
                        title={"Create Comment"}
                        className="max-w-lg"
                        visible={this.state.isCreateCommentModalOpen}
                        onClose={this.toggleCreateCommentDialog}
                        fields={this.getFieldsComment()}
                        onSubmit={(params) => {
                            if (params) {
                                params.ParentMessageID = this.state.groupMessageID;
                                params.id = this.state.groupID;
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getSecondQuery(),
                                    params: params,
                                    resource: Resources.GroupsMessages,
                                    piggyResource: Resources.GroupsMessages,
                                    notificationMessage: "Comment posted"
                                }));
                                this.toggleCreateCommentDialog(false)
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalSaveResource
                        title={"Report Post"}
                        className="max-w-lg"
                        visible={this.state.isCreateReportModalOpen}
                        onClose={this.toggleCreateReportDialog}
                        fields={this.getFieldsComment()}
                        onSubmit={(params) => {
                            if (params) {
                                params.GroupMessageID = this.state.groupMessageID;
                                params.id = this.state.groupID;
                                this.props.dispatch(createSecondResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getSecondQuery(),
                                    params: params,
                                    resource: Resources.GroupsMessagesReport,
                                    piggyResource: Resources.GroupsMessages,
                                    notificationMessage: "Report sent to the admin team!"
                                }));
                                this.toggleCreateReportDialog(false)
                            }
                        }}
                        translate={this.props.translate}
                    />

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete group " + this.state.groupTitle + "?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    GroupID: this.state.groupID
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.setState({
                                groupID: null,
                                groupTitle: null
                            }, () => {
                                this.handleToggleConfirmModal(false)
                            })
                        }}
                        visible={this.state.confirmModalOpen}
                    />

                    <ModalConfirm
                        title={"Confirm leave"}
                        text={"Are you sure you want to leave group " + this.state.groupTitle + "?"}
                        onClose={this.handleToggleConfirmLeaveModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    GroupID: this.state.groupID
                                }, this.getQuery()),
                                resource: Resources.GroupsLeave,
                                piggyResource: this.getResourceName()
                            }));
                            this.setState({
                                groupID: null,
                                groupTitle: null
                            }, () => {
                                this.handleToggleConfirmLeaveModal(false)
                            })
                        }}
                        visible={this.state.confirmLeaveModalOpen}
                    />

                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete message '" + this.state.selectedItem?.Message + "'?"}
                        onClose={this.handleToggleMessageDeleteModal}
                        onConfirm={() => {
                            this.handleRemoveMessage(this.state.selectedItem?.GroupMessageID);
                            this.handleToggleMessageDeleteModal(false);
                        }}
                        visible={this.state.confirmMessageModalOpen}
                    />


                    <DialogDefault
                        title={"Disclaimer"}
                        visible={this.state.discModalOpen}
                        widthClass="max-w-xl"
                        onClose={this.handleToggleDiscModal}
                        translate={translate}
                    >
                        <div className="sm:items-start">
                            <p className="mb-4 mt-2">1. Users agree not to use any obscene or offensive language on KP
                                360 any material that is defamatory, abusive, or hateful, constitutes junk mail or
                                unauthorized advertising, invades anyone's privacy, or encourages conduct that would
                                constitute a criminal offense, or otherwise violate any laws. You agree to use the KP
                                360 groups only for lawful purposes.</p>

                            <p className="mb-4">2. To maintain a “clean” discussion forum, KP 360 moderators may decide
                                to remove messages, material or parts thereof at their sole discretion if considered to
                                be offensive or contrary to forum rules.</p>

                            <p className="mb-4">3. Discussion material on the KP 360 website is for your personal use
                                only.</p>

                            <p className="mb-4">4. If you participate in a discussion, you are responsible for ensuring
                                that any material you post to the KP 360 Website groups does not violate or infringe
                                upon the copyright, patent, trademark or any other personal or proprietary rights of any
                                third party and is posted with the permission of the owner of such rights.</p>

                            <p className="mb-4">5. All copyright content on this website is owned by the relevant
                                copyright holders. The KP 360 website does not support the distribution of copyrighted
                                works and encourages users to seek copyright permission from the respective owners.</p>
                        </div>
                        <div className="mt-5 sm:mt-4 flex flex-row-reverse">
                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={() => this.handleToggleDiscModal(false)}
                            >
                                {translate("btn.close")}
                            </button>

                            <button
                                type="submit"
                                className="btn btn-primary mr-3 focus:ring-offset-inverse hover:cursor-pointer"
                                onClick={() => {
                                    this.joinGroup();
                                    this.handleToggleDiscModal(false);
                                }}
                            >
                                {translate("btn.confirm")}
                            </button>
                        </div>

                    </DialogDefault>

                    <DialogDefault
                        title={`Likes (${this.state.selectedLikes.length})`}
                        visible={this.state.isLikesModalOpen}
                        widthClass="max-w-xl"
                        onClose={() => this.handleToggleMessageLikesDialog()}
                        translate={translate}
                    >
                        <div className="sm:items-start">
                            <ul role="list"
                                className="-my-5 divide-y divide-gray-200 p-6 bg-popup">
                                {this.state.selectedLikes.map((person, index) => (
                                    <li key={index} className="py-4">
                                        <div className="flex items-center space-x-4">
                                            <div className="flex-shrink-0">
                                                {person.ImagePath ?
                                                    <img className="h-8 w-8 rounded-full"
                                                         src={Env.getApiUrl("api/" + this.getImageResourcePathForUser(person), {
                                                             token: LocalStorage.get('user')?.access_token,
                                                             id: this.getImagePersonID(person)
                                                         })}
                                                         alt={person.Email}
                                                    />
                                                    :
                                                    <svg
                                                        className="max-w-none h-8 w-8 rounded-full ring-2 ring-white text-gray-300"
                                                        fill="currentColor" viewBox="0 0 24 24">
                                                        <path
                                                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                                        ></path>
                                                    </svg>
                                                }
                                            </div>
                                            <div className="flex-1 min-w-0">
                                                <p className="text-sm font-medium text-gray-900 truncate">{person.Name}</p>
                                                <p className="text-sm text-gray-500 truncate">{person.Email}</p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mt-5 sm:mt-4 flex flex-row-reverse">
                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={() => this.handleToggleMessageLikesDialog()}
                            >
                                {translate("btn.close")}
                            </button>
                        </div>

                    </DialogDefault>
                </div>

                <MemberCardDialog
                    visible={this.state.viewModalOpen}
                    onClose={this.handleToggleViewModal}
                    translate={this.props.translate}
                    member={this.state.selectedItem}
                />

            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(Groups);
