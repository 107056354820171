import React, {Component} from "react";

export default class FieldPassword extends Component {

    render() {
        const addClass = this.props.className ? " " + this.props.className : "";
        const className = addClass + (this.props.errorMessage ? " is-invalid" : "");
        // + (this.props.errorMessage ? " form-error" : "");

        return (
            <React.Fragment>
                <div className="relative">
                    <input type={"password"}
                           onKeyDown={(e) => this.props.handleKeyDown ? this.props.handleKeyDown(e) : null}
                           onChange={(event) => {
                               this.props.onChange(this.props.name, event.target.value);
                           }}
                           name={this.props.name}
                           value={this.props.value}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           className={className}
                    />
                </div>

                {this.props.errorMessage && (
                    <p className="mt-2 text-sm text-red-600">
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </p>
                )}
            </React.Fragment>
        )
    }
}