import LocalStorage from "./localStorage";
import React from "react";
import FieldDate from "../components/field-date";
import FieldText from "../components/field-text";
import FieldTextarea from "../components/field-textarea";
import FieldCheckbox from "../components/field-checkbox";
import FieldSelectSearch from "../components/field-select-search";
import moment from "moment-timezone";
import FieldDropdownSelect from "../components/field-dropdown-select";
import axios from "axios";
import Env from "./env";
import {processResponse} from "../data/services/api-util";
import {Link} from "react-router-dom";
import Dropzone from "react-dropzone";
import {Editor} from '@tinymce/tinymce-react';
import MultiSelect from "../components/field-multi-select";
import ImageViewCell from "../components/image-view-cell";

export const READ_PERM = 1;
export const CREATE_PERM = 2;
export const UPDATE_PERM = 4;
export const DELETE_PERM = 8;

export const DEFAULT_SQL_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const LOGGED_IN_USER_TYPE_MEMBER = "Member"
export const LOGGED_IN_USER_TYPE_CLIENT = "Client"
export const LOGGED_IN_USER_TYPE_ADMIN = "Admin"

export const CALENDAR_EVENT_CREATED = 1;
export const CALENDAR_EVENT_ACCEPTED = 2;
export const CALENDAR_EVENT_DENIED = 3;
export const CALENDAR_EVENT_UPDATED = 4;
export const CALENDAR_EVENT_CANCELED = 5;

export const DEFAULT_CRUD_STATE = {
    offset: 0,
    limit: 10,
    sort: "ASC",
    sortBy: "",
    query: "",
    paginationPage: 1,
    archived: false
};

export const ROLE_CLIENT_ID = 2;

export function checkPerm(key, check) {
    const perm = parseInt(getProp(LocalStorage.get('user'), 'permissions.' + key, 0));
    return (perm & check) !== 0;
}

export function getProp(object, keys, defaultVal) {
    if (object === undefined || object === null) {
        return defaultVal;
    }
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return (object === undefined || object === null) ? defaultVal : object;
}

export function getCurrentTimeSeconds() {
    const d = new Date();
    return Math.round(d.getTime() / 1000);
}

export function numberWithCommas(n) {
    if (!isNaN(n) && (n != "") && (n !== null)) {
        return formatMoney(n, 2, ".", ",");
    }

    return n;
}

export function integerWithCommas(n) {
    return n;
}

export function numberWithCommasToBack(n) {
    if (n) {
        let parts = n.toString().split(".");
        return Number(parts[0].replace(/,/g, "") + (parts[1] ? "." + parts[1] : (n.toString().includes(".") ? "." : "")));
    }

    return n;
}

export function numberWithCommasInputChange(n) {
    if (n) {
        let original = n.toString().split(".");
        if (original[1] && original[1].length > 1) {
            return original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        } else {
            n = n.replace(/[^0-9.]/g, '');
            n = n.replace(/,/g, "");
            n = n.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return n;
        }
    }
}

export function timePickerValueToServerTime(time) {
    return moment(time, getUserTimeFormat()).format("HH:mm:ss");
}

export function isMilitaryTimeSet() {
    if (LocalStorage.get('user')?.Contact?.DateTimeFormat) {
        return LocalStorage.get('user')?.Contact.DateTimeFormat.slice(-1) !== "A"
    }
}

export function getUserTimeFormat() {
    if (isMilitaryTimeSet()) {
        return "HH:mm"
    } else {
        return "hh:mma"
    }
}


export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}

export function getLookup(name, key, value) {
    const lookup = LocalStorage.get('lookup', {});
    return lookup[name] && lookup[name].reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function getLookupWithFilter(name, key, value, filter) {
    const lookup = LocalStorage.get('lookup', {});
    return lookup[name] && lookup[name].filter((item) => filter(item)).reduce(
        (memo, item) => {
            memo[item[key]] = item[value];
            return memo;
        }, {})
}

export function toBackDateTime(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "No Data";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD HH:mm:ss") : "No Data");
}

export function toBackDate(dateEntered) {
    if (dateEntered === "1900-01-01 00:00:00.000" || dateEntered === "1900-01-01 00:00:00") {
        return "No Data";
    }
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("YYYY-MM-DD") : "No Data");
}

export function toFrontDate(dateEntered) {
    const DateFormat = LocalStorage.get('user')?.Contact?.DateTimeFormat.split(" ")[0] ?? "MM/DD/YYYY";
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format(DateFormat) : "No Data");
}

export function toFrontDateTime(dateEntered) {
    return (dateEntered ? moment(dateEntered, 'YYYY-MM-DD HH:mm:ss').format("MM/DD/YYYY h:mm a") : "No Data");
}

export function toFrontDateTimeFromUTC(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(LocalStorage.get('user')?.Contact?.Timezone ?? "UTC").format(LocalStorage.get('user')?.Contact?.DateTimeFormat) : "");
}

export function toBackDateTimeFromUTC(dateEntered) {
    return (dateEntered ? moment.utc(dateEntered, 'YYYY-MM-DD HH:mm:ss', 'UTC').tz(LocalStorage.get('user')?.Contact?.Timezone ?? "UTC").format('YYYY-MM-DD HH:mm:ss') : "");
}

export function fieldsToCells(fields, item, classNames = "border-secondary-200 border hover:bg-gray-100 p-3 border-b-0 h-12") {

    return Object.keys(fields).filter(
        it => !fields[it]?.metadata?.hideTable
    ).map((key, i) => {
        switch (key) {
            case 'ClientName':
                return (
                    <td className={classNames} key={i}><Link to={"/client/" + item.ClientID}>{item[key]}</Link></td>
                );
            case 'CandidateID':
                return (
                    <td className={classNames} key={i}><Link
                        to={"/contact/" + item.CandidateID}>{item['FirstName'] + " " + item['LastName']}</Link></td>
                );
            case 'ContactName':
                return (
                    <td className={classNames} key={i}><Link to={"/contact/" + item.CandidateID}>{item[key]}</Link></td>
                );
            case 'ContactID':
                return (
                    <td className={classNames}
                        key={i}>{item['FirstName'] ? (item['FirstName'] + " ") : "" + " " + item['LastName'] ? item['LastName'] : ""}</td>
                );
            case 'ClientContactID':
                return (
                    <td className={classNames} key={i}>{item.ClientContactName}</td>
                );
            case 'Roles':
                return (
                    <td className={classNames} key={i}>{item?.Roles?.map(it => it.role_name).join(",")}</td>
                );
            // Change when there is a real lookup
            case 'RoomID':
                return (
                    <td className={classNames} key={i}>{item.RoomID}</td>
                );
            default:
        }
        switch (fields[key].type) {
            case 'join':
                let joinValue = "";
                if (fields[key].name === "LocationJoin") {
                    joinValue = (item['City'] ? (item['City'] + (item['State'] ? ", " : "")) : "") + (item['State'] ? item['State'] : "");
                } else if (fields[key].name === "Recruiter") {
                    joinValue = item['FirstName'] + " " + item['LastName'];
                }
                return (
                    <td className={classNames} key={i}>{joinValue}</td>
                );
            case 'hidden':
                return null;
            case 'select':
                let value = "";
                if (fields[key].name === "UpdatedByContactID") {
                    value = item['FirstName'] + " " + item['LastName'];
                } else {
                    value = item[key.replace("ID", "")];
                }
                return (
                    <td className={classNames} key={i}>{value}</td>
                );
            case 'date':
                return (
                    <td className={classNames} key={i}>{toFrontDate(item[key])}</td>
                );
            case 'datetime':
                return (
                    <td className={classNames} key={i}>{toFrontDateTime(item[key])}</td>
                );
            case 'datetimezone':
                return (
                    <td className={classNames} key={i}>{toFrontDateTimeFromUTC(item[key])}</td>
                );
            case 'checkbox':
                return (
                    <td className={classNames} key={i}>
                        <span
                            className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full" + (item[key] ? " bg-primary-100 text-primary-800" : " bg-red-100 text-red-800")}>
                            {!!item[key] ? "Yes" : "No"}
                        </span>
                    </td>
                );
            case 'select-search':
                if (key === "CandidateID") {
                    return (
                        <td className={classNames} key={i}>{item['FirstName']} {item['LastName']}</td>
                    );
                }

                return (
                    <td className={classNames} key={i}>{item[key.replace("ID", "")]}</td>
                );
            case 'textarea':
                return (
                    // temp delete " table-td-textarea"
                    <td className={classNames} key={i} title={item[key]}>
                        {item[key]}
                    </td>
                );
            case 'image':
            case 'photo':
                return (
                    <ImageViewCell
                        classNames={classNames}
                        fields={fields}
                        item={item}
                        itemKey={key}
                        i={i}
                    />
                );
            case 'custom':
                return fields[key]?.metadata?.render(item);
            default:
                switch (fields[key].validate[0]) {
                    case 'float':
                    case 'float_or_empty':
                        return (
                            <td className={classNames} key={i}>{numberWithCommas(item[key])}</td>
                        )
                    case 'integer':
                    case 'integer_or_empty':
                        return (
                            <td className={classNames} key={i}>{integerWithCommas(item[key])}</td>
                        )
                    default:
                        break;
                }

                return (
                    <td className={classNames} key={i}>{item[key]}</td>
                );
        }
    })
}

export function fieldsToEmptyCells(fields) {
    const classNames = "px-4 py-2 whitespace-nowrap text-sm text-secondary-500";
    return Object.keys(fields).map((key, i) => {
        return (
            <td className={classNames} key={i}></td>
        )
    })
}

export function fieldsSearchToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy.reduce((memo, item) => {
        let field;
        if (item.type === 'custom') {
            field = selects[item.name];
        } else if (item.type === 'select-search') {
            field = (
                <FieldDropdownSelect
                    onChange={handleInputChange}
                    {...item}
                    className="w-64 inline-block mr-6 mb-3"
                    placeholder={translate("filter." + item.name)}
                    defaultOptions={true}
                    isClearable={true}
                    loadOptions={
                        (inputValue, callback) => {
                            axios.get(
                                Env.getApiUrl(selects[item.name].api, Object.assign(!!selects[item.name].query ? selects[item.name].query : {}, {query: inputValue})),
                                {
                                    headers: {
                                        'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                    }
                                }
                            )
                                .then((response) => {
                                    const result = processResponse(response);
                                    if (result && result.status === 0) {
                                        const list = result.data.list.map((it) => {
                                            return selects[item.name].searchMap(it);
                                        });
                                        callback(list);
                                    }
                                })
                                .catch((error) => {
                                });
                        }
                    }
                />
            );
        } else if (item.type === "select") {
            let values;
            if (!!selects[item.name]) {
                values = selects[item.name];
            } else {
                values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
            }

            field = (
                <FieldSelectSearch
                    placeholder={translate("filter." + item.name)}
                    className="w-64 inline-block mr-6 mb-3"
                    values={values}
                    all={true}
                    onChange={handleInputChange}
                    {...item}
                />
            );
        }

        memo.push(field);

        return memo;
    }, []);
}

export function fieldsToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {

    const labelClasses = "block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2";
    const fieldClasses = "h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md bg-inverse text-gray-700"

    return fieldsCpy
        .filter(
            it => !it?.metadata?.hideDialog && (!selects[it.name]?.visibleFilter || !selects[it.name].visibleFilter(fieldsCpy))
        )
        .reduce((memo, item, i) => {
            let classes = "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6";
            if (i === 0) {
                classes = "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5";
            }
            const req = (item.validate.includes("empty") || item.validate.includes("integer") || item.validate.includes("float") || item.validate.includes("email") || item.validate.includes("empty_select_search"));
            let field;
            if (item.type === 'custom') {
                field = (typeof selects[item.name] === "function") ? selects[item.name](fieldsCpy, item, handleInputChange) : selects[item.name];
            } else if (item.type === 'select-search') {
                field = (
                    <div
                        className={classes}
                    >
                        <label htmlFor={item.name} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <FieldDropdownSelect
                            onChange={handleInputChange}
                            {...item}
                            className="select-search min-h-9 w-56"
                            defaultOptions={true}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(selects[item.name].api, Object.assign(!
                                                !selects[item.name].query ? (
                                                (typeof selects[item.name].query === "function") ? selects[item.name].query(fieldsCpy) : selects[item.name].query)
                                            : {},
                                            {query: inputValue})
                                        ),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + LocalStorage.get('user').access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return selects[item.name].searchMap(it);
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                        });
                                }
                            }
                            {...item.props}
                            setKey={item.name}
                        />


                        {item?.metadata?.htmlAfter}
                    </div>
                );
            } else if (item.type === 'hidden') {
                field = null;
            } else if (item.type === 'readonly' || item.type === 'readonly-select-search') {
                field = (
                    <div key={item.name} className={classes}>
                        <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">{translate("field." + item.name)}</dt>
                        <dd className="mt-1 text-gray-900 text-sm font-medium">{item.value}</dd>
                    </div>
                );
            } else if (item.type === 'readonly-datetime') {
                field = (
                    <div key={item.name} className={classes}>
                        <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">{translate("field." + item.name)}</dt>
                        <dd className="mt-1 text-gray-900 text-sm font-medium">{toFrontDateTime(item.value)}</dd>
                    </div>
                );
            } else if (item.type === 'readonly-select') {
                field = (
                    <div key={item.name} className={classes}>
                        <dt className="block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2">{translate("field." + item.name)}</dt>
                        <dd className="mt-1 text-gray-900 text-sm font-medium">{selects[item.name][item.value]}</dd>
                    </div>
                );
            } else if (item.type === 'readonly-photo') {
                field = (
                    <div
                        key={translate("field." + item.name)}
                        className={classes}
                    >
                        <label className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <span className="mt-1 flex items-center col-span-2">
                        <label htmlFor={item.name}
                               className="cursor-pointer inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12 object-cover">
                            {!item?.metadata?.src(item) && (
                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                    ></path>
                                </svg>
                            )}

                            {!!item?.metadata?.src && (
                                <img
                                    onClick={() => !!item?.metadata?.onClick ? item.metadata.onClick(item) : null}
                                    className={item?.metadata["imageClass"]}
                                    src={item.metadata.src(item)} alt=""
                                />
                            )}
                        </label>
                    </span>

                        {/*<p className="mt-2 text-sm text-gray-500">Upload square image that is at least 50x50px.</p>*/}
                    </div>
                )
            } else if (item.type === 'tiny') {
                field =
                    (
                        <div
                            className={classes}>
                            <label htmlFor={item.key} className={labelClasses}>
                                {translate("field." + item.name)}{req ? "*" : ""}
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                {drawTinyField(item, handleInputChange, translate)}
                            </div>
                        </div>
                    );
            } else if (item.type === "textarea") {
                field = (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldTextarea
                                className="form-control h-28"
                                onChange={handleInputChange}
                                addClass={"form-control"}
                                {...item}
                                {...item.props}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === "checkbox") {
                field = (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="">
                            <FieldCheckbox onChange={handleInputChange} {...item} />
                        </div>
                    </div>
                );
            } else if (item.type === "select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
                }

                field = (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">

                            <FieldSelectSearch
                                className="select-search h-9 w-56"
                                values={values}
                                onChange={handleInputChange}
                                {...item}
                                {...item.props}
                            />
                        </div>
                    </div>
                );
            } else if ((item.type === "datetime") || (item.type === "datetimezone")) {
                field = (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldDate
                                className={fieldClasses}
                                onChange={handleInputChange}
                                showTimeSelect={true}

                                minDate={item?.metadata?.minDate}
                                minTime={item?.metadata?.minDate}

                                showTimeSelectOnly={item?.metadata?.showTimeSelectOnly}

                                {...item}
                                {...item.props}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === "date") {
                field = (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldDate
                                className={fieldClasses}
                                onChange={handleInputChange}
                                showTimeSelect={false}
                                {...item}
                                dateFormat={"MM/dd/yyyy"}
                            />
                        </div>
                    </div>
                );
            } else if (item.type === 'image') {
                let images = item?.value;
                const thumbs = (!!images && Array.isArray(images)) ? images.map(file => (
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div
                            key={i}
                            className={classes}>
                            <div className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                <img
                                    alt={"File Preview"}
                                    src={file.preview}
                                />
                            </div>
                        </div>
                    </div>
                )) : null;
                field = (<>
                    <div
                        className={classes}>
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <Dropzone
                            onDrop={(acceptedFiles) => {
                                handleInputChange(item.name, acceptedFiles.map(file => Object.assign(file, {
                                    preview: URL.createObjectURL(file)
                                })))
                            }}
                            multiple={false}
                            accept={'image/jpeg, image/png'}
                            onDragEnter={() => {

                            }}
                            onDragLeave={() => {

                            }}
                            onDropAccepted={() => {

                            }}
                        >
                            {({getRootProps, getInputProps}) => (
                                <div className="mt-1 sm:mt-0" {...getRootProps()}>
                                    <div
                                        className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label
                                                    htmlFor="file-upload"
                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                >
                                                    <span>Upload a file</span>
                                                    <input {...getInputProps()} />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">{"PNG or JPG up to 10MB"}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Dropzone>

                        {thumbs}
                    </div>
                </>)
            } else if (item.type === 'photo') {
                field = (
                    <div
                        key={translate("field." + item.name)}
                        className={classes}
                    >
                        <label className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>

                        <span className="mt-1 flex items-center col-span-2">
                        <label htmlFor={item.name}
                               className="cursor-pointer inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12 object-cover">
                            {!item?.value?.preview && !item?.metadata?.src(item) && (
                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                    <path
                                        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                                    ></path>
                                </svg>
                            )}

                            {!!item?.metadata?.src && !item?.value?.preview && (
                                <img className={item?.metadata["imageClass"]} src={item.metadata.src(item)} alt=""/>
                            )}

                            {!!item?.value?.preview && (
                                <img className={item?.metadata["imageClass"]} src={item.value.preview} alt=""/>
                            )}
                        </label>

                        <label
                            htmlFor={item.name}
                            className="cursor-pointer ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                        >
                            Change
                        </label>

                            {(!!item?.metadata?.src && !!item.value) && (
                                <span
                                    onClick={() => !!item?.metadata?.removePhoto ? item?.metadata.removePhoto(item) : handleInputChange(item.name, "")}
                                    className="cursor-pointer relative z-30 ml-5 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                Remove
                            </span>
                            )}

                            <input
                                id={item.name}
                                type="file"
                                accept="image/png, image/jpeg"
                                onChange={(e) => handleInputChange(item.name, Object.assign(e.target.files[0], {preview: URL.createObjectURL(e.target.files[0])}))}
                                className="hidden"
                            />
                    </span>

                        {/*<p className="mt-2 text-sm text-gray-500">Upload square image that is at least 50x50px.</p>*/}
                    </div>
                )
            } else if (item.type === 'multi-select') {
                let values;
                if (getProp(selects, "filters." + [item.name], false)) {
                    values = selects.filters[item.name](fieldsCpy);
                } else if (!!selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""), item.name, item.name.replace("ID", ""));
                }

                field = (
                    <div
                        className=" sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5">
                        <label htmlFor={item.key} className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <MultiSelect
                                className="select-search h-9"
                                values={values}
                                onChange={handleInputChange}
                                {...item}
                            />
                        </div>
                    </div>
                );
            } else {
                field = (
                    <div
                        className={classes}>
                        <label htmlFor={"text-" + item.key}
                               className={labelClasses}>
                            {translate("field." + item.name)}{req ? "*" : ""}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <FieldText
                                id={"text-" + item.key}
                                className={fieldClasses}
                                onChange={handleInputChange}
                                {...item}
                            />
                        </div>
                    </div>
                );
            }

            memo.push(field);

            return memo;
        }, []);
}

export function drawTinyField(item, handleInputChange) {
    /*
    file_picker_types: 'image',
    file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');



        input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {

                var id = 'blobid' + (new Date()).getTime();
                var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                var base64 = reader.result.split(',')[1];
                var blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), {title: file.name});
            };
            reader.readAsDataURL(file);
        };

        input.click();
     }
     */
    return (
        <Editor
            apiKey={"260itthuh2ep794xwtdj2pv11bivfhikitkg5id18z8v4bxu"}
            value={item.value}
            init={{
                height: 500,
                content_style: "",
                plugins: [
                    'advlist autolink lists link media image charmap print preview anchor image',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                    'undo redo | image | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
            }}
            onEditorChange={(content, editor) => {
                handleInputChange(item.name, content);
            }}
        />
    )
}

export function fillFieldsFromData(fieldTemplates, data) {
    const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];

    const unmodifiedFields = Object.assign({}, fieldTemplates);

    if (!data) {
        return fieldTemplates;
    }

    return Object.values(fieldTemplates).reduce((memo, item) => {
        if (item.validate.filter(value => formats.includes(value)).length > 0) {
            const val = data[item.name];
            if (!!val && (val.length > 0) && val.includes(",")) {
                item.value = val;
            } else {
                if (item.validate[0] == 'integer' || item.validate[0] == 'integer_or_empty') {
                    item.value = integerWithCommas(val);
                } else {
                    item.value = numberWithCommas(val);
                }
            }
        } else if (item.type === 'select-search') {
            item.value = {
                value: data[item.name],
                label: (!!item.value && !item.value.label) ? (item.value) : (data[item.name.replace("ID", "")])
            }
        } else if (item.type === 'readonly-select-search') {
            item.value = data[item.name.replace("ID", "")]
        } else if (item.type === 'textarea') {
            item.value = data[item.name] ? data[item.name] : ""; // return null was causing a bug. Null value was still displaying old value after cancel action
        } else {
            item.value = data[item.name] || unmodifiedFields[item.name].value;
        }

        memo[item.name] = item;

        return memo;
    }, {});
}

export function getUserLabel() {
    return getProp(LocalStorage.get('user'), 'Contact.FirstName') + ' ' + getProp(LocalStorage.get('user'), 'Contact.LastName');
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export function checkPasswordStrength(password) {

    // Regular Expressions.
    let regex = [];
    regex.push("[A-Z]"); // Uppercase Alphabet.
    regex.push("[a-z]"); // Lowercase Alphabet.
    regex.push("[0-9]"); // Digit.
    regex.push("[!@#$%^&*()\\-_=+{};:,<.>?~\"|']"); // Special Character.

    let passed = 0;

    // Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
            passed++;
        }
    }

    // Validate for length of Password.
    if (passed > 2 && password.length >= 8) {
        passed++;
    } else {
        passed--;
    }

    // Display status.
    let color = "";
    let strength = "";
    switch (passed) {
        case 0:
        case 1:
            strength = "Very weak";
            color = "red";
            break;
        case 2:
            strength = "Weak";
            color = "darkorange";
            break;
        case 3:
            strength = "Average";
            color = "darkorange";
            break;
        case 4:
            strength = "Strong";
            color = "green";
            break;
        case 5:
            strength = "Very Strong";
            color = "darkgreen";
            break;
    }

    return {
        passed: passed,
        strength: strength,
        color: color
    }
}

export function setDocumentTitle(pathname, translate) {
    if (pathname) {
        let pageTitle;
        const path = pathname.split("/");
        if (path[2] === 'info') {
            pageTitle = translate("page.title." + path[1] + ".info") + (path[3] ? " - " + path[3] : "");
        } else {
            pageTitle = translate("page.title." + path[1]);
        }

        if (document.title !== pageTitle) {
            document.title = pageTitle + " | KP-360";
        }
    }
}

export const scrollErrorIntoView = (fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (value.errorMessage) {
            const firstErrorInput = document.querySelector('input[name=' + key + ']');

            if (firstErrorInput) {
                firstErrorInput.parentElement.scrollIntoView({block: "center", behavior: "smooth"});
                return true;
            }
        }
    }

    return false;
}

export const getRandomID = (length = 10) => {
    let result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    return result.join('');
}
