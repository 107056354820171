import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {pushNotification} from "../actions/ui";
import {
    doneCreatePublicResource,
    doneGetPublicResource,
    doneUpdatePublicResource,
    errorCreatePublicResource,
    errorGetPublicResource,
    errorUpdatePublicResource
} from "../actions/publicResource";

export function* getResourceCall(action) {
    let user = null;

    let result = yield call(Api.getPublicResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetPublicResource(result.data));

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        yield put(errorGetPublicResource(result.data));
    }
}

export function* watchGetPublicResource() {
    yield takeLatest('GET_PUBLIC_RESOURCE', getResourceCall);
}

export function* updatePublicResourceCall(action) {
    let result;
    result = yield call(Api.updatePublicResource, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneUpdatePublicResource(result.data));

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;
        yield put(pushNotification(action));
        yield put(errorUpdatePublicResource(result.data));
    }
}

export function* watchUpdatePublicResource() {
    yield takeLatest('UPDATE_PUBLIC_RESOURCE', updatePublicResourceCall);
}

export function* createResourceCall(action) {
    const user = null
    let result;
    if (action.data.request === 'PUT') {
        result = yield call(Api.putResource, user, action.data.params, action.data.resource);
    } else {
        result = yield call(Api.createResource, user, action.data.params, action.data.resource);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetPublicResource(resPiggy.data));
                yield put(doneCreatePublicResource(result.data));
            }
        } else {
            yield put(doneCreatePublicResource(result.data));
        }

        if (action.data.notificationMessage) {
            yield put(pushNotification(action));
        }
    } else {
        action.data.error = true;
        yield put(pushNotification(action));
        yield put(errorCreatePublicResource(result.data));
    }
}

export function* watchCreatePublicResource() {
    yield takeLatest('CREATE_PUBLIC_RESOURCE', createResourceCall);
}
