import React, {Component} from 'react';

export default class PhotoViewerWrapper extends Component {
    constructor(props) {
        super(props);

        this.state = {
            originalWidth: 0,
            originalHeight: 0,
            imageLoaded: false,
        };
    }

    render() {
        return (
            <div className="photo-viewer-container" id="pg-photo-container">
                <img alt="" src={this.props.filePath}/>
            </div>
        );
    }
}