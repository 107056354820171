import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import PageHeading from "../../components/page-heading";
import FieldDate from "../../components/field-date";
import {DEFAULT_SQL_FORMAT, fillFieldsFromData, getLookup, getProp, ROLE_CLIENT_ID} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import SimpleTable from "../../components/simple-table";
import {createResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import FieldSelectSearch from "../../components/field-select-search";
import {connect} from "react-redux";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import moment from "moment";
import Env from "../../util/env";
import SignatureCanvas from "../../components/signature-canvas";
import DialogDefault from "../../components/dialog-default";

class CheckIn extends Component {

    constructor(props) {
        super(props);
        this.state = {

            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "Role",

            query: "",
            archived: false,

            fields: this.getFields('', ['Picture', 'Notes', 'InNotes']),
            search: {
                CheckInStatusID: new Field("CheckInStatusID", 1, [], false, 'select'),
                Date: new Field("Date", moment().format("YYYY-MM-DD"), [], false, 'date'),
            },

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            updateModalOpen: false,
            confirmModalOpen: false,
            createModalOpen: false
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: Resources.CheckIn
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleUpdateModal = (item = null) => {
        this.setState({
            selectedItem: item,
            updateModalOpen: !this.state.updateModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleView = (item = null) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            paginationPage: 1,
            offset: 0
        }, this.fetchData)
    }

    handleSearchChange = (name, value) => {
        this.setState({
            search: FieldsManager.updateField(this.state.search, name, value),
            paginationPage: 1,
            offset: 0
        }, () => this.fetchData());
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id ?? 0;
    }

    getPrimaryKey = () => {
        return "CheckInID";
    }

    getResourceName = () => {
        return Resources.CheckIn;
    }

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.search);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.search);
        } else {
            this.setState({search: fields});
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0,
            searchFields: JSON.stringify(this.getValidSearchFields())
        }
    }

    getFields = (item = null, excludeFields = [], readOnly = false) => {
        const fieldTemplates = {
            RoleID: new Field("RoleID", '', ['empty'], readOnly, 'select'),
            ChildID: new Field("ChildID", '', [], readOnly, 'select-search'),
            CareTeamID: new Field("CareTeamID", '', ['empty'], readOnly, 'select-search'),
            ScheduleLocationID: new Field("ScheduleLocationID", '', [], false, 'select-search'),
            SignInTime: new Field("SignInTime", '', [], false, "datetime"),
            DroppedOffByID: new Field("DroppedOffByID", '', [], false, 'select-search'),
            SignOutTime: new Field("SignOutTime", '', [], false, "datetime"),
            PickedUpByID: new Field("PickedUpByID", '', [item?.RoleID === ROLE_CLIENT_ID ? "empty_select_search" : ''], false, 'select-search'),
            Picture: new Field("Picture", '', [], false, "custom"),
            CheckInStatusID: new Field("CheckInStatusID", '', [], '', 'select'),
            Notes: new Field("Notes", '', [''], false, "custom"),
            InNotes: new Field('InNotes', '', [], false, 'custom'),
        };

        if (excludeFields) {
            excludeFields.forEach((it) => {
                delete fieldTemplates[it];
            });
        }

        let items = fillFieldsFromData(fieldTemplates, item);

        if (!!item && !!items.ChildID?.value) {
            items.ChildID.value.ClientID = item.ClientID;
        }
        return items;
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);
        const isLoading = getProp(this.props, "resource.isLoading", false);

        const showChildFilter = (fieldsCpy) => {
            const role = fieldsCpy.find((it) => {
                return it.name === "RoleID";
            });
            return role?.value != "2";
        };

        const metadata = {
            ChildID: {
                api: 'api/' + Resources.ClientChild,
                query: {
                    sort: "ASC",
                    sortBy: "ChildName"
                },

                searchMap: (item) => ({
                    value: item.ClientChildID,
                    label: item.ChildName,
                    ClientID: item.ClientID
                }),
                visibleFilter: showChildFilter
            },
            CareTeamID: {
                api: 'api/' + Resources.Members,
                query: {
                    sort: "ASC",
                    sortBy: "FirstName"
                },
                searchMap: (item) => ({
                    value: item.MemberID,
                    label: item.FirstName + " " + item.LastName
                })
            },
            ScheduleLocationID: {
                api: 'api/' + Resources.ScheduleLocations,
                query: {
                    sort: "ASC",
                    sortBy: "LocationName"
                },
                searchMap: (item) => ({
                    value: item.ScheduleLocationID,
                    label: item.LocationName
                })
            },
            DroppedOffByID: {
                api: 'api/' + Resources.ClientPickups,
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ChildID";
                    });
                    return {
                        ClientID: child?.value?.ClientID ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientPickupID,
                    label: item.FirstName + " " + item.LastName
                }),
                visibleFilter: showChildFilter
            },
            PickedUpByID: {
                api: 'api/' + Resources.ClientPickups,
                query: (fieldsCpy) => {
                    const child = fieldsCpy.find((it) => {
                        return it.name === "ChildID";
                    })
                    return {
                        ClientID: child?.value?.ClientID ?? -1
                    }
                },
                searchMap: (item) => ({
                    value: item.ClientPickupID,
                    label: item.FirstName + " " + item.LastName
                }),
                visibleFilter: showChildFilter
            },
            Picture: (fieldsCpy, item) => {
                const DroppedOffByID = fieldsCpy.find((it) => {
                    return it.name === "DroppedOffByID";
                })
                const PickedUpByID = fieldsCpy.find((it) => {
                    return it.name === "PickedUpByID";
                })
                return (
                    <>
                        {DroppedOffByID?.value?.value && (
                            <div

                                className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                                <img className="h-18 max-w-md object-cover " src={
                                    Env.getApiUrl('api/' + Resources.ClientPickupImage, {
                                        id: DroppedOffByID?.value?.value,
                                        token: LocalStorage.get('user').access_token
                                    })
                                } alt=""/>
                            </div>
                        )}
                        {PickedUpByID?.value?.value && (
                            <div

                                className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                                <img className="h-18 max-w-md object-cover" src={
                                    Env.getApiUrl('api/' + Resources.ClientPickupImage, {
                                        id: PickedUpByID?.value?.value,
                                        token: LocalStorage.get('user').access_token
                                    })
                                } alt=""/>
                            </div>
                        )}
                    </>
                )
            },
            Notes: (fieldsCpy, item, handleInputChange) => {
                return (
                    <div
                        className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                        <label className={"block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2"}>
                            {translate("field.signature")} {item.validate.includes("empty") ? "*" : ""}
                        </label>
                        <div>
                            <SignatureCanvas
                                name={"Notes"}
                                strokeStyle={'#333333'}
                                lineWidth={3}
                                width={330}
                                height={200}
                            />
                            {item.errorMessage && (
                                <div className="text-red-700 italic mt-1">Please fill out this field</div>
                            )}
                        </div>
                    </div>
                );
            },
            InNotes: (fieldsCpy, item, handleInputChange) => {
                return (
                    <div
                        className={"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-secondary-200 sm:pt-5 mt-6"}>
                        <label className={"block text-sm font-medium text-grey-700 sm:mt-px sm:pt-2"}>
                            {translate("field.signature")} {item.validate.includes("empty") ? "*" : ""}
                        </label>
                        <div>
                            <SignatureCanvas
                                name={"InNotes"}
                                strokeStyle={'#333333'}
                                lineWidth={3}
                                width={330}
                                height={200}
                            />
                            {item.errorMessage && (
                                <div className="text-red-700 italic mt-1">Please fill out this field</div>
                            )}
                        </div>
                    </div>
                );
            }
        };

        return (
            <React.Fragment>
                <LayoutDashboard {...this.props}>
                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                        <PageHeading
                            title={translate("page.title.checkin")}
                        >
                        </PageHeading>

                        <div className="mt-6 grid grid-cols-12 gap-6">
                            <div className="col-span-12 lg:col-span-3 sm:col-span-6">
                                <label htmlFor="query" className="block text-sm font-medium text-gray-700">
                                    Search
                                </label>

                                <div className="col-span-12 lg:col-span-3 sm:col-span-6">
                                    <FieldSelectSearch
                                        placeholder={""}
                                        className="w-44 sm:mr-6 mb-3"
                                        all={true}
                                        isClearable={this.state.search.CheckInStatusID.value}
                                        values={getLookup("CheckInStatus", "CheckInStatusID", "CheckInStatus")}
                                        onChange={this.handleSearchChange}
                                        {...this.state.search.CheckInStatusID}
                                    />
                                </div>
                            </div>

                            <div className="col-span-12 lg:col-span-3 sm:col-span-6 relative">
                                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                                    Date
                                </label>

                                <FieldDate
                                    dateFormat={"MM/dd/yyyy"}
                                    isClearable={this.state.search.Date.value}
                                    onChange={this.handleSearchChange}
                                    {...this.state.search.Date}
                                />
                            </div>

                            <div className="col-span-12 lg:col-span-6 sm:col-span-6 mt-5 text-right">
                                {!this.props.disableCreate && (
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse w-40 justify-center"
                                    >
                                        {translate("btn.check_in")}
                                    </button>
                                )}
                            </div>

                        </div>

                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onCustomAction={(it) => {
                                if (it.CheckInStatusID === 1) {
                                    return (
                                        <>
                                            <button
                                                onClick={() => this.handleToggleUpdateModal(it)}
                                                type="button"
                                                className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                            >
                                                Update
                                            </button>

                                            <button
                                                onClick={() => this.handleToggleEditModel(it)}
                                                type="button"
                                                className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                            >
                                                Check out
                                            </button>
                                        </>
                                    )
                                }

                                if (it.CheckInStatusID === 2) {
                                    return (
                                        <>
                                            <button
                                                onClick={() => this.handleToggleView(it)}
                                                type="button"
                                                className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                            >
                                                View
                                            </button>
                                        </>
                                    )
                                }
                            }}
                        />
                    </div>

                    <ModalSaveResource
                        title={"Check In"}
                        className="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields('', ['SignInTime', 'SignOutTime', 'PickedUpByID', 'CheckInStatusID', 'Notes'])}
                        onSubmit={(params) => {
                            if (params) {
                                const image = window.document.getElementById("InNotes")?.toDataURL();
                                params.id = this.getId();
                                params.InNotes = image;
                                params.SignInTime = moment().local().format(DEFAULT_SQL_FORMAT);
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.CheckIn,
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        handleInputChange={(fields, name, value) => {
                            if (name === "ChildID") {
                                fields.DroppedOffByID.value = null;
                                fields.DroppedOffByID.props.key = value.value;
                            }
                            if (name === "RoleID") {
                                fields.ChildID.validate = (value == ROLE_CLIENT_ID) ? ['empty_select_search'] : [];
                                fields.ChildID.props.key = value;
                                if ((value != ROLE_CLIENT_ID)) {
                                    fields.ChildID.value = null;
                                }
                            }
                            return FieldsManager.updateField(fields, name, value);
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        disableOverflow
                    />

                    <ModalSaveResource
                        title={"Update Entry"}
                        className="max-w-lg"
                        visible={this.state.updateModalOpen}
                        onClose={this.handleToggleUpdateModal}
                        fields={this.getFields(this.state.selectedItem, ['SignInTime', 'SignOutTime', 'PickedUpByID', 'CheckInStatusID', 'Notes'])}
                        onSubmit={(params) => {
                            if (params) {
                                const image = window.document.getElementById("InNotes")?.toDataURL();
                                params.id = this.getId();
                                params.InNotes = image;
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: Resources.CheckInUpdate,
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleUpdateModal(null)
                            }
                        }}
                        handleInputChange={(fields, name, value) => {
                            if (name === "ChildID") {
                                fields.DroppedOffByID.value = null;
                                fields.DroppedOffByID.props.key = value.value;
                            }
                            if (name === "RoleID") {
                                fields.ChildID.validate = (value == ROLE_CLIENT_ID) ? ['empty_select_search'] : [];
                                fields.ChildID.props.key = value;
                                if ((value != ROLE_CLIENT_ID)) {
                                    fields.ChildID.value = null;
                                }
                            }
                            return FieldsManager.updateField(fields, name, value);
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        closeButtonName={"Cancel"}
                        submitButtonName={"Update"}
                        disableOverflow
                    />

                    <ModalSaveResource
                        title={"Check Out"}
                        className="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFields(this.state.selectedItem, ['SignInTime', 'SignOutTime', 'DroppedOffByID', 'CheckInStatusID', 'RoomID', 'ScheduleLocationID', 'InNotes'], true)}
                        onSubmit={(params) => {
                            if (params) {
                                const image = window.document.getElementById("Notes")?.toDataURL();
                                params.id = this.getId();
                                params.Notes = image;
                                params.SignOutTime = moment().local().format(DEFAULT_SQL_FORMAT)
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                        closeButtonName={"Cancel"}
                        submitButtonName={"Check Out"}
                        disableOverflow
                    />

                    <DialogDefault
                        title={"Signature"}
                        visible={this.state.viewModalOpen}
                        className="max-w-lg"
                        onClose={this.handleToggleView}
                        translate={translate}
                        disableOverflow
                    >
                        <div className="">
                            <h2 className={"text-lg font-bold mb-2"}>{this.state.selectedItem?.CareTeam}</h2>
                            <h4>Check In Signature</h4>
                            <img
                                style={{width: 330, height: 200}}
                                alt={this.state.selectedItem?.CareTeam}
                                src={this.state.selectedItem?.InNotes}
                            />
                            <h4>Check Out Signature</h4>
                            <img
                                style={{width: 330, height: 200}}
                                alt={this.state.selectedItem?.CareTeam}
                                src={this.state.selectedItem?.Notes}
                            />
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button type="button"
                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                    onClick={() => this.handleToggleView(false)}
                            >
                                {translate("btn.close")}
                            </button>
                        </div>
                    </DialogDefault>
                </LayoutDashboard>
            </React.Fragment>
        )
    }

}

export default connect(state => state)(CheckIn);
