import React, {useEffect, useState} from 'react'
import {EyeIcon, PencilIcon, RefreshIcon} from "@heroicons/react/solid";
import Pagination from "../pagination"
import Loader from "../loader";
import {ArchiveIcon} from "@heroicons/react/outline";
import Table from "./table"
import TableMobile from "./table-mobile"

export default function SimpleTable({
                                        fields,
                                        data,
                                        onRowClick,
                                        onView,
                                        onEdit,
                                        onEditLabel,
                                        onDelete,
                                        onDeleteLabel,
                                        onRestore,
                                        customActions,
                                        onCustomAction,
                                        onOffsetChange,
                                        onSortChange,
                                        sort,
                                        sortBy,
                                        translate,
                                        limit,
                                        offset,
                                        count,
                                        paginationPage,
                                        isLoading,
                                        addClassToTableWrapper
                                    }) {

    const [isMobileView, setIsMobileView] = useState(undefined);

    useEffect(() => {
        const smallScreenBreakpoint = 768;

        const watchWindowSize = () => {

            if (window.innerWidth >= smallScreenBreakpoint) {
                if (isMobileView) {
                    setIsMobileView(false);
                }
            }

            if (window.innerWidth < smallScreenBreakpoint) {
                if (!isMobileView) {
                    setIsMobileView(true);
                }
            }
        }

        window.addEventListener('resize', watchWindowSize);

        if (isMobileView === undefined) {
            watchWindowSize();
        }

        return () => window.removeEventListener('resize', watchWindowSize);
    }, [isMobileView])

    const haveActions = (!!onEdit || !!onView || !!onDelete || !!customActions || !!onCustomAction);

    const getActions = (it) => {
        return (
            <td className={`w-10 px-4 py-2 whitespace-nowrap text-sm text-white ${isMobileView ? "grid auto-rows-auto gap-2" : ""}`}>
                {!!onCustomAction && onCustomAction(it)}
                {!!customActions && customActions.length && (
                    customActions.filter(button => !button.hidden)).map(button => (
                    <button
                        title="View"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            button.action(it);
                        }}
                        className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                    >
                        {button.icon}
                        <span className="hidden sm:inline">{button.label}</span>
                    </button>
                ))}

                {onView && (
                    <button
                        title="View"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onView(it);
                        }}
                        className="inline-flex mx-1 items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                    >
                        <EyeIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                 aria-hidden="true"/>
                        <span className="hidden sm:inline">View</span>
                    </button>
                )}
                {onEdit && !it.editHidden && (
                    <button
                        title={onEditLabel ?? translate("btn.edit")}
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onEdit(it);
                        }}
                        className="mx-1 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                    >
                        <PencilIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                    aria-hidden="true"/>

                        <span className="hidden sm:inline">
                            {onEditLabel ?? translate("btn.edit")}
                        </span>
                    </button>
                )}

                {onDelete && !it.ArchivedDate && !it.deleteHidden && (
                    <button
                        title={onDeleteLabel ?? translate("btn.delete")} // fall-backs to default label
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onDelete(it);
                        }}

                        className="mx-1 inline-flex items-center px-4 py-2 border border-red-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-inverse bg-red hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-red-500"
                    >
                        <ArchiveIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-gray-400"
                                     aria-hidden="true"/>

                        <span className="hidden sm:inline">
                            {onDeleteLabel ?? translate("btn.delete")}
                        </span>
                    </button>
                )}

                {onRestore && !!it.ArchivedDate && (
                    <button
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            onRestore(it);
                        }}
                        className="mx-1 inline-flex items-center px-4 py-2 border border-primary-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
                    >
                        <RefreshIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        <span className="hidden sm:inline">
                            Restore
                        </span>
                    </button>
                )}
            </td>
        )
    };

    return (
        <div className="mx-auto">
            <div className="flex flex-col">
                <div
                    className={"px-1 align-middle min-w-full overflow-x-auto md:shadow overflow-hidden md:rounded-lg relative " + addClassToTableWrapper}>

                    {!isMobileView && (
                        <Table
                            onSortChange={onSortChange}
                            sort={sort}
                            data={data}
                            sortBy={sortBy}
                            haveActions={haveActions}
                            onView={onView}
                            onEdit={onEdit}
                            onRowClick={onRowClick}
                            getActions={getActions}
                            isLoading={isLoading}
                            fields={fields}
                            translate={translate}
                        />
                    )}

                    {!!isMobileView && (
                        <TableMobile
                            fields={fields}
                            data={data}
                            haveActions={haveActions}
                            getActions={getActions}
                            isLoading={isLoading}
                            translate={translate}
                        />
                    )}

                    {isLoading && (
                        <div className={"absolute inset-0 text-center mt-4 mb-4 flex items-center justify-center"}>
                            <Loader/>
                        </div>
                    )}

                    {/* Pagination */}
                    {!!paginationPage && (
                        <Pagination
                            className="md:sticky left-0 rounded-md md:rounded-none"
                            limit={limit}
                            offset={offset}
                            count={count}
                            paginationPage={paginationPage}
                            updateOffset={onOffsetChange}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}
