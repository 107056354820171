import React, {Component, Fragment} from 'react'
import {checkPerm, classNames, getProp, READ_PERM, setDocumentTitle} from "../../util/util";
import LocalStorage from "../../util/localStorage";
import {logoutClear} from "../../data/actions/user";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {ChatIcon, MenuIcon, XIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Logout from "../logout";
import Notification from "../notification";
import {hideNotification} from "../../data/actions/ui";
import {showChatPanel} from "../../package/chat/actions/messaging";
import ReactImageFallback from "../image-fallback";
import Env from "../../util/env";
import Resources from "../../data/services/resources";

export default class LayoutDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            ThemeDark: false,
            sidebarIsCollapsed: true,
            sidebarAnimation: false,
            page: ""
        }
    }

    componentDidMount() {
        // False parameter prevents collapsed sidebar from 'jumping' when changing page
        this.changeTheme(LocalStorage.get("IS_THEME_DARK"), false);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps?.user?.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== window?.location?.pathname) {
            this.setState({
                page: window?.location?.pathname
            }, () => setDocumentTitle(window?.location?.pathname, this.props.translate));
        }
    }

    changeTheme = (isDark, sidebarAnimation = true) => {
        this.setState({
            ThemeDark: isDark,
            sidebarAnimation: sidebarAnimation
        }, () => {
            if (this.state.ThemeDark) {
                document.documentElement.classList.add('theme-dark');
                document.documentElement.classList.remove('theme-starter');
                LocalStorage.set('IS_THEME_DARK', true);
            } else {
                document.documentElement.classList.remove('theme-dark');
                document.documentElement.classList.add('theme-starter');
                LocalStorage.set('IS_THEME_DARK', false);
            }
        })
    }

    render() {
        const user = {
            name: LocalStorage.get('user')?.Contact?.FirstName + " " + LocalStorage.get('user')?.Contact?.LastName,
            email: LocalStorage.get('user')?.Contact?.Email,
        }

        const navigation = [
            {
                name: 'Calendar',
                href: '/dashboard',
                active: ("/dashboard" === window.location.pathname),
                perm: checkPerm(Resources.ScheduleItem, READ_PERM)
            },
            {
                name: 'Groups',
                href: '/groups',
                active: ("/groups" === window.location.pathname),
                perm: checkPerm(Resources.Groups, READ_PERM)
            },
            {
                name: 'Resources',
                href: '/resources',
                active: ("/resources" === window.location.pathname),
                perm: checkPerm(Resources.Documentation, READ_PERM)
            },
            {
                name: 'Profile',
                href: '/client-profile',
                active: ("/client-profile" === window.location.pathname),
                perm: checkPerm(Resources.ClientProfileInfo, READ_PERM)
            },
            {
                name: 'Org Chart',
                href: '/org-chart',
                active: ("/org-chart" === window.location.pathname),
                perm: checkPerm(Resources.Members, READ_PERM)
            },
            {
                name: this.props.translate("menu.checkin"),
                href: '/checkin',
                active: ("/checkin" === window.location.pathname),
                perm: checkPerm(Resources.CheckIn, READ_PERM)
            },
            {
                name: 'Reports',
                href: '/reports',
                active: ("/reports" === window.location.pathname),
                perm: checkPerm(Resources.UserReports, READ_PERM)
            },
            {
                name: 'Profile',
                href: '/member-profile',
                active: ("/member-profile" === window.location.pathname),
                perm: checkPerm(Resources.MemberProfile, READ_PERM)
            }
        ].filter((it) => !!it.perm);

        const userNavigation = [
            checkPerm(Resources.Users, READ_PERM) ? {name: 'Users', href: '/users'} : null,
            checkPerm(Resources.ScheduleLocations, READ_PERM) ? {name: 'Locations', href: '/locations'} : null,
            checkPerm(Resources.ScheduleLogs, READ_PERM) ? {name: 'Schedule Logs', href: '/schedule-logs'} : null,
            checkPerm(Resources.Categories, READ_PERM) ? {name: 'Categories', href: '/categories'} : null,
            {name: 'Logged devices', href: '/devices'},
            {name: 'Settings', href: '/settings'},
            {name: 'Sign out', href: '/logout'}
        ].filter((it) => !!it);

        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg = it.message;
            switch (it.message) {
                case 'CREATE_RESOURCE':
                case 'CREATE_DIALOG_RESOURCE':
                case "CREATE_RESOURCE_WITH_IMAGE":
                case "CREATE_DIALOG_RESOURCE_WITH_IMAGE":
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                case 'UPDATE_DIALOG_RESOURCE':
                case 'UPDATE_SECOND_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                case 'DELETE_DIALOG_RESOURCE':
                    msg = "Entry deleted";
                    break;
                default:
                    if (!msg) {
                        msg = "Operation complete";
                    }
                    break;
            }
            return (
                <Notification
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    title={it.title ?? "Notification"}
                    message={msg}
                />
            )
        });

        let newMessages = false; //getProp(this.props, 'ui.unreadCount', false)

        return (
            <>
                <Disclosure as="header" className="bg-white shadow relative">
                    {({open}) => (
                        <>
                            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
                                <div className="relative h-16 flex justify-between">
                                    <div className="relative  z-30 px-2 flex lg:px-0">
                                        <div className="flex-shrink-0 flex items-center">
                                            <Link to="/dashboard">
                                                <img
                                                    className="block h-12 w-auto"
                                                    src="/images/logos/kp-logo.png?v=1.0.0"
                                                    alt="Workflow"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                    <div
                                        className="relative z-0 flex-1 px-2 flex items-center justify-center sm:absolute sm:inset-0">
                                        <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.active ? 'border-primary-600' : 'border-transparent',
                                                        'py-2 h-16 px-3  text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                                                    )}
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                    <div className="relative  z-30 flex items-center lg:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button
                                            className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                                            <span className="sr-only">Open menu</span>
                                            {open ? (
                                                <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                            ) : (
                                                <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="hidden lg:relative lg: z-30 lg:ml-4 lg:flex lg:items-center">

                                        <button
                                            onClick={() => this.props.dispatch(showChatPanel())}
                                            className={"bg-secondary-100 p-1 rounded-full text-secondary-400 hover:text-secondary-500 "
                                            + (newMessages ? "outline-none ring-2 ring-primary-500 ring-offset-2 ring-offset-secondary-100" : "")}>
                                            <span className="sr-only">View messages</span>
                                            <ChatIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>

                                        {/* Profile dropdown */}
                                        <Menu as="div" className="flex-shrink-0 relative ml-4">
                                            {({open}) => (
                                                <>
                                                    <div>
                                                        <Menu.Button
                                                            className="bg-white rounded-full flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kp-blue-light overflow-hidden">
                                                            <span className="sr-only">Open user menu</span>
                                                            <div
                                                                className="w-10 h-10 bg-blue-400 rounded-full flex justify-center items-center select-none">

                                                                <ReactImageFallback
                                                                    src={Env.getApiUrl("/api/user/image?token=" + LocalStorage.get('user')?.access_token)}
                                                                    fallbackImage={(<div
                                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                                    initialImage={(<div
                                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                                    alt={user?.name}
                                                                    className="object-cover h-10 w-full"
                                                                />
                                                            </div>
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items
                                                            static
                                                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                                                        >
                                                            {userNavigation.map((item) => (
                                                                <Menu.Item key={item.name}>
                                                                    {({active}) => {
                                                                        if (item.href === '/logout') {
                                                                            return (
                                                                                <Logout
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block py-2 px-4 text-sm text-gray-700'
                                                                                    )}
                                                                                    history={this.props.history}
                                                                                    translate={this.props.translate}
                                                                                    dispatch={this.props.dispatch}
                                                                                />
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <Link
                                                                                    to={item.href}
                                                                                    className={classNames(
                                                                                        active ? 'bg-gray-100' : '',
                                                                                        'block py-2 px-4 text-sm text-gray-700'
                                                                                    )}
                                                                                >
                                                                                    {item.name}
                                                                                </Link>
                                                                            )
                                                                        }
                                                                    }}
                                                                </Menu.Item>
                                                            ))}
                                                        </Menu.Items>
                                                    </Transition>
                                                </>
                                            )}
                                        </Menu>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel as="nav" className="lg:hidden" aria-label="Global">
                                <div className="pt-2 pb-3 px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="border-t border-gray-200 pt-4 pb-3">
                                    <div className="px-4 flex items-center">
                                        <div className="flex-shrink-0">
                                            <div
                                                className="w-10 h-10 bg-blue-400 rounded-full flex justify-center items-center select-none overflow-hidden">
                                                <ReactImageFallback
                                                    src={Env.getApiUrl("/api/user/image?token=" + LocalStorage.get('user')?.access_token)}
                                                    fallbackImage={(<div
                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                    initialImage={(<div
                                                        className="text-inverse font-black">{user?.name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>)}
                                                    alt={user?.name}
                                                    className="object-cover h-10 w-full"
                                                />
                                            </div>
                                        </div>

                                        <div className="ml-3">
                                            <div className="text-base font-medium text-gray-800">{user.name}</div>
                                            <div className="text-sm font-medium text-gray-500">{user.email}</div>
                                        </div>

                                        <button
                                            onClick={() => this.props.dispatch(showChatPanel())}
                                            className="ml-auto bg-secondary-100 p-1 rounded-full text-secondary-400 hover:text-secondary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-secondary-100">
                                            <span className="sr-only">View messages</span>
                                            <ChatIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                    <div className="mt-3 px-2 space-y-1">
                                        {userNavigation.map((item) => {
                                                if (item.href === '/logout') {
                                                    return (
                                                        <Logout
                                                            className={classNames('block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900')}
                                                            history={this.props.history}
                                                            translate={this.props.translate}
                                                            dispatch={this.props.dispatch}/>
                                                    )
                                                } else {
                                                    return (
                                                        <Link
                                                            key={item.name}
                                                            to={item.href}
                                                            className="block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )
                                                }
                                            }
                                        )}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>

                {!this.props.noDefaultLayout && (
                    <main className="max-w-7xl px-6 py-8 mx-auto lg:px-8">
                        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                            {this.props.children}
                        </div>
                    </main>
                )}

                {!!this.props.noDefaultLayout && (
                    this.props.children
                )}

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </>
        )
    }
}
