import React, {Component} from 'react';

import Loading from '../loading';

export default class extends Component {
    componentDidMount() {
        const jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', this.props.filePath, true);
        jsonFile.send();
        jsonFile.responseType = 'arraybuffer';
        jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {

            }
        };
    }

    render() {
        return (
            <div id="docx">
                <Loading/>
            </div>);
    }
}
