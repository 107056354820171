import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateResource,
    doneCreateResourceWithImage,
    doneDeleteResource,
    doneGetResource,
    doneGetResourceDetails,
    doneUpdateResource,
    doneUploadDocument,
    errorCreateResource,
    errorCreateResourceWithImage,
    errorDeleteResource,
    errorGetResource,
    errorGetResourceDetails,
    errorUpdateResource,
    errorUploadDocument
} from "../actions/resource";
import {
    doneCreateSecondResource,
    doneDeleteSecondResource,
    doneGetSecondResource,
    doneUpdateSecondResource,
    errorCreateSecondResource,
    errorDeleteSecondResource,
    errorGetSecondResource,
    errorUpdateSecondResource
} from "../actions/secondResource";
import {pushNotification} from "../actions/ui";

export function* getAuthResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getAuthResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetAuthResource() {
    yield takeLatest('GET_AUTH_RESOURCE', getAuthResourceCall);
}

export function* getResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResource(result.data));
    } else {
        yield put(errorGetResource(result.data));
    }
}

export function* watchGetResource() {
    yield takeLatest('GET_RESOURCE', getResourceCall);
}

export function* getSecondResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetSecondResource(result.data));
    } else {
        yield put(errorGetSecondResource(result.data));
    }
}

export function* watchGetSecondResource() {
    yield takeLatest('GET_SECOND_RESOURCE', getSecondResourceCall);
}

export function* getResourceDetailsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        yield put(doneGetResourceDetails(result.data));
    } else {
        yield put(errorGetResourceDetails(result.data));
    }
}

export function* watchGetResourceDetails() {
    yield takeLatest('GET_RESOURCE_DETAILS', getResourceDetailsCall);
}

export function* createResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else if (action.data.piggyResource) {
            let resPiggy;
            if (!!action.data.params.authApi) {
                resPiggy = yield call(Api.getAuthResource, user, action.data.query, action.data.piggyResource);
            } else {
                resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            }

            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResource(result.data));
            }
        } else {
            yield put(doneCreateResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorCreateResource(result.data));
    }
}

export function* watchCreateResource() {
    yield takeLatest('CREATE_RESOURCE', createResourceCall);
}

export function* createSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {

        if (action.data.file) {
            yield call(Api.uploadFile, user, result.data.id, action.data.file, 0, action.data.fileResource, action.data.fileDescription);
        }

        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateSecondResource(result.data));
            }
        } else {
            yield put(doneCreateSecondResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorCreateSecondResource(result.data));
    }
}

export function* watchCreateSecondResource() {
    yield takeLatest('CREATE_SECOND_RESOURCE', createSecondResourceCall);
}

export function* updateResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.file) {
        yield call(Api.uploadFile, user, action.data.params.id, action.data.file, 0, action.data.fileResource, action.data.fileDescription);
    }

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else if (action.data.piggyResource) {
            let resPiggy;
            if (!!action.data.params.authApi) {
                resPiggy = yield call(Api.getAuthResource, user, action.data.query, action.data.piggyResource);
            } else {
                resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneUpdateResource(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorUpdateResource(result.data));
    }
}

export function* watchUpdateResource() {
    yield takeLatest('UPDATE_RESOURCE', updateResourceCall);
}

export function* updateSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (action.data.file) {
        yield call(Api.uploadFile, user, action.data.params.id, action.data.file, 0, action.data.fileResource, action.data.fileDescription);
    }

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneUpdateSecondResource(result.data));
            }
        } else {
            yield put(doneUpdateSecondResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorUpdateSecondResource(result.data));
    }
}

export function* watchUpdateSecondResource() {
    yield takeLatest('UPDATE_SECOND_RESOURCE', updateSecondResourceCall);
}

export function* createResourceWithImageCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResourceWithImage, user, action.data.params, action.data.resource, action.data.image);

    if (result && result.status === 0) {
        if (action.data.secondPiggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.secondPiggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneCreateResourceWithImage(result.data));
            }
        } else if (action.data.piggyResource) {
            let resPiggy;
            if (!!action.data.params.authApi) {
                resPiggy = yield call(Api.getAuthResource, user, action.data.query, action.data.piggyResource);
            } else {
                resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            }
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneCreateResourceWithImage(result.data));
            }
        } else {
            yield put(doneUpdateResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorCreateResourceWithImage(result.data));
    }
}

export function* watchCreateResourceWithImage() {
    yield takeLatest('CREATE_RESOURCE_WITH_IMAGE', createResourceWithImageCall);
}

export function* deleteResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetResource(resPiggy.data));
                yield put(doneDeleteResource(result.data));
            }
        } else {
            yield put(doneDeleteResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorDeleteResource(result.data));
    }
}

export function* watchDeleteResource() {
    yield takeLatest('DELETE_RESOURCE', deleteResourceContactCall);
}

export function* deleteSecondResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSecondResource(resPiggy.data));
                yield put(doneDeleteSecondResource(result.data));
            }
        } else {
            yield put(doneDeleteSecondResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorDeleteSecondResource(result.data));
    }
}

export function* watchDeleteSecondResource() {
    yield takeLatest('DELETE_SECOND_RESOURCE', deleteSecondResourceCall);
}

export function* uploadDocumentsCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.uploadFiles, user, action.data.resource, action.data.files, action.data.params, action.data.descriptions, action.data.names, action.data.types, action.data.categories, action.data.IsPublic);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resultPiggy = yield call(Api.getResource, user, Object.assign({
                id: action.data.id ? action.data.id : action.data.params.id
            }, action.data.piggyQuery ? action.data.piggyQuery : (action.data.query ? action.data.query : {})), action.data.piggyResource ? action.data.piggyResource : action.data.resource);

            if (resultPiggy && resultPiggy.status === 0) {
                yield put(doneGetResource(resultPiggy.data));
                yield put(doneUploadDocument(true));
            } else {
                yield put(errorUploadDocument(result.data));
            }
        } else {
            yield put(doneUploadDocument(true));
        }
    } else {
        yield put(errorUploadDocument(result.data));
    }
}

export function* watchUploadDocument() {
    yield takeLatest('UPLOAD_DOCUMENT', uploadDocumentsCall);
}

export function* downloadDocument(action) {
    const user = yield* checkUserHelper(action.data.user);

    yield call(Api.downloadFile, user, action.data.resource, action.data.query);
}

export function* watchDownloadDocument() {
    yield takeLatest('DOWNLOAD_FILE', downloadDocument);
}
