import React, {Component} from "react";
import Layout from "../../components/layout";
import connect from "react-redux/es/connect/connect";
import {Link} from "react-router-dom";
import {Field, FieldsManager} from "../../data/services/fields";
import {resetPasswordConfirm, resetUserMessage} from "../../data/actions/user";
import FieldPassword from "../../components/field-password/index";
import Button from "../../components/button/index";
import {checkPasswordStrength} from "../../util/util";
import Loader from "../../components/loader";

class ResetPasswordView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            no_match: false,
            passwordStrength: {
                passed: 0,
                strength: "",
                color: "white"
            },
            is_new: this.props.match.params && !!this.props.match.params.is_new
        };
    }

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});

        if (name === 'password') {
            this.setState({
                passwordStrength: checkPasswordStrength(value)
            })
        }
    };

    submitForm = (event) => {
        if (event.key === 'Enter') {
            this.submit(event);
        }
    };

    changePasswordSubmit = (event) => {
        event && event.preventDefault();

        const email = this.props.match.params && this.props.match.params.email;
        const token = this.props.match.params && this.props.match.params.token;

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (this.state.passwordStrength.strength < 4) {
                return;
            }
            if (this.state.fields.password.value !== this.state.fields.password_confirm.value) {
                this.setState({
                    no_match: true
                });
            } else if (!this.state.fields.password.errorMessage && !this.state.fields.password_confirm.errorMessage) {

                this.props.dispatch(resetPasswordConfirm({
                    username: email,
                    token: token,
                    password: this.state.fields.password.value
                }))
            }
        })
    };

    resetUserMessage = () => {
        this.props.dispatch(resetUserMessage());
        this.setState({
            no_match: false
        });
    }

    render() {
        const {translate} = this.props;

        return (
            <Layout {...this.props}>

                <div className="px-6 pb-6 pt-12 sm:px-14 sm:pb-14 sm:pt-24">
                    <div className="theme-dark-popup bg-inverse shadow rounded-lg pt-8 px-8 pb-2 max-w-sm mx-auto">
                        <div className="mx-auto py-4 max-w-sm text-center text-3xl">
                            {this.state.is_new ? translate("text.create_password") : translate("text.reset_password")}
                        </div>

                        {this.props.user.isLoading && (
                            <div className={"text-center w-full m-4"}>
                                <Loader/>
                            </div>
                        )}

                        {!!this.props.user.resetConfirm && !this.props.user.isLoading && (
                            <React.Fragment>
                                <section>
                                    <div>
                                        <div>
                                            <div className="col-lg-6 bg-light">
                                                <div
                                                    className="ht-100v d-flex align-items-center justify-content-center">
                                                    <div>
                                                        <div>{this.state.is_new ? translate("create_password.success") : translate("reset_password.success")}</div>
                                                        <Link to={`/login`}>{translate("btn.back_to_login")}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </React.Fragment>
                        )}

                        {!this.props.user.resetConfirm && !this.props.user.isLoading && (
                            <section>
                                <form onKeyPress={this.submitForm}>
                                    <div>
                                        <div>
                                            <form>
                                                <div className={"mt-5 mb-5"}>
                                                    <FieldPassword
                                                        onChange={this.handleInputChange} {...this.state.fields.password}
                                                        className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700 "
                                                        placeholder={translate("field.placeholder.new_password")}/>
                                                </div>

                                                <div className={"mt-5 mb-5"}>
                                                    <FieldPassword
                                                        onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                        className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                        placeholder={translate("field.placeholder.password_confirm")}/>
                                                </div>

                                                <span
                                                    className={"text-sm"}>{(!!this.state.passwordStrength.color && this.state.passwordStrength.color !== "white") ? translate("text.password_strength") : ""}</span>
                                                <span className={"passwordStrength mb-5"}
                                                      style={{backgroundColor: this.state.passwordStrength.color}}></span>

                                                {!this.props.user.isLoading && (
                                                    <React.Fragment>
                                                        <Button
                                                            className="w-full justify-center capitalize"
                                                            type="primary"
                                                            onClick={this.changePasswordSubmit}>
                                                            {this.state.is_new ? translate("btn.create_password") : translate("btn.reset_password")}
                                                        </Button>

                                                        <div className="flex items-center justify-between mt-2">
                                                            <div className="flex items-center">
                                                            </div>

                                                            <div className="text-sm">
                                                                <Link className="form-btn-submit link-like-element"
                                                                      to={`/login`}>{translate("btn.back_to_login")}</Link>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}

                                            </form>

                                            {this.props.user.error && (
                                                <div
                                                    className="px-6 py-3 mt-3 mb-3 flex rounded-btn bg-red-100 text-red-700"
                                                    onClick={() => this.resetUserMessage()}>
                                                    <svg className="text-red-700 w-5 h-5 mr-2"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    {this.props.translate(this.props.user.errorMessage)}
                                                </div>
                                            )}

                                            {this.state.no_match && (
                                                <div
                                                    className="px-6 py-3 mt-3 mb-3 flex rounded-btn bg-red-100 text-red-700"
                                                    onClick={() => this.resetUserMessage()}>
                                                    <svg className="text-red-700 w-5 h-5 mr-2"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                                              clipRule="evenodd"/>
                                                    </svg>

                                                    {this.props.translate("reset_password.no_match")}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </form>

                            </section>
                        )}

                    </div>
                </div>

            </Layout>
        );
    }
}

export default connect(state => state)(ResetPasswordView);