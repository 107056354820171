import React from "react";
import {checkPerm, classNames, READ_PERM} from "../../util/util";

const HorizontalTabs = (props) => {
    const {tabs, handleTabChange} = props;

    return (
        <React.Fragment>
            <div className="sm:hidden  mb-6">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>

                <select
                    id="tabs"
                    name="tabs"
                    className="mt-4 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
                    defaultValue={tabs.find((tab) => tab.current).name}
                    onChange={e => handleTabChange(e.target.value)}
                >
                    {tabs.map((tab) => (
                        <option
                            key={tab.name}
                            value={tab.resource}
                        >
                            {tab.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="mt-2 -mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.filter((tab) => checkPerm(tab.resource, READ_PERM)).map((tab) => (

                            <button
                                key={tab.name}
                                onClick={() => handleTabChange(tab.resource)}
                                className={classNames(
                                    tab.current
                                        ? 'border-primary-500 text-primary-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                    'whitespace-nowrap py-4 px-3 border-b-2 font-medium text-sm'
                                )}
                            >
                                {tab.name}
                                {tab.count ? (
                                    <span
                                        className={classNames(
                                            tab.current ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                      {tab.count}
                                    </span>
                                ) : null}
                            </button>
                        ))}
                    </nav>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HorizontalTabs;