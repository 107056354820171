import React, {Component} from 'react'
import {fieldsToHtml} from "../../util/util";
import SimpleTable from "../../components/simple-table";
import Button from "../../components/button";
import DialogDefault from "../../components/dialog-default";
import {FieldsManager} from "../../data/services/fields";

export default class ClientChild extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.props.getFields(),

            offset: 0,
            limit: 10,
            paginationPage: 1,

            isDialogVisible: false,
            selectedItem: undefined
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((!!this.props.visible && this.props.selectedItem !== prevProps.selectedItem) || (this.props.data !== prevProps.data)) {
            this.setState({
                fields: this.props.getFields(this.state.selectedItem)
            });
        }
    }

    toggleMembersDialog = (item = this.state.selectedItem, cancel = false) => {
        let originalData = null;

        if (!this.state.isDialogVisible) {
            this.props?.saveData && this.props.saveData()
        }

        if (!!cancel) {
            this.props?.revertData && this.props.revertData()
        }

        let newItem = null;
        if (!item) {
            newItem = Object.assign({}, {ClientChildID: this.props.newPersonIndex})
        }

        this.setState({
            selectedItem: item,
            fields: this.props.getFields(item),
            newItem: newItem,
            isDialogVisible: !this.state.isDialogVisible,
            originalData: originalData
        })
    };

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value, (this.state.selectedItem || this.state.newItem))
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    getValidFields = () => {
        let fields = FieldsManager.validateFields(this.state.fields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(fields);
        }

        this.setState({fields: fields});

        return false;
    };

    /** Render
     ================================================================= */

    render() {
        const {translate, data, isLoading} = this.props;

        const {limit, offset, paginationPage} = this.state;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange);

        return (
            <React.Fragment>
                <div className="mt-10">
                    <div className="mb-4 text-right">
                        <Button
                            type="outline"
                            onClick={() => this.toggleMembersDialog(false)}
                        >
                            Add Child
                        </Button>
                    </div>

                    <SimpleTable
                        data={data}
                        count={data.length}

                        fields={this.state.fields}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={limit}
                        offset={offset}
                        paginationPage={paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={this.toggleMembersDialog}
                        onDelete={this.props.onDelete}
                    />
                </div>

                <DialogDefault
                    title="Children"
                    widthClass="max-w-4xl"
                    visible={this.state.isDialogVisible}
                    blurBackdrop={true}
                    onClose={() => this.toggleMembersDialog(this.state.selectedItem, "cancel")}
                    translate={translate}
                >

                    {fieldsHtml}

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => {
                                        const fields = this.getValidFields();

                                        if (!fields) return null;

                                        if (!!this.state.selectedItem) {
                                            this.props.onEdit(fields, this.state.selectedItem)
                                        } else {
                                            this.props.onAdd(fields, this.state.newItem);
                                        }
                                        this.toggleMembersDialog();
                                    }}
                                >
                                    Confirm
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                    onClick={() => this.toggleMembersDialog(this.state.selectedItem, "cancel")}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogDefault>
            </React.Fragment>
        )
    }
}